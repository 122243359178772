import React, { useState, useEffect } from "react";
import Checkbox from './checkbox';
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row";
//import Col from "react-bootstrap/Col";
import { Assignlist } from "./assign-list";


const AssignContact = () => {

    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [list, setList] = useState([]);

    useEffect(() => {
        setList(Assignlist);
        }, [list]);

        const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(list.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
        };

        const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
        };

        const catalog = list.map(({ id, name }) => {
            return (
                <div className = "row mx-0 user-row" key={name}>
                    <div className = "user-name user-name-details">
                        <Form.Label  htmlFor = {id}>
                            {name}
                        </Form.Label>
                        <Checkbox
                        type="checkbox"
                        id={id}
                        handleClick={handleClick}
                        isChecked={isCheck.includes(id)}
                        />
                    </div>
                </div>
            );
          });
        
    return (
        <>
            <Row>
                <div className = "user-name">
                    <label  htmlFor = "selectAll">
                        <ul className = "nav align-items-center">
                            <li className = "nav-item">
                                <div className = "u-name">
                                    Select All <span>(3)</span>
                                </div>
                            </li>
                        </ul>
                    </label>
                    <Checkbox
                        type="checkbox"
                        name="selectAll"
                        id="selectAll"
                        handleClick={handleSelectAll}
                        isChecked={isCheckAll}
                    />
                </div>
            </Row>
            {catalog}
        </>
    );
};

export default AssignContact;