
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
import Table from "react-bootstrap/Table";

import Pricecard from "../components/price-card/pricecard";

import "../components/price-card/switch.css";

const Subscription = () => {
    return (
        <section className = "main faq subcription-sec">
            <Container>
                <h1>
                    Plans & Pricing
                </h1>
                <p>
                    Learn about our pricing plan and choose what suits your requirement.
                </p>
                <Row>
                    <div className = "col-12">
                        <div className = "d-flex justify-content-center py-3">
                            <ul className="radio-switch">
                                <li className="radio-switch__item">
                                    <input type="radio" className="radio-switch__input" id="radio1" name="radioSwitch" checked/>
                                    <label htmlFor = "radio1" className="radio-switch__label">Monthly</label>
                                </li>

                                <li className="radio-switch__item">
                                    <input type="radio" className="radio-switch__input" id="radio2" name="radioSwitch"/>
                                    <label htmlFor = "radio2" className="radio-switch__label">Yearly</label>
                                    <div className="radio-switch__marker" aria-hidden="true"></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Row>
                <Row>
                    <Col sm = "12">
                        <div className = "subcription-card py-3 d-flex justify-content-center">
                            <Pricecard feetitle={'Fee'} amount = {'$0'} buttontext = {'Try it free'} 
                            point_a = {'10 Message'} point_b = {'1 Group '} point_c = {'One time broadcast'} point_d = {'Up-to 5 users'}/>
                            <Pricecard feetitle={'Standard'} amount = {'$500'} buttontext = {'Get Started'}  
                            point_a = {'10 Message'} point_b = {'1 Group '} point_c = {'One time broadcast'} point_d = {'Up-to 5 users'}/>
                            <Pricecard feetitle={'Pro'} amount = {'$900'} buttontext = {'Get Started'} 
                             point_a = {'10 Message'} point_b = {'1 Group '} point_c = {'One time broadcast'} point_d = {'Up-to 5 users'}/>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container className = "py-5">
                <Row>
                    <Col sm = {12}>
                        <h1 className = "mb-5">
                            Compare plans & features
                        </h1>
                        <div className = "compare-plan-table mb-0">
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>Feature</th>
                                        <th>Free</th>
                                        <th>Standard</th>
                                        <th>Pro</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>User can send broadcast messages</td>
                                        <td>10 Messages</td>
                                        <td>300 Messages</td>
                                        <td>Unlimited</td>
                                    </tr>
                                    <tr>
                                        <td>User can send broadcast messages</td>
                                        <td><i className ="bi bi-check-lg"></i></td>
                                        <td><i className ="bi bi-check-lg"></i></td>
                                        <td><i className ="bi bi-check-lg"></i></td>
                                    </tr>
                                    <tr>
                                        <td>User can send broadcast messages</td>
                                        <td><i className ="bi bi-check-lg"></i></td>
                                        <td><i className ="bi bi-check-lg"></i></td>
                                        <td><i className ="bi bi-check-lg"></i></td>
                                    </tr>
                                    <tr>
                                        <td>User can send broadcast messages</td>
                                        <td><i className ="bi bi-check-lg"></i></td>
                                        <td><i className ="bi bi-check-lg"></i></td>
                                        <td><i className ="bi bi-check-lg"></i></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Container>

        </section>
    )
}

export default Subscription;