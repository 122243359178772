
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import './css/templateListing-footer.css';

const TemplateListingFooter = () => {
    return (
        <>
            <section className = "listingfooter">
                <Container>
                    <Row>
                        <Col xs = {12}>
                            <ul className = "nav justify-content-center align-items-center">
                                <li className = "nav-item prev">
                                    <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 8.78195L6 11L2.40694e-07 5.49355L6 0L6 2.20516L2.34454 5.49355L6 8.78195Z" fill="black" fillOpacity="0.3"/>
                                    </svg>
                                </li>
                                <li className = "nav-item active">
                                    1
                                </li>
                                <li className = "nav-item">
                                    2
                                </li>
                                <li className = "nav-item next">
                                    <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M-9.69542e-08 8.78195L0 11L6 5.49355L-4.80825e-07 0L-3.84435e-07 2.20516L3.65546 5.49355L-9.69542e-08 8.78195Z" fill="black"/>
                                    </svg>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default TemplateListingFooter;