

import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import './form.css';

const Reset_form = ({heading})=>{

    return(
        <div className = "form-sec">
            <Form>
                <div className = "form-heading text-center">
                   {heading}
                </div>
                <Form.Group className = "form-group">
                    <Form.Label>Password*</Form.Label>
                    <Form.Control type = "password" placeholder = ""/>
                </Form.Group>
                <Form.Group className = "form-group">
                    <Button className = "btn btn-main" type = "submit">
                        Reset Password
                    </Button>
                </Form.Group>
                <Form.Group className = "text-center last-pra">
                    <Link to = "/login">Back to login</Link>
                </Form.Group>
            </Form>
        </div>
    );
}

export default Reset_form;