
import WidgetForm from "../components/widget-form/widget-form";
import PageHeader from "../components/page-header/pageHeader";
import ChatUs from "../components/chatUs/chatUs";



const Websitewidget = () => {

    return (
        <>

            <PageHeader Pagename={'WhatsApp Website Widget'} Backpage={'Integrations'} 
            Currentpage={'WhatsApp Website Widget'} Linkpage={'/integrations'}/>

            <WidgetForm/>

            <ChatUs/>

           
        </>
    );
};

export default Websitewidget;