
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Link } from "react-router-dom";

const PageHeader = ({Pagename, Backpage, Currentpage, Linkpage}) => {

    const pageHeaderstyle = {
        boxShadow: '0px 3px 5px 0px #00000014',
        backgroundColor: '#fff',
        padding: '25px 0px'
    }

    const pageHeadingstyle = {
        fontWeight: '700',
        fontSize: '22px',
        color: '#000',
        lineHeight: '30px',
    }

    const breadcrumbLinkstyle = {
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '19px',
        color: '#4B4A4A',
    }

    const breadcrumbSpanstyle = {
        fontWeight: '600',
        color: '#4B4A4A',
        textDecoration: 'none',
    }

    const linkstyle = {
        textDecoration: 'none',
        marginRight: '5px'
    }

    const svg_iconstyle = {
        position: 'relative',
        top: '-1px',
    }

    return (
        <>
            <section style = {pageHeaderstyle} className = "page-header">
                <Container>
                    <Row className = "align-items-center">
                        <Col md = {6} style = {pageHeadingstyle} className = "page-heading d-none d-md-block">
                            <div className = "flex align-items-center gap-2">
                                {Pagename}
                            </div>
                        </Col>
                        <Col style = {breadcrumbLinkstyle} className = "bc-links col-md-6 text-md-end">
                            <Link style = {linkstyle} to = {Linkpage}> 
                                <span style = {breadcrumbSpanstyle}>
                                <svg style = {svg_iconstyle} className = "me-2" width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 10L1 5.5L6 1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg> 
                                {Backpage}
                                </span> 
                            </Link> 
                                / {Currentpage}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default PageHeader;