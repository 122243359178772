import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import TagsHeader from "../components/tags/tags-header";
import Search from "../components/search/search";
import TemplateListingFooter from "../components/template-listing/templateListing-footer";
import TagTable from "../components/tags/tag-table";
import TagsMob from "../components/tags/tag-mob";

const Tags = () => {
    return (
        <>
            <TagsHeader Tag_title={'Tags'} Tag_search={<Search/>}/>

            <section className = "contact-group-table py-3 d-none d-lg-block">
                <Container>
                    <Row>
                        <Col xs = {12}>
                            <TagTable/>
                        </Col>
                    </Row>
                </Container>
            </section>
            
            <section className = "main inner-main template-listing pb-3 d-lg-none">
                <TagsMob/>
            </section>
            
            <TemplateListingFooter/>

        </>
    )
}

export default Tags;