
import Wtable from "../table/table";
import TagAction from "./tag-action";

import "../table/table.css";

const TagTable = () => {

    const getRandomLightColor = () => {
        // Generate a random light color in hexadecimal format
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        return `rgba(${r}, ${g}, ${b}, 0.2)`; // using alpha to make it light
    };

    const badgeStyle = (color) => (
        {
            backgroundColor: color,
            color: '#000',
            fontWeight: '600',
            lineHeight: '16px',
            fontSize: '12px',
            borderRadius: '5px',
            padding: '5px 6px',
            display: 'inline-block',
            margin: '3px 0px',
            whiteSpace: 'nowrap',
        }
    ) 

    const theadData = ["Tag names", "Created at", "Action"];

    const tbodyData = [
                    {
                        id: "1",
                        items: ["Sale", "Apr-18-2023", <TagAction/>]
                    },
                    {
                        id: "2",
                        items: ["Technical Support", "Apr-18-2023", <TagAction/>]
                    },
                    {
                        id: "3",
                        items: ["Event Marketing", "Apr-18-2023", <TagAction/>]
                    },
                    {
                        id: "4",
                        items: ["General Queries", "Apr-18-2023", <TagAction/>]
                    },
                    {
                        id: "5",
                        items: ["Logistic", "Apr-18-2023", <TagAction/>]
                    },
                    {
                        id: "6",
                        items: ["Sale", "Apr-18-2023", <TagAction/>]
                    },
                    {
                        id: "7",
                        items: ["Event Marketing", "Apr-18-2023", <TagAction/>]
                    },
                    {
                        id: "8",
                        items: ["Technical Support", "Apr-18-2023", <TagAction/>]
                    },
                    {
                        id: "9",
                        items: ["Event Marketing", "Apr-18-2023", <TagAction/>]
                    },
    ]



    return (
        
        <>
            <div className = "cg-custom-table mb-5">
                <Wtable theadData={theadData} 
                    tbodyData={tbodyData.map(row => ({
                        id: row.id,
                        items: [
                            <span style={badgeStyle(getRandomLightColor())}>{row.items[0]}</span>,
                            row.items[1],
                            row.items[2]
                        ]
                    }))}
                />
            </div>
        </>
    )
}

export default TagTable;