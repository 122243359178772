

const TemplateDetails = ({Eventname, EventCreatedBy}) => {
    return (
        <>
            <div className = "eventName">
                {Eventname}
            </div>
            <div className = "eventCreator">
                Created by {EventCreatedBy}
            </div>
        </>
    );
};

export default TemplateDetails;