
import { Link } from 'react-router-dom';
import './integration-card.css';

const IntegrationCard = ({Websitename, Iconimg, WebsiteTitle, WebsiteLink}) => {
    return (
        <>
            <div className = "integration-card position-relative">
                <div className = "card-logo mb-3">
                    <img src = {Iconimg} alt = "logo" />
                </div>
                <div className = "integration-card-title">
                    {Websitename}
                </div>
                <div className = "integration-card-content">
                    {WebsiteTitle}
                </div>
                <div className = "card-link d-flex justify-content-end">
                    <Link to = {WebsiteLink}>
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.5 6.5L12.5 10.5L8.5 14.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </Link>
                </div>
                <div className = "copy-link">
                    <ul className = "nav align-items-cetner justify-content-center gap-1">
                        <li className = "nav-item">
                            Get Code
                        </li>
                        <li className = "nav-ietm">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.75 13.5C6.3375 13.5 5.98437 13.3531 5.69062 13.0594C5.39687 12.7656 5.25 12.4125 5.25 12V3C5.25 2.5875 5.39687 2.23438 5.69062 1.94063C5.98437 1.64688 6.3375 1.5 6.75 1.5H13.5C13.9125 1.5 14.2656 1.64688 14.5594 1.94063C14.8531 2.23438 15 2.5875 15 3V12C15 12.4125 14.8531 12.7656 14.5594 13.0594C14.2656 13.3531 13.9125 13.5 13.5 13.5H6.75ZM6.75 12H13.5V3H6.75V12ZM3.75 16.5C3.3375 16.5 2.98437 16.3531 2.69062 16.0594C2.39687 15.7656 2.25 15.4125 2.25 15V4.5H3.75V15H12V16.5H3.75Z" fill="#919191"/>
                            </svg>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default IntegrationCard;