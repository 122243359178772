import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useState } from 'react';

import "../components/Group-page/groups.css";
import EmptyGroupIcon from "../images/groups/groups_icon.svg";

import GroupHeader from "../components/Group-page/group-header";
import Search from "../components/search/search";
import Allcontacts from "../components/group-table/all-contacts";
import { Link } from "react-router-dom";


const EmptyGroup = () => {

    const [cgtshow, setCgtShow] = useState(false);
    const [newgroupshow, setNewgroupShow] = useState(false);


    return(
        <>

            <GroupHeader Contact_title={'Groups'} Contact_search={<Search/>}/>

            <section className = "main inner-main templates-body-empty">
                    <Container>
                        <Row className = "align-items-center">
                            <Col xs = {12}>
                                <div className = "tb-empty-content text-center groups">
                                    <img class = "groups-icons" src = {EmptyGroupIcon} alt = "icon"/>
                                    <div className = "tb-page-title">
                                        Currently you don’t have any group
                                    </div>
                                    <p>
                                        You can create a new group by clicking the button below
                                    </p>
                                    <Button className = "btn-main" onClick={() => setCgtShow(true)}>
                                        <i className ="bi bi-plus"></i> Create Group
                                    </Button>
                                    <p>
                                        <Link to = "/group" className = "btn btn-main mt-3">
                                            Group page
                                        </Link>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
             </section>

             <Modal show={cgtshow} onHide={() => setCgtShow(false)} className = "whatsapp-modal modal-g-contact" centered>
                <Modal.Header className = "p-0">
                    <Modal.Title className = "mb-1 w-100">
                        Add Group Member
                         <span className = "finish-btn" onClick={()=>{setCgtShow(false) 
                        setNewgroupShow(true)}}>
                            Next <i className ="bi bi-chevron-right"></i>
                         </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className = "pt-0">
                    {/* <Row>
                        <Col sm = {12}>
                            <Button className = "import-cvs">
                                Import Contacts From CSV File
                            </Button>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Control type = "text" placeholder = "Search Contact"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <div className = "col-12">
                            <Allcontacts/>
                        </div>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={newgroupshow} onHide={() => setNewgroupShow(false)} className = "whatsapp-modal modal-contact" centered>
                <Modal.Header className = "p-0" closeButton>
                    <Modal.Title className = "text-center w-100">
                        <div className="back-btn" onClick = {()=>{setNewgroupShow(false) 
                        setCgtShow(true)}}>
                            <i className ="bi bi-chevron-left"></i>
                        </div>
                        New Group
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Group Name</Form.Label>
                                <Form.Control type = "text" placeholder = "Mwan Mobile"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Participants: 3 of 5</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className = "px-0">
                    <Button  className = "btn-main" onClick={()=>{setNewgroupShow(false)}}>
                        Create Group
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EmptyGroup;