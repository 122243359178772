
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";

import Username from "../userName/username";

import '../manageUser/css/usermob.css';

const BroadcastDetailsMob = () => {

    return (
        <>
        <Container>
            <Row>
                <Col xs = {12}>
                    <div className = "template-listing-collapse">
                        <div className = "tlc-header">
                            Name
                        </div>
                        <div className = "tlc-body">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey = "0">
                                    <Accordion.Header>
                                        <div className = "username-broadcastmob">
                                            <Username name = {'Assad Mehmood'}/>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title" style = {{fontWeight: '400'}}>Phone Number</div>
                                            <div className = "phone_number">
                                                +97122495022
                                            </div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title" style = {{fontWeight: '400'}}>Status</div>
                                            <div className = "phone_number">
                                                Delivered 
                                            </div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title" style = {{fontWeight: '400'}}>Delivery At</div>
                                            <div className = "cb-date">Apr-18-2023</div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey = "1">
                                    <Accordion.Header>
                                        <div className = "username-broadcastmob">
                                            <Username name = {'Abigail Smith'}/>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title" style = {{fontWeight: '400'}}>Phone Number</div>
                                            <div className = "Role">
                                                +97122495022
                                            </div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title" style = {{fontWeight: '400'}}>Status</div>
                                            <div className = "phone_number">
                                                Delivered 
                                            </div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title" style = {{fontWeight: '400'}}>Delivery At</div>
                                            <div className = "cb-date">Apr-18-2023</div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey = "2">
                                    <Accordion.Header>
                                        <div className = "username-broadcastmob">
                                            <Username name = {'Daniel Williams'}/>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title" style = {{fontWeight: '400'}}>Phone Number</div>
                                            <div className = "Role">
                                                +97122495022
                                            </div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title" style = {{fontWeight: '400'}}>Status</div>
                                            <div className = "phone_number">
                                                Delivered 
                                            </div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title" style = {{fontWeight: '400'}}>Delivery At</div>
                                            <div className = "cb-date">Apr-18-2023</div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey = "3">
                                    <Accordion.Header>
                                        <div className = "username-broadcastmob">
                                            <Username name = {'Edward Martinez'}/>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title" style = {{fontWeight: '400'}}>Phone Number</div>
                                            <div className = "Role">
                                                +97122495022
                                            </div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title" style = {{fontWeight: '400'}}>Status</div>
                                            <div className = "phone_number">
                                                Delivered 
                                            </div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title" style = {{fontWeight: '400'}}>Delivery At</div>
                                            <div className = "cb-date">Apr-18-2023</div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        </>
    );
};

export default BroadcastDetailsMob;