import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from 'react-router-dom';

import TemplateListingHeader from "../components/template-listing/templateListing-header";
import TemplateListingFooter from "../components/template-listing/templateListing-footer";
import Template_ic from "../images/icons/template-ic.svg";


const TemplateEmpty = () => {

    return (
        <>
            
            <TemplateListingHeader/>

             <section className = "main inner-main templates-body-empty">
                    <Container>
                        <Row className = "align-items-center">
                            <Col xs = {12}>
                                <div className = "tb-empty-content text-center">
                                    <img src = {Template_ic} alt = "icon"/>
                                    <div className = "tb-page-title">
                                        No Templates to display.
                                    </div>
                                    <p>
                                        You can create a new Tag by clicking the button below
                                    </p>
                                    <Link to = "/createTemplate" className = "btn btn-main text-decoration-none">
                                        <i className ="bi bi-plus"></i> Create New
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
             </section>

             <TemplateListingFooter/>
        </>
    );
};

export default TemplateEmpty;