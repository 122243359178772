
import './broadcast.css';

const Broadcastname = ({EventName, CreatedName}) => {
    return (
        <>
            <div className = "broadcast-name">
                <div class = "event-name">
                    {EventName}
                </div>
                <div class = "createdby">
                    Created By {CreatedName}
                </div>
            </div>
        </>
    )
}

export default Broadcastname;