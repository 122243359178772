import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

import Search from "../components/search/search";


const Placeholdertable = () => {
    return (
        <section className = "main inner-main">
            <section className = "contact-group-top">
                <Container>
                    <Row className = "align-items-center">
                        <Col sm = {6} className = "mb-3 mb-sm-0">
                            <Search/>
                        </Col>
                        <Col sm = {6}>
                            <div className = "btn-sec text-sm-end">
                                <Button className = "btn-main">
                                    <i className ="bi bi-plus"></i> Add Contact
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className = "contact-group-table">
                <Container>
                    <Row>
                        <Col xs = {12}>
                        <Table className = "cg-custom-table" responsive>
                            <thead>
                                <tr className = "placeholder-glow">
                                   <th>
                                        Name
                                   </th>
                                   <th>
                                        Phone Number
                                   </th>
                                   <th>
                                        Created Date
                                   </th>
                                   <th>
                                        Email
                                   </th>
                                   <th>
                                        Action
                                   </th>
                                </tr>
                            </thead>
                            <tbody>
                               <tr className = "placeholder-glow">
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                               </tr>
                               <tr className = "placeholder-glow">
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                               </tr>
                               <tr className = "placeholder-glow">
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                               </tr>
                               <tr className = "placeholder-glow">
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                               </tr>
                               <tr className = "placeholder-glow">
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                                    <td>
                                        <span className = "placeholder w-100"></span>
                                    </td>
                               </tr>
                            </tbody>
                        </Table>
                        </Col>
                    </Row>
                </Container>
            </section>

        </section>
    )
}

export default Placeholdertable;