
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

import Emptylist from "../images/user-list-empty.svg";
import Pempty from "../images/chat-p-empty.svg";
import Msgicon from "../images/msg-icon.svg";

import "../components/chat/inbox.css";

const Emptychat = () => {
    return (
        <div className = "chat-section">
            <Row className = "mx-0">
                <Col md = {4} xxl = {3} className = "d-none d-md-block">
                    <div className = "user-list h-100">
                        <div className = "active-user-list empty-state">
                            <img src = {Emptylist} alt = "list-placeholder"/>
                        </div>
                    </div>
                </Col>
                <Col md = {5} xxl = {6}>
                    <div className = "live-chat-sec empty-chat-sec h-100">
                        <div className = "empity-content text-center">
                            <img className = "mb-3" src = {Msgicon} alt = "icon"/>
                            <h1>No chat to display</h1>
                            <p>You can start the new chat by tapping the button bellow</p>
                            <Link to = "#" className = "btn btn-main mt-3">
                                Send New Message
                            </Link>
                        </div>
                    </div>
                </Col>
                <Col md = {3} xxl = {3} className = "d-none d-md-block">
                    <div className = "user-info-data h-100 empty-state">
                        <img src = {Pempty} alt = "Profile-placeholder"/>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Emptychat;