
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Link } from "react-router-dom";

import Accessfb from "../images/connect_img.png";

const Facebookaccess = () => {
    return (
        <>
            <section className = "wahtsapp_form pt-4">
                <Container>
                    <Row className="justify-content-center">
                        <Col xs = {12} md = {10} lg = {8} xl = {7} xxl = {6}>
                            <div className = "accessfacebook-content bg-white">
                                <div className = "text-center access-img mb-3 mb-lg-4">
                                    <img src = {Accessfb} alt = "access-img"/>
                                </div>
                                <h2 className = "text-center">
                                    Connect your Facebook Business Account
                                </h2>
                                <p className = "top">
                                    To continue, you will need to access your company's Facebook business account.By clicking the '<Link to = "#">Continue with Faceboo</Link>' button below you can:
                                </p>
                                <ul>
                                    <li> 
                                        Create new or select existing Facebook and WhatsApp Business accounts.
                                    </li>
                                    <li>
                                        Provide a display name and description for your WhatsApp business profile.
                                    </li>
                                    <li>
                                        Connect and verify your phone number to use for the WhatsApp API access.
                                    </li>
                                </ul>

                                <div className = "highlighted-pra">
                                    <p>
                                        Do not use a number that is currently connected to an existing WhatsApp account. This includes numbers that are connected to the <b>WhatsApp Business App</b>. You will not be able to finish the registration process. 
                                        <br/><br/>
                                        <b>Learn more about your WhatsApp phone number</b>
                                    </p>
                                </div>

                                <Link to = "#" className = "btn">
                                    Continue with Facebook
                                </Link>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Facebookaccess;