
import React from 'react';
import Wtable from '../table/table';
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Button from "react-bootstrap/Button";
// import Broadcastprogressbar from './b-progressbar';
//import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
// import { buildStyles } from 'react-circular-progressbar';
import "../table/table.css";

import BroadcastTablestatus from './broadcast-status';
import Broadcastname from './broadcast-name';
import BroadcastType from './broadcast-type';
import DeliveryStatus from './delivery-status';
import BroadcastAction from './broadcast-action';

const Broadcasttable = () => {

    const theadData = ["Broadcast Name", "Created At", "Status", "Audience", "Broadcast type", "Message Delivery", "Action", ];

    const tbodyData = [
        {
            id: "1",
            items: [<Broadcastname EventName={'GCC eHealth Event Announcement'} CreatedName={'Muzammil Ahmed Khan'}/>, "Apr-18-2023", <BroadcastTablestatus statusClass={'status-title'} statusTitle={'Completed'}/>, "35",
            <BroadcastType className={'scheduled'} Typename={'Scheduled'} TypeDateTime={'Apr-18-2023 07:00 PM'}/>, 
            <DeliveryStatus CompletedValue={'60'} PendingValue={'30'} FailedValue={'10'}/>, <BroadcastAction Link_add={'/broadcastDetails'}/>],
        },
        {
            id: "2",
            items: [<Broadcastname EventName={'Discount offer for new customers'} CreatedName={'Muzammil Ahmed Khan'}/>, "Apr-18-2023", <BroadcastTablestatus statusClass={'status-title'} statusTitle={'Pending'}/>, "35",
                <BroadcastType className={'instant'} Typename={'Instant'} TypeDateTime={'Apr-18-2023 07:00 PM'}/>, 
                <DeliveryStatus CompletedValue={'100'} PendingValue={'0'} FailedValue={'0'}/>, <BroadcastAction Link_add={'/broadcastDetails'}/>],
        },
        {
            id: "3",
            items: [<Broadcastname EventName={'Registration confirmation message to the registered user'} CreatedName={'Asad Mehmood'}/>, "Apr-18-2023", <BroadcastTablestatus statusClass={'status-title'} statusTitle={'Completed'}/>, "35",
                <BroadcastType className={'instant'} Typename={'Instant'} TypeDateTime={'Apr-18-2023 07:00 PM'}/>, 
                <DeliveryStatus CompletedValue={'60'} PendingValue={'30'} FailedValue={'10'}/>, <BroadcastAction Link_add={'/broadcastDetails'}/>],
        },
        {
            id: "4",
            items: [<Broadcastname EventName={'Registration confirmation message to the registered user'} CreatedName={'Asad Mehmood'}/>, "Apr-18-2023", <BroadcastTablestatus statusClass={'status-title'} statusTitle={'Failed'}/>, "35",
                <BroadcastType className={'instant'} Typename={'Instant'} TypeDateTime={'Apr-18-2023 07:00 PM'}/>,
                <DeliveryStatus CompletedValue={'60'} PendingValue={'30'} FailedValue={'10'}/>, <BroadcastAction Link_add={'/broadcastDetails'}/>],
        },
        {
            id: "5",
            items: [<Broadcastname EventName={'GCC eHealth Event Announcement'} CreatedName={'Muzammil Ahmed Khan'}/>, "Apr-18-2023", <BroadcastTablestatus statusClass={'status-title'} statusTitle={'Completed'}/>, "35",
                <BroadcastType className={'scheduled'} Typename={'Scheduled'} TypeDateTime={'Apr-18-2023 07:00 PM'}/>, 
                <DeliveryStatus CompletedValue={'60'} PendingValue={'30'} FailedValue={'10'}/>, <BroadcastAction Link_add={'/broadcastDetails'}/>],
        },
    ];

    return (
    <>
        <div className = "cg-custom-table">
            <Wtable theadData={theadData} tbodyData={tbodyData}/>
        </div>
    </>
    )
}

export default Broadcasttable;