
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import React, { useState } from 'react';

import readmessage from "../images/icons/read.svg";
import Pending from "../images/icons/pending.svg";
import Send_message from "../images/icons/send.svg";
import Expired from "../images/icons/expired.svg";
import Calendar from "../images/company/calendar.svg";

import Broadcastcard from "../components/broadcast/broadcast-card" ;
import Companytable from "../components/company-listing/companytable";

import "../components/company-listing/companylisting.css";

const Companylisting = () => {

    const [ctshow, setCtShow] = useState(false);
    const [cvsshow, setCvsShow] = useState(false);
    const [expiredhow, setExpiredShow] = useState(false);

    const [active, setActive] = useState(0);

    const handleClick = (index) => {
        setActive(index);
    }

    const items = ["Day","Weekly","Monthly","Yearly"];

    return (
        <>
        <section className = "main inner-main broadcast-sec">

            <section className = "contact-group-top">
                <Container fluid>

                    <Row>
                        <Col sm = {12} className = "mb-3">
                            <div className = "overview-heading">
                                Overview
                            </div>
                        </Col>

                        <Col sm = {12} className = "mb-3 d-md-none">
                            <div className = "overview-header bg-white rounded-pill">
                                <Row className = "align-items-center">
                                    <Col xs = {12}>
                                        {<ul className = "nav">
                                            {items.map((item,index)=> (
                                                <li key = {index} className = {active === index ? "active": "nav-item rounded-pill"} 
                                                onClick={() => handleClick(index)}>
                                                    {item}
                                                </li>
                                            ))}
                                        </ul>}
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                        <Col sm = {12} className = "mb-4 mb-lg-5">
                            <div className = "overview-header bg-white rounded-pill">
                                <Row className = "align-items-center">
                                    <Col md = "4">
                                        <div className = "date-side">
                                            <img src = {Calendar} alt = "calendar-icon"/> 03/02/2023
                                        </div>
                                    </Col>
                                    <Col md = "8" className = "d-none d-md-block">
                                        {/* <ul className = "nav justify-content-end">
                                            <li className = "nav-item rounded-pill active">
                                                Day
                                            </li>
                                            <li className = "nav-item rounded-pill">
                                                Weekly
                                            </li>
                                            <li className = "nav-item rounded-pill">
                                                Mounthly
                                            </li>
                                            <li className = "nav-item rounded-pill">
                                                Yearly
                                            </li>
                                        </ul> */}
                                        <ul className = "nav justify-content-end">
                                            {items.map((item,index)=> (
                                                <li key = {index} className = {active === index ? "active nav-item rounded-pill": "nav-item rounded-pill"} 
                                                onClick={() => handleClick(index)}>
                                                    {item}
                                                </li>
                                            ))}
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    <Row className = "pb-3">
                        <Col md = "4" lg = {3} xl = {3}>
                            <div className = "message-send company-card" onClick={()=> setCvsShow(true)}>
                                <Broadcastcard title = {'Active Companies'} imageicon = {readmessage} number = {'14'} />
                            </div>
                        </Col>
                        <Col md = "4" lg = {3} xl = {3}>
                            <div className = "delivered-message company-card">
                                <Broadcastcard title = {'Messages Sent'} imageicon = {Send_message} number = {'500'} />
                            </div>
                        </Col>
                        <Col md = "4" lg = {3} xl = {3}>
                            <div className = "pending company-card">
                                <Broadcastcard title = {'Pending Companies'} imageicon = {Pending} number = {'03'} />
                            </div>
                        </Col>
                        <Col md = "4" lg = {3} xl = {3}>
                            <div className = "failed company-card" onClick={()=> setExpiredShow(true)}>
                                <Broadcastcard title = {'Expired Companies'} imageicon = {Expired} number = {'10'} />
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
            <section className = "contact-group-table">
                <Container fluid>
                    <Row className = "py-3 align-items-center">
                        <Col xs = {6}>
                            <div className = "overview-heading">
                                Companies Listing
                            </div>
                        </Col>
                        <Col xs = {6} className = "text-end">
                            <Button className = "btn-main add-company" onClick={() => setCtShow(true)}>
                                <i className ="bi bi-plus"></i> Add Company
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs = {12}>
                            <Companytable/>
                        </Col>
                    </Row>
                </Container>
            </section>

        </section>


            <Modal show={ctshow} onHide={() => setCtShow(false)} className = "whatsapp-modal modal-contact" centered>
                <Modal.Header className = "p-0" closeButton>
                    <Modal.Title>Add Company</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control type = "text" placeholder = "Enter name"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Phone No</Form.Label>
                                <Form.Control type = "text" placeholder = "Ex: 923025676625"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type = "text" placeholder = "Enter email"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Expiry Date</Form.Label>
                                <Form.Control type = "text" placeholder = "Mar-10-2023"/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className = "px-0">
                        <Button className = "btn-main-default" onClick={()=>setCtShow(false)}>
                            Cancel
                        </Button>
                        <Button  className = "btn-main" onClick={()=> setCtShow(false)}>
                            Add Company
                        </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={cvsshow} onHide={() => setCvsShow(false)} className = "whatsapp-modal modal-csv" centered>
                <Modal.Header className = "p-0" closeButton>
                    <Modal.Title>
                        Active Companies (10)
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className = "pt-0">
                    <Row>
                        <Col sm = {12}>
                            <div className = "modal-table">
                                <div className = "table-responsive">
                                    <table className ="table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Mwan Mobile
                                                </td>
                                                <td>
                                                    mwanmobile@gmail.com
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Etisalat 
                                                </td>
                                                <td>
                                                    etisalat@gmail.com
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Mwan Events
                                                </td>
                                                <td>
                                                    mwanevents@gmail.com
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Iplex PVT
                                                </td>
                                                <td>
                                                    iplexlimited@gmail.com
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Jazz Mobile
                                                </td>
                                                <td>
                                                    jazzmobile@gmail.com
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Mwan Mobile
                                                </td>
                                                <td>
                                                    mwanmobile@gmail.com
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Etisalat 
                                                </td>
                                                <td>
                                                    etisalat@gmail.com
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Mwan Events
                                                </td>
                                                <td>
                                                    mwanevents@gmail.com
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Iplex PVT
                                                </td>
                                                <td>
                                                    iplexlimited@gmail.com
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Jazz Mobile
                                                </td>
                                                <td>
                                                    jazzmobile@gmail.com
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={expiredhow} onHide={() => setExpiredShow(false)} className = "whatsapp-modal modal-csv" centered>
                <Modal.Header className = "p-0" closeButton>
                    <Modal.Title>
                        Expired Companies (10)
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className = "pt-0">
                    <Row>
                        <Col sm = {12}>
                            <div className = "modal-table company-listing-table">
                                <div className = "table-responsive">
                                    <table className ="table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Mwan Mobile
                                                </td>
                                                <td>
                                                    mwanmobile@gmail.com
                                                </td>
                                                <td>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Etisalat 
                                                </td>
                                                <td>
                                                    etisalat@gmail.com
                                                </td>
                                                <td>
                                                    <Form.Check 
                                                        type="switch"
                                                        id="custom-switch"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Mwan Events
                                                </td>
                                                <td>
                                                    mwanevents@gmail.com
                                                </td>
                                                <td>
                                                    <Form.Check 
                                                        type="switch"
                                                        id="custom-switch"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Iplex PVT
                                                </td>
                                                <td>
                                                    iplexlimited@gmail.com
                                                </td>
                                                <td>
                                                    <Form.Check 
                                                        type="switch"
                                                        id="custom-switch"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Jazz Mobile
                                                </td>
                                                <td>
                                                    jazzmobile@gmail.com
                                                </td>
                                                <td>
                                                    <Form.Check 
                                                        type="switch"
                                                        id="custom-switch"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Mwan Mobile
                                                </td>
                                                <td>
                                                    mwanmobile@gmail.com
                                                </td>
                                                <td>
                                                    <Form.Check 
                                                        type="switch"
                                                        id="custom-switch"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Etisalat 
                                                </td>
                                                <td>
                                                    etisalat@gmail.com
                                                </td>
                                                <td>
                                                    <Form.Check 
                                                        type="switch"
                                                        id="custom-switch"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Mwan Events
                                                </td>
                                                <td>
                                                    mwanevents@gmail.com
                                                </td>
                                                <td>
                                                    <Form.Check 
                                                        type="switch"
                                                        id="custom-switch"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Iplex PVT
                                                </td>
                                                <td>
                                                    iplexlimited@gmail.com
                                                </td>
                                                <td>
                                                    <Form.Check 
                                                        type="switch"
                                                        id="custom-switch"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Jazz Mobile
                                                </td>
                                                <td>
                                                    jazzmobile@gmail.com
                                                </td>
                                                <td>
                                                    <Form.Check 
                                                        type="switch"
                                                        id="custom-switch"
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Companylisting;