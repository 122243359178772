
import React from 'react';
import Wtable from '../table/table';
import Useraction from './useraction';
import Username from '../userName/username';
import UsertableStatus from './user-status';

import "../table/table.css";

const UserListingtable = () => {

    const badgeStyel = {
        backgroundColor: '#FBE2FF',
        color: '#000',
        fontWeight: '600',
        lineHeight: '16px',
        fontSize: '12px',
        borderRadius: '5px',
        padding: '5px 6px',
        display: 'inline-block',
        margin: '3px 0px',
        whiteSpace: 'nowrap',
    }

    const theadData = ["Name", "Tags", "Role", "Status", "Created Date", "Action"];

    const tbodyData = [
        {
            id: "1",
            items: [<Username name = {'Assad Mehmood'} Useremail={'asad.mehmood@mwanmobile.com'}/>, 
            <><span style = {badgeStyel}>Marketing</span> <span style = {{...badgeStyel, backgroundColor:'#FFDBD9'}}>Sale</span></>, 
                "User", <UsertableStatus statusTitle={'Active'} statusClass={'active'}/>, "Jan-18-2023", <Useraction/>]
        },
        {
            id: "2",
            items: [<Username name = {'Abigail Smith'} Useremail={'abigail.smith@example.com'}/>, 
            <><span style = {badgeStyel}>Marketing</span></>, 
            "User", <UsertableStatus statusTitle={'Active'} statusClass={'active'}/>, "Jan-18-2023",  <Useraction/>],
        },
        {
            id: "3",
            items: [<Username name = {'Daniel Williams'} Useremail={'daniel.williams@example.com'}/>, 
            <><span style = {{...badgeStyel, backgroundColor:'#E8F1FF'}}>General Queries</span></>, 
            "User", <UsertableStatus statusTitle={'Active'} statusClass={'active'}/>, "Jan-18-2023",  <Useraction/>],
        },
        {
            id: "4",
            items: [<Username name = {'Edward Martinez'} Useremail={'edward.martinez@example.com'}/>, 
            <><span style = {badgeStyel}>Marketing</span> <span style = {{...badgeStyel, backgroundColor:'#E8F1FF'}}>General Queries</span> <span style = {{...badgeStyel, backgroundColor:'#FFDBD9'}}>Sale</span></>, 
            "User", <UsertableStatus statusTitle={'Disactivated'} statusClass={'disactivated'}/>, "Jan-18-2023", <Useraction/>],
        },
    ];

    return (
    <>
        <div className = "cg-custom-table">
            <Wtable theadData={theadData} tbodyData={tbodyData}/>
        </div>
    </>
    )
}

export default UserListingtable;