
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Electric_icon from '../images/integrations/electric-plugin-icon.svg';
import hubspot_logo from '../images/integrations/hubspot.svg';

import IntegrationHeader from "../components/integrations/integration-header";
import IntegrationCard from "../components/integrations/integration-card";


const Integrations = () => {

    return (
        <>
            
            <IntegrationHeader Pagename={'Integrations'}/>

            <section className = "main inner-main">
                <Container>
                    <Row className = "py-1 py-md-3">
                        <Col md = {6} lg = {4} className = "mb-3 mb-md-0">
                            <IntegrationCard Iconimg={Electric_icon} Websitename={'WhatsApp Website Widget'} 
                            WebsiteTitle={'Boost WhatsApp sales by implementing personalized CTAs.'} WebsiteLink={'/websitewidget'}/>
                        </Col>
                        <Col md = {6} lg = {4}>
                            <IntegrationCard Iconimg={hubspot_logo} Websitename={'WhatsApp Website Widget'} 
                            WebsiteTitle={'Boost WhatsApp sales by implementing personalized CTAs.'} WebsiteLink={'/websitewidget'}/>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Integrations;