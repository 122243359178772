

const TemplateStatus = ({statustext, statusClass}) => {
    return (
        <>
            <span className={`status-badge ${statusClass}`}>
                {statustext}
            </span>
        </>
    );
};

export default TemplateStatus;