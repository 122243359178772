import ContactGroup from "./contactGroup";
import Hajira from "../../images/hajira.png";


export const Assignlist = [
    {
      id: "01",
      name: <ContactGroup Image = {Hajira}  name = {'Hajira'} email={'hajira.eshan@mwanmobile.org'} 
      Usertag={<><div className = "user-tag marketing">Marketing</div></>}/>
    },
    {
      id: "02",
      name: <ContactGroup Image = {Hajira}  name = {'Rabbia Awan'} email={'rabbia.awan@mwanmobile.org'} 
      Usertag={<><div className = "user-tag sale">Sale</div></>}/>
    },
    {
      id: "03",
      name: <ContactGroup Image = {Hajira}  name = {'Muzamil Khan'} email={'Muzamil.khan@mwanmobile.org'} 
      Usertag={<><div className = "user-tag sale">Sale</div></>}/>
    },
    {
      id: "04",
      name: <ContactGroup Image = {Hajira}  name = {'Mohsin Khan'} email={'Mohsin.khan@mwanmobile.org'} 
      Usertag={<><div className = "user-tag sale">Sale</div></>}/>
    },
    {
      id: "05",
      name: <ContactGroup Image = {Hajira}  name = {'Anum Ihsan'} email={'hajiraeshan@mwanmobile.org'} 
      Usertag={<><div className = "user-tag sale">Sale</div></>}/>
    },
  ];