import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link } from 'react-router-dom';

import Select from "react-select";

import React, { useState } from 'react';

import Contact_ic from "../../images/contacts_icon.svg";

import '../template-listing/css/templateListing-header.css';

const ContactHeader = ({Contact_title, Contact_search}) => {

    const [ctshow, setCtShow] = useState(false);
    const [cvsshow, setCvsShow] = useState(false);
    const [tostshow, setTostShow] = useState(false);

    const options = [
        { value: 'mwan', label: 'Mwan Mobile' },
        { value: 'gcc', label: 'GCC Telehealth' },
        { value: 'africa health', label: 'Africa Telehealth' },
        { value: 'Mena telehealth', label: 'Mena Telehealth' }
    ]

    const showTost = () => {
        setTostShow(true)
    }

    const hideTost = () => {
        setTostShow(false)
    }

    const [fileName, setFileName] = useState('');
    const [error, setError] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 16 * 1024 * 1024) { // 16 MB in bytes
                setError('File size should be up to 16 MB');
                setFileName('');
            } else {
                setError('');
                setFileName(file.name);
            }
        }
    };

    return (
        <>
             <section className = "template-header py-3">
                <Container>
                    <Row className = "align-items-center">
                        <Col xs = {6} lg = {6}>
                            <Row className = "align-items-center">
                                <Col md = {12} lg = {3}>
                                    <div className = "template-heading">
                                        <img className = "me-2" src = {Contact_ic} alt = "icon"/> 
                                        {Contact_title}
                                    </div>
                                </Col>
                                <Col sm = {9} className = "ps-0 d-none d-lg-block">
                                    {Contact_search}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs = {6} lg = {6} className = "text-end">
                            <ul className = "nav align-items-center justify-content-end gap-2">
                                <li className = "nav-item">
                                    <Button className = "btn btn-main text-decoration-none btn-main-mob" onClick = {showTost}>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.5 10.5L10.5 14.732L14.5 10.541" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M10.5 3.5V14.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M4.5 17.5H16.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                         <span>Export Contacts</span>
                                    </Button>
                                </li>
                                <li className = "nav-item">
                                    <Button className = "btn btn-main text-decoration-none btn-main-mob" onClick={() => setCtShow(true)}>
                                        <i className ="bi bi-plus"></i> <span>Add Contact</span>
                                    </Button>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className = "template-header-mob d-lg-none pt-3">
                <Container>
                    <Row>
                        <Col xs = {12} className = "mb-3">
                            <Form.Group className = "form-group">
                                <Form.Control placeholder = "Search"/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </section>

            <div className = {`Success-downlaod tost-msg success ${tostshow ? 'show' : ''}`}>
                <div className = "tost-body">
                     <div className = "tost-icon">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.6266 11.7213L13.9066 17.4546L11.7066 15.2546C11.5871 15.1151 11.44 15.0017 11.2746 14.9217C11.1092 14.8416 10.929 14.7967 10.7454 14.7896C10.5617 14.7825 10.3786 14.8134 10.2075 14.8805C10.0364 14.9475 9.88104 15.0492 9.7511 15.1791C9.62116 15.309 9.51948 15.4644 9.45245 15.6355C9.38542 15.8066 9.35447 15.9897 9.36157 16.1734C9.36866 16.357 9.41363 16.5372 9.49366 16.7026C9.5737 16.868 9.68706 17.0151 9.82663 17.1346L12.96 20.2813C13.0846 20.4049 13.2323 20.5026 13.3948 20.569C13.5572 20.6353 13.7312 20.669 13.9066 20.668C14.2564 20.6665 14.5916 20.5276 14.84 20.2813L21.5066 13.6146C21.6316 13.4907 21.7308 13.3432 21.7985 13.1807C21.8662 13.0183 21.901 12.844 21.901 12.668C21.901 12.492 21.8662 12.3177 21.7985 12.1552C21.7308 11.9927 21.6316 11.8453 21.5066 11.7213C21.2568 11.473 20.9189 11.3336 20.5666 11.3336C20.2144 11.3336 19.8764 11.473 19.6266 11.7213ZM16 2.66797C13.3629 2.66797 10.785 3.44996 8.59236 4.91504C6.39971 6.38013 4.69074 8.46251 3.68157 10.8989C2.6724 13.3352 2.40836 16.0161 2.92283 18.6025C3.4373 21.1889 4.70717 23.5647 6.57188 25.4294C8.43658 27.2941 10.8123 28.564 13.3988 29.0784C15.9852 29.5929 18.6661 29.3289 21.1024 28.3197C23.5388 27.3105 25.6211 25.6016 27.0862 23.4089C28.5513 21.2162 29.3333 18.6384 29.3333 16.0013C29.3333 14.2503 28.9884 12.5165 28.3184 10.8989C27.6483 9.28118 26.6662 7.81133 25.4281 6.57321C24.1899 5.3351 22.7201 4.35297 21.1024 3.68291C19.4847 3.01285 17.7509 2.66797 16 2.66797ZM16 26.668C13.8903 26.668 11.828 26.0424 10.0739 24.8703C8.31976 23.6982 6.95259 22.0323 6.14525 20.0833C5.33792 18.1342 5.12668 15.9895 5.53826 13.9203C5.94983 11.8512 6.96573 9.95059 8.45749 8.45883C9.94925 6.96707 11.8499 5.95117 13.919 5.53959C15.9881 5.12802 18.1328 5.33925 20.0819 6.14659C22.031 6.95392 23.6969 8.3211 24.869 10.0752C26.041 11.8293 26.6666 13.8916 26.6666 16.0013C26.6666 18.8303 25.5428 21.5434 23.5424 23.5438C21.5421 25.5442 18.8289 26.668 16 26.668Z" fill="#6FCF97"/>
                        </svg>
                     </div>
                     <div className = "tost-content">
                        <div className = "msg-title">
                            Successful
                        </div>
                        <div>Contacts Exported Successfully.</div>
                     </div>
                     <div className = "btn hide-tost" onClick = {hideTost}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.94004 8.00012L13.14 3.80679C13.2656 3.68125 13.3361 3.51099 13.3361 3.33346C13.3361 3.15592 13.2656 2.98566 13.14 2.86012C13.0145 2.73459 12.8442 2.66406
                            12.6667 2.66406C12.4892 2.66406 12.3189 2.73459 12.1934 2.86012L8.00004 7.06012L3.80671 2.86012C3.68117 2.73459 3.51091 2.66406 3.33337 2.66406C3.15584 2.66406 2.98558 2.73459 2.86004 2.86012C2.7345 2.98566 2.66398 3.15592 2.66398 3.33346C2.66398 3.51099 2.7345 3.68125 2.86004 3.80679L7.06004 8.00012L2.86004 12.1935C2.79756 12.2554 2.74796 12.3292 2.71411 12.4104C2.68027 12.4916 2.66284 12.5788 2.66284 12.6668C2.66284 12.7548 2.68027 12.8419 2.71411 12.9232C2.74796 13.0044 2.79756 13.0781 2.86004 13.1401C2.92202 13.2026 2.99575 13.2522 3.07699 13.2861C3.15823 13.3199 3.24537 13.3373 3.33337 13.3373C3.42138 13.3373 3.50852 13.3199 3.58976 13.2861C3.671 13.2522 3.74473 13.2026 3.80671 13.1401L8.00004 8.94012L12.1934 13.1401C12.2554 13.2026 12.3291 13.2522 12.4103 13.2861C12.4916 13.3199 12.5787 13.3373 12.6667 13.3373C12.7547 13.3373 12.8419 13.3199 12.9231 13.2861C13.0043 13.2522 13.0781 13.2026 13.14 13.1401C13.2025 13.0781 13.2521 13.0044 13.286 12.9232C13.3198 12.8419 13.3372 12.7548 13.3372 12.6668C13.3372 12.5788 13.3198 12.4916 13.286 12.4104C13.2521 12.3292 13.2025 12.2554 13.14 12.1935L8.94004 8.00012Z" fill="black"/>
                         </svg>
                     </div>
                </div>
            </div>

            <Modal show={ctshow} onHide={() => setCtShow(false)} className = "whatsapp-modal modal-contact" centered>
                <Modal.Header className = "p-0">
                    <Modal.Title className = "d-flex align-items-center justify-content-between w-100">
                        <span>Add Contact</span>
                        <Button className = "import-cvs" onClick={()=>{setCtShow(false) 
                                setCvsShow(true)}}>
                            Import Contacts
                        </Button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <Form.Control type = "text" placeholder = "Enter name"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Phone No</Form.Label>
                                <Form.Control type = "text" placeholder = "Ex: 923025676625"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type = "email" placeholder = "Enter email"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group className = "contact-m-form">
                                <Form.Label>Select Group</Form.Label>
                                <Select className = "c-select basic-multi-select" options={options} placeholder = {'Select Group(s)'} 
                                classNamePrefix="select"/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className = "px-0">
                    <Button className = "btn-main-default" onClick={()=>setCtShow(false)}>
                        Cancel
                    </Button>
                    <Button  className = "btn-main" onClick={()=>setCtShow(false)}>
                        Add Contact
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={cvsshow} onHide={() => setCvsShow(false)} className = "whatsapp-modal modal-csv" centered>
                <Modal.Header className = "p-0">
                    <Modal.Title className = "d-flex align-items-center justify-content-between w-100">
                        <span>Import Contacts</span>
                        <Button className = "import-cvs" onClick={()=>{setCtShow(true) 
                            setCvsShow(false)}}>
                            Add Contact
                        </Button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className = "pt-0">
                    <Row>
                        <Col sm = {12}>
                            <div className = "download-template">
                                <p>
                                    Download the “CSV Template”, fill in the data, and then upload the file to the system.
                                </p>
                                <Button className = "btn btn-main">
                                    <svg className = "me-1" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.5 10.5L10.5 14.732L14.5 10.541" stroke="#165E5B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M10.5 3.5V14.5" stroke="#165E5B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M4.5 17.5H16.5" stroke="#165E5B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg> 
                                    Download Sample
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <div className = "Upload-cvs-file">
                                    <input type = "file" onChange={handleFileChange}/>
                                    <div className = "file-body">
                                        <i class="bi bi-file-earmark-text me-2"></i> 
                                        {fileName ? fileName : 'Upload a CSV file - up to 16 MB'}
                                    </div>
                                </div>
                                {error && <div className="error-message" style={{ color: 'red', fontSize: '12px' }}>{error}</div>}
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className = "px-0">
                    <Button className = "btn-main-default" onClick={()=>setCvsShow(false)}>
                        Cancel
                    </Button>
                    <Button  className = "btn-main" onClick={()=>setCvsShow(false)}>
                        Import
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default ContactHeader;