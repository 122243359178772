


const TemplateHeader = ({TemplateName, TemplateDate, Badgetext, TemplateCreated, statusClass}) => {
    return(
        <>
            <div className = "template-header-mob">
                <div className = "templete-name mb-1">
                    {TemplateName}
                </div>
                <div className = "templated-created-date">
                    {TemplateDate}
                </div>
                <div className = "d-flex align-items-center justify-content-between mt-3">
                    <div className = "status-show">
                        <span className={`status-badge ${statusClass}`}>
                            {Badgetext}
                        </span>
                    </div>
                    <div className = "templated-createdby">
                        Created By {TemplateCreated}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TemplateHeader;