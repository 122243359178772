

const BroadcastTablestatus = ({statusClass, statusTitle}) => {

    const statusColorMap = {
        Completed: '#2BD02E',  // Green for completed
        Pending: '#FEC931',    // Yellow for pending (example color, adjust as needed)
        Failed: '#F64F43', 
        Read: '#2BD02E',
        Delivered: '#669CEC',
    };
    
    // Function to get the background color based on the statusTitle
    const getStatusColor = (statusTitle) => {
        return statusColorMap[statusTitle] || '#CCCCCC';  // Default color if statusTitle is unknown
    };

    const statusStyle = {
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: getStatusColor(statusTitle),
    }

    return (
        <>
            <ul className = "nav align-items-center">
                <li className = "nav-item pe-2">
                    <div style = {statusStyle} className = {`status-circle ${statusClass}`}></div>
                </li>
                <li className = "nav-item">
                    <div className = "status-title">
                        {statusTitle}
                    </div>
                </li>
            </ul>
        </>
    );
};

export default BroadcastTablestatus;