
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useEffect, useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';

import "../components/chat/inbox.css";

const Chatplaceholder = () => {

    const [isActive, setIsActive] = useState(false);
    const [inputValue, setInputValue] = useState("");

    const refOne = useRef(null);

    const buttonClick = () => {
        setIsActive(true);
    }

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };   

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);
    const handleClickOutside = (event) => {
        if (refOne.current && !refOne.current.contains(event.target)) {
            setIsActive(false);
            setInputValue("");
        }
    };

    return (
        <div className = "main">
            <div className = "chat-section">
                <Row className = "mx-0">
                    <Col md = {4} xxl = {3} className = "d-none d-md-block">
                        <div className = "user-list h-100">
                            <div className = "user-list-header">
                                <Row className = "mx-0">
                                    <Col xs = {12} md = {12} className = "position-relative px-0">
                                        <Button variant = "main">
                                            New Message
                                        </Button>
                                        <input ref = {refOne} type = "text" value = {inputValue}  className = {isActive ? "active search" : "search"} onClick = {buttonClick} 
                                        onChange={handleInputChange}/>
                                    </Col>
                                </Row>
                            </div>
                            <div className = "active-user-list">
                                <div className = "single-user">
                                    <div className = "user-details placeholder-glow">
                                        <div className = "user-image placeholder"></div>
                                        <div className = "user-content w-75">
                                            <span className = "placeholder w-100 py-3 px-5"></span>
                                        </div>
                                    </div>                                          
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md = {5} xxl = {6}>
                        <div className = "live-chat-sec h-100">
                            <div className = "chat-header">
                                <ul className = "nav user-info">
                                    <li className = "nav-item placeholder-glow">
                                        <div className = "user-image  placeholder">
                                        </div>
                                    </li>
                                    <li className = "nav-item">
                                        <div className = "user-name  placeholder-glow">
                                            <span className = "placeholder py-3 px-5"></span>
                                        </div>
                                    </li>
                                </ul>
                                
                            </div>
                            <div className = "chat-message-sec">
                                <div className = "placeholder-glow row msg-row mx-0 h-100">
                                    <span className = "col-12 placeholder h-100"></span>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md = {3} xxl = {3} className = "d-none d-md-block">
                        <div className = "user-info-data h-100 empty-state">
                            <div className = "placeholder-glow">
                                <span className = "col-12 placeholder mb-2 py-3"></span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Chatplaceholder;