import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useState } from 'react';

import Select from "react-select";

import EmptyContactIcon from "../images/empty-contact_ic.svg";

import ContactHeader from "../components/contact-page/contact-header";

import { Link } from "react-router-dom";


const EmptyContact = () => {

    const [ctshow, setCtShow] = useState(false);
    const [cvsshow, setCvsShow] = useState(false);
    const [editshow, setEditShow] = useState(false);

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
        { value: 'apple', label: 'Apple' },
        { value: 'mango', label: 'Mango' },
        { value: 'grapes', label: 'Grapes' }
    ]


    return(
        <>

            <ContactHeader Contact_title={'Contacts'}/>

            <section className = "main inner-main templates-body-empty">
                    <Container>
                        <Row className = "align-items-center">
                            <Col xs = {12}>
                                <div className = "tb-empty-content text-center">
                                    <img src = {EmptyContactIcon} alt = "icon"/>
                                    <div className = "tb-page-title">
                                        Currently you don’t have any contact
                                    </div>
                                    <p>
                                        You can create a new contact by clicking the button below
                                    </p>
                                    <Button className = "btn-main" onClick={() => setCtShow(true)}>
                                        <i className ="bi bi-plus"></i> Add Contact
                                    </Button>
                                    <p>
                                        <Link to = "/contact" className = "btn btn-main mt-3">
                                        Contact page
                                        </Link>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
             </section>

            <Modal show={ctshow} onHide={() => setCtShow(false)} className = "whatsapp-modal modal-contact" centered>
                <Modal.Header className = "p-0">
                    <Modal.Title className = "d-flex align-items-center justify-content-between w-100">
                        <span>Add Contact</span>
                        <Button className = "import-cvs" onClick={()=>{setCtShow(false) 
                                setCvsShow(true)}}>
                            Import Contacts
                        </Button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <Form.Control type = "text" placeholder = "Enter name"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Phone No</Form.Label>
                                <Form.Control type = "text" placeholder = "Ex: 923025676625"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type = "email" placeholder = "Enter email"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group className = "contact-m-form">
                                <Form.Label>Select Group</Form.Label>
                                <Select className = "c-select basic-multi-select" options={options} placeholder = {'Select Group(s)'} 
                                classNamePrefix="select"/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className = "px-0">
                    <Button className = "btn-main-default" onClick={()=>setCtShow(false)}>
                        Cancel
                    </Button>
                    <Button  className = "btn-main" onClick={()=>setCtShow(false)}>
                        Add Contact
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={cvsshow} onHide={() => setCvsShow(false)} className = "whatsapp-modal modal-csv" centered>
                <Modal.Header className = "p-0">
                    <Modal.Title className = "d-flex align-items-center justify-content-between w-100">
                        <span>Import Contacts</span>
                        <Button className = "import-cvs" onClick={()=>{setCtShow(true) 
                            setCvsShow(false)}}>
                            Add Contact
                        </Button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className = "pt-0">
                    <Row>
                        <Col sm = {12}>
                            <div className = "download-template">
                                <p>
                                    Download the “CSV Template”, fill in the data, and then upload the file to the system.
                                </p>
                                <Button className = "btn btn-main">
                                    <svg className = "me-1" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.5 10.5L10.5 14.732L14.5 10.541" stroke="#165E5B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M10.5 3.5V14.5" stroke="#165E5B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M4.5 17.5H16.5" stroke="#165E5B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg> 
                                    Download Sample
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <div className = "Upload-cvs-file">
                                    <input type = "file"/>
                                    <div className = "file-body">
                                        <i class="bi bi-file-earmark-text me-2"></i> Upload a CSV file - up to 16 MB
                                    </div>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className = "px-0">
                    <Button className = "btn-main-default" onClick={()=>setCvsShow(false)}>
                        Cancel
                    </Button>
                    <Button  className = "btn-main" onClick={()=>setCvsShow(false)}>
                        Import
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={editshow} onHide={() => setEditShow(false)} className = "whatsapp-modal modal-contact" centered>
                <Modal.Header className = "p-0">
                    <Modal.Title>Add Contact</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm = {6}>
                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <Form.Control type = "text" placeholder = "Enter name"/>
                            </Form.Group>
                        </Col>
                        <Col sm = {6}>
                            <Form.Group>
                                <Form.Label>Phone No</Form.Label>
                                <Form.Control type = "text" placeholder = "Ex: 923025676625"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type = "email" placeholder = "Enter email"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Button className = "import-cvs" onClick={()=>{setCtShow(false) 
                                setCvsShow(true)}}>
                                Import Contacts From CSV File
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className = "px-0">
                    <Button className = "btn-main-default" onClick={()=>setEditShow(false)}>
                        Cancel
                    </Button>
                    <Button  className = "btn-main" onClick={()=>setEditShow(false)}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EmptyContact;