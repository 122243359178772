import Inbox from "./pages/Inbox";
import Header from "./components/header/Header";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/main-style.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import React,{ Fragment } from "react";
import {Route, Routes} from "react-router-dom"

import Login from "./pages/login";
import Reset from "./pages/resetpassword"
import Signup from "./pages/signup";
import Contact from "./pages/contact";
import Group from "./pages/group";
import Faq from "./pages/faq";
import Subscription from "./pages/subscription";
import Editprofile from "./pages/editprofile";
import Broadcast from "./pages/broadcast";
import Template from "./pages/template";
import Createtemplate from "./pages/createTemplate";
import Companylisting from "./pages/company_listing";
import Emptychat from "./pages/emty-chat";
import Placeholdertable from "./pages/placeholder-table";
import Chatplaceholder from "./pages/chat-placeholder";
import Facebookaccess from "./pages/facebookaccess";
import TemplateEmpty from "./pages/template-listing-empty";
import TemplateListing from "./pages/template-listing";
import EmptyUser from "./pages/empty-user";
import ManageUser from "./pages/manage-user";
import Integrations from "./pages/integrations";
import Websitewidget from "./pages/website-widget";
import EmptyContact from "./pages/empty-contact";
import EmptyGroup from "./pages/empty-group";
import EmptyBroadcast from "./pages/emptybroadcast";
import BroadcastListing from "./pages/broadcast-listing";
import EmptyTags from "./pages/empty-tags";
import Tags from "./pages/tags";
import BroadcastDetails from "./pages/broadcast-details";
import NewBroadcast from "./pages/newBroadcast";

function App() {
  return (
    <>
      <Fragment>
        <Header/>
        <Routes>
            <Route path= "/login" element={<Login/>}/>
              <Route path= "/inbox" element={<Inbox/>}/>
              <Route path= "/resetpassword" element={<Reset/>}/>
              <Route path= "/signup" element = {<Signup/>}/>
              <Route path= "/contact" element = {<Contact/>}/>
              <Route path="/group" element = {<Group/>}/>
              <Route path="/faq" element = {<Faq/>}/>
              <Route path="/subscription" element = {<Subscription/>}/>
              <Route path="/editprofile" element = {<Editprofile/>}/>
              <Route path="/broadcast" element = {<Broadcast/>}/>
              <Route path="/template" element = {<Template/>}/>
              <Route path="/createTemplate" element = {<Createtemplate/>}/>
              <Route path="/company_listing" element = {<Companylisting/>}/>
              <Route path="/emty-chat" element = {<Emptychat/>}/>
              <Route path= "/placeholder-table" element={<Placeholdertable/>}/>
              <Route path="/chat-placeholder" element={<Chatplaceholder/>}/>
              <Route path="/facebookaccess" element={<Facebookaccess/>}/>
              <Route path="/templateEmpty" element={<TemplateEmpty/>}/>
              <Route path="/templatelisting" element={<TemplateListing/>}/>
              <Route path="/emptyuser" element={<EmptyUser/>}/>
              <Route path="/manageuser" element={<ManageUser/>}/>
              <Route path = "/integrations" element = {<Integrations/>}/>
              <Route path = "/websitewidget" element = {<Websitewidget/>}/>
              <Route path = "/emptyContact" element = {<EmptyContact/>}/>
              <Route path = "/emptyGroup" element = {<EmptyGroup/>}/>
              <Route path = "/emptyBroadcast" element = {<EmptyBroadcast/>}/>
              <Route path = "/broadcastListing" element = {<BroadcastListing/>}/>
              <Route path = "/emptytags" element = {<EmptyTags/>}/>
              <Route path = "/tags" element = {<Tags/>}/>
              <Route path = "/broadcastDetails" element = {<BroadcastDetails/>}/>
              <Route path = "/newBroadcast" element = {<NewBroadcast/>}/>
              <Route path= "*" element={<Login/>}/>
      </Routes>
      </Fragment>
    </>
  );
}

export default App;
