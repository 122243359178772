

import Select from 'react-select';


const UserTags = () => {

    const Tags = [
        { value: 'Marketing', label: 'Event Marketing' },
        { value: 'sale', label: 'Sale' },
        { value: 'queries', label: 'Queries' },
    ]
    
    return (
        <>
            <div className = "user-info-data">
                <div className = "user-personal-info">
                    <div className = "info-row">
                        <div className = "title mb-2">Tag Assigned to this conversation:</div>
                        <Select isMulti className = "c-select basic-multi-select" options={Tags} placeholder = {'Choose Tag'} classNamePrefix="select"/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserTags;