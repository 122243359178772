

import "./broadcast.css";

const Broadcastcard = ({title,imageicon,number, iconClass}) => {
    return (
        <>
            <div className = "broadcast-card">
                <div className = "card-inner">
                    <div className = "broadcast-header">
                        <div className = "c-title">
                            {title}
                        </div>
                        <div className = "total-number">
                            {number}
                        </div>
                    </div>
                    <div className = {`card-icon d-flex align-items-center justify-content-center ${iconClass}`}>
                        <div className = "icon-s">
                            <img src = {imageicon} alt = "b-icon"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Broadcastcard;