
import Form from "react-bootstrap/Form";

import "./search.css";

const Search = () => {
    return (
        <Form.Group className = "input-search">
            <Form.Control type = "text" placeholder = "Search...."/>
        </Form.Group>
    )
}

export default Search;