
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import { Link } from "react-router-dom";

import Select from 'react-select';

import Closeic from "../images/icons/close-ic.svg";
import Reciver from "../images/icons/reciver.svg";
import Linkforward from "../images/icons/link-forward.svg";

const Createtemplate = () => {

    const[quickReply, setQuickReply] = useState(false);
    const[calltoaction, setCalltoaction] = useState(false);

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
        { value: 'apple', label: 'Apple' },
        { value: 'mango', label: 'Mango' },
        { value: 'grapes', label: 'Grapes' }
      ]

    const language = [
        { value: 'English', label: 'English' },
        { value: 'Urdu', label: 'Urdu' },
        { value: 'Punjabi', label: 'Punjabi' }
    ]

    const country = [
        { value: 'Pakistan', label: 'Pakistan' },
        { value: 'India', label: 'India' },
        { value: 'England', label: 'England' }
    ]

    const calloption = [
        { value: 'CallMe', label: 'CallMe' },
        { value: 'CallMe', label: 'CallMe' },
        { value: 'CallMe', label: 'CallMe' }
    ]

    const pageHeaderstyle = {
        boxShadow: '0px 3px 5px 0px #00000014',
        backgroundColor: '#fff',
        padding: '25px 0px'
    }

    const pageHeadingstyle = {
        fontWeight: '700',
        fontSize: '22px',
        color: '#000',
        lineHeight: '30px',
    }

    const breadcrumbLinkstyle = {
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '19px',
        color: '#4B4A4A',
    }

    const breadcrumbSpanstyle = {
        fontWeight: '600',
        color: '#4B4A4A',
        textDecoration: 'none',
    }

    const linkstyle = {
        textDecoration: 'none',
        marginRight: '5px'
    }

    const svg_iconstyle = {
        position: 'relative',
        top: '-1px',
    }

    return (
        <>
            <section style = {pageHeaderstyle} className = "page-header">
                <Container>
                    <Row className = "align-items-center">
                        <div style = {pageHeadingstyle} className = "page-heading col-md-6 d-none d-md-block">
                            Create New Template
                        </div>
                        <div style = {breadcrumbLinkstyle} className = "bc-links col-md-6 text-md-end">
                            <Link style = {linkstyle} to = "/templatelisting"> 
                                <span style = {breadcrumbSpanstyle}>
                                    <svg style = {svg_iconstyle} className = "me-2" width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 10L1 5.5L6 1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg> 
                                     Templates 
                                </span> 
                            </Link> 
                                 / Create new template
                        </div>
                    </Row>
                </Container>
            </section>

            <section className = "main inner-main">
                <section className = "contact-group-top">
                    <Container>
                        <Row>
                            <Col md = {7} lg = {8} className = "mb-3 mb-md-0">
                                <div className = "template-header mb-3 d-none">
                                    <div className = "template-heading">
                                        <Link to = "/"><i className ="bi bi-caret-left-fill"></i>Templates </Link> / Create new template
                                    </div>
                                </div>
                                <div className = "create-template-form">
                                    <Row>
                                        <Col sm = {12}>
                                            <Form.Group className = "form-group">
                                                <Form.Label>Name</Form.Label>
                                                <div className = "label-bottom">
                                                    Give a name to your template.
                                                </div>
                                                <div className = "input-body position-relative">
                                                    <Form.Control placeholder = "My template"/>
                                                    <span className = "word-limit">10/60</span>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm = {12}>
                                            <Form.Group>
                                                <Form.Label>Category</Form.Label>
                                                <div className = "label-bottom">
                                                    Choose a category.
                                                </div>
                                                <Select isMulti className = "c-select basic-multi-select" options={options} placeholder = {'Category name'} 
                                                classNamePrefix="select"/>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm = {12}>
                                            <Form.Group>
                                                <Form.Label>Languages</Form.Label>
                                                <div className = "label-bottom">
                                                    Choose a Language.
                                                </div>
                                                <Select className = "c-select" options={language} placeholder = {'English US'} closeMenuOnSelect={false} classNamePrefix="select"/>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm = {12}>
                                            <Form.Group className = "form-group">
                                                <Form.Label>Header</Form.Label>
                                                <div className = "label-bottom">
                                                    Add a title to your template.
                                                </div>
                                                <div className = "input-body position-relative">
                                                    <div className = "input-body position-relative">
                                                        <Form.Control placeholder = "My template"/>
                                                        <span className = "word-limit">10/60</span>
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm = {12}>
                                            <Form.Group className = "form-group fg-body">
                                                <Form.Label>Body</Form.Label>
                                                <div className = "label-bottom">
                                                    Enter the text for your message in the language you’ve selected.
                                                </div>
                                                <div className = "textarea-body position-relative">
                                                    <textarea className = "form-control" placeholder="Body text"  rows = "6"></textarea>
                                                    <span className = "word-limit">60/1024</span>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm = {12}>
                                            <Form.Group className = "form-group">
                                                <Form.Label>Footer</Form.Label>
                                                <div className = "label-bottom">
                                                    Add a short line of text to the bottom of your message template.
                                                </div>
                                                <div className = "input-body position-relative">
                                                    <Form.Control placeholder = "My template"/>
                                                    <span className = "word-limit">10/60</span>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm = {12}>
                                            <Form.Group>
                                                <Form.Label>Buttons</Form.Label>
                                                <div className = "label-bottom">
                                                    Choose a button that let users interact to your message.
                                                </div>
                                                <div className = "button-select">
                                                    <div className = "form-check form-check-inline" onClick={()=>{setQuickReply(true) 
                                                    setCalltoaction(false)}}>
                                                        <input className = "form-check-input" type = "radio" id = "inline-radio-1" name = "buttons"/>
                                                        <label className = "form-label" htmlFor = "inline-radio-1">Quick Reply</label>
                                                    </div>
                                                    <div className = "form-check form-check-inline" onClick={()=>{setCalltoaction(true) 
                                                    setQuickReply(false)}}>
                                                        <input className = "form-check-input" type = "radio" id = "inline-radio-2" name = "buttons"/>
                                                        <label className = "form-label"  htmlFor = "inline-radio-2">Call To Action</label>
                                                    </div>
                                                    <div className = "form-check form-check-inline" onClick={()=>{setCalltoaction(false) 
                                                    setQuickReply(false)}}>
                                                        <input className = "form-check-input" type = "radio" id = "inline-radio-3" name = "buttons"/>
                                                        <label className = "form-label"  htmlFor = "inline-radio-3">None</label>
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {quickReply && (
                                        <Row>
                                            <Col sm = {12}>
                                                <label className = "form-label">Call To Action</label>
                                                <div className = "button-details">
                                                    <div className = "button-text">
                                                        <Form.Control placeholder = "Add button text"/> 
                                                        <div className = "close-input">
                                                            <img src = {Closeic} alt = "close-icon"/>
                                                        </div>
                                                    </div>
                                                    <div className = "button-text">
                                                        <Form.Control placeholder = "Add button text"/>
                                                        <div className = "close-input">
                                                            <img src = {Closeic} alt = "close-icon"/>
                                                        </div>
                                                    </div>
                                                    <Button className = "add-btn">
                                                        <i className ="bi bi-plus"></i> Add More
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    )}

                                    {calltoaction && (
                                        <Row>
                                            <Col sm = {12}>
                                                <label className = "form-label">Call To Action</label>
                                                <div className = "button-details call-to-action-details">
                                                    <div className = "button-text">
                                                        <Select className = "c-select" options={calloption} placeholder = {'Call Me'}/>
                                                        <Form.Control placeholder = "button text"/> 
                                                        <Select className = "c-select" options={country} placeholder = {'Country'}/>
                                                        <Form.Control placeholder = "Phone number"/>
                                                        <div className = "close-input">
                                                            <img src = {Closeic} alt = "close-icon"/>
                                                        </div>
                                                    </div>
                                                    <div className = "button-text">
                                                        <Select className = "c-select" options={calloption} placeholder = {'Call Me'}/>
                                                        <Form.Control placeholder = "button text"/> 
                                                        <Select className = "c-select" options={country} placeholder = {'Country'}/>
                                                        <Form.Control placeholder = "Phone number"/>
                                                        <div className = "close-input">
                                                            <img src = {Closeic} alt = "close-icon"/>
                                                        </div>
                                                    </div>
                                                    <Button className = "add-btn">
                                                        <i className ="bi bi-plus"></i> Add More
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    )}


                                </div>
                                
                                <Row className = "pt-3">
                                    <Col sm = {12} className = "text-end">
                                        <button className = "btn btn-main">
                                            Create Template
                                        </button>
                                    </Col>
                                </Row>

                            </Col>
                            <Col md = {5} lg = {4}>
                                <div className = "preview-sec">
                                    <div className = "mob-heading text-center py-3 d-md-none">
                                        Message preview on WhatsApp
                                    </div>
                                    <div className = "preview-body">
                                        <div className = "preview-screen">
                                            <div className = "heading">
                                                Mwan Events
                                            </div>
                                            <div className = "timing">
                                                Event Timings 
                                            </div>
                                            <div className = "content">
                                                Hello everyone, the event starts at 12 o'clock promptly on March 7th, 2023. the event starts at 12 o'clock promptly on March 7th, 2023.the event starts at 12 o'clock promptly.
                                            </div>
                                            <div className = "heading">
                                                Best Regards
                                            </div>
                                        </div>
                                        <Row className = "link-footer mx-0">
                                            <Col xs = {6} className = "px-0">
                                                <a href = "#"> <img src = {Reciver} alt = "icon"/> Call me</a>
                                            </Col>
                                            <Col xs = {6} className = "px-0">
                                                <a href = "#"><img src = {Linkforward} alt = "icon"/> Register Now</a>
                                            </Col>
                                        </Row>
                                    </div>
                                    <Row className = "event-time">
                                        <Col xs = {6} className = "pe-1">
                                            <div className = "e-time start-time">
                                                12:00 PM
                                            </div>
                                        </Col>
                                        <Col xs = {6} className = "ps-1">
                                            <div className = "e-time end-time">
                                                2:00 PM
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </section>
        </>
    )
}

export default Createtemplate;