
import React, { useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Grouptable from "../components/group-table/g-table";
import Search from "../components/search/search";
import Modaltable from '../components/modal-table/mTable';
// import Groupcontact from '../components/group-table/groupcontact';
// import Hajira from "../images/hajira.png";

import "./../components/group-table/group-contact.css";
import Allcontacts from "../components/group-table/all-contacts";
import GroupHeader from "../components/Group-page/group-header";
//import Groupcontact from "../components/group-table/groupcontact";
import TemplateListingFooter from "../components/template-listing/templateListing-footer";
import GroupMobile from "../components/Group-page/group-mobile";

const Contact = () => {

    const [cgshow, setCgShow] = useState(false);
    const [cgtshow, setCgtShow] = useState(false);
    const [cvsshow, setCvsShow] = useState(false);
    const [newgroupshow, setNewgroupShow] = useState(false);

    const inputRef = useRef(null);

    const handleClick = () => {
        inputRef.current.click();
    };

    return (
        <>

            <section className = "main inner-main">
                
                <GroupHeader Contact_title={'Groups'} Contact_search={<Search/>}/>

                <section className = "contact-group-table pt-3 pt-lg-5 d-none d-lg-block">
                    <Container>
                        <Row>
                            <Col xs = {12}>
                                <Grouptable/>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className = "main inner-main template-listing pb-3 d-lg-none">
                    <GroupMobile/>
                </section>

                <TemplateListingFooter/>

            </section>

            <Modal show={cgshow} onHide={() => setCgShow(false)} className = "whatsapp-modal modal-contact" centered>
                <Modal.Header className = "p-0">
                    <Modal.Title>Create Group</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Group Name</Form.Label>
                                <Form.Control type = "text" placeholder = "Mwan Mobile"/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className = "px-0">
                    <Button className = "btn-main-default" onClick={()=>setCgShow(false)}>
                        Cancel
                    </Button>
                    <Button  className = "btn-main" onClick={()=>{setCgShow(false) 
                        setCgtShow(true)}}>
                        Next
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={cgtshow} onHide={() => setCgtShow(false)} className = "whatsapp-modal modal-g-contact" centered>
                <Modal.Header className = "p-0">
                    <Modal.Title className = "mb-1 w-100">
                        Add Group Member
                        <span className = "finish-btn" onClick={()=>{setCgtShow(false) 
                        setNewgroupShow(true)}}>
                            Next <i className ="bi bi-chevron-right"></i>
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className = "pt-0">
                    {/* <Row>
                        <Col sm = {12}>
                            <Button className = "import-cvs" onClick={()=>{setCgtShow(false) 
                                setCvsShow(true)}}>
                                Import Contacts From CSV File
                            </Button>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Control type = "text" placeholder = "Search Contact"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <div className = "col-12">
                            <Allcontacts/>
                        </div>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={newgroupshow} onHide={() => setNewgroupShow(false)} className = "whatsapp-modal modal-contact" centered>
                <Modal.Header className = "p-0" closeButton>
                    <Modal.Title className = "text-center w-100">
                        <div className="back-btn" onClick = {()=>{setNewgroupShow(false) 
                        setCgtShow(true)}}>
                            <i className ="bi bi-chevron-left"></i>
                        </div>
                        New Group
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Group Name</Form.Label>
                                <Form.Control type = "text" placeholder = "Mwan Mobile"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Participants: 3 of 5</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className = "px-0">
                    <Button  className = "btn-main" onClick={()=>{setNewgroupShow(false) 
                        setCvsShow(true)}}>
                        Create Group
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={cvsshow} onHide={() => setCvsShow(false)} className = "whatsapp-modal modal-csv" centered>
                <Modal.Header className = "p-0 border-0" closeButton>
                    <Modal.Title className = "w-100 text-center">
                        <div className="back-btn" onClick = {()=>{setCvsShow(false) 
                            setNewgroupShow(true)}}>
                            <i className ="bi bi-chevron-left"></i>
                        </div>
                        Import contacts from CSV File 
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className = "pt-0">
                    <Row className = "mb-1">
                        <Col sm = {12}>
                        <Form.Group controlId="formFile" className = "position-relative">
                            <Form.Control className = "file-up" type="file"  ref={inputRef}/>
                            <div className = "input-label" onClick={handleClick}>
                                UPload
                            </div>
                        </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <div className = "modal-table">
                                <Modaltable/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Button className = "import-cvs">
                                Import Contacts From CSV File
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className = "px-0">
                    <Button className = "btn-main-default" onClick={()=>setCvsShow(false)}>
                        Cancel
                    </Button>
                    <Button  className = "btn-main" onClick={()=>setCvsShow(false)}>
                        Import Contact
                    </Button>
                </Modal.Footer>
            </Modal>
    </>
    )
}

export default Contact;