
import React, { useState } from 'react';

import Form from "react-bootstrap/Form";

const InsertTemplate = () => {

    const [activeId, setActiveId] = useState(null);

    const handleRadioChange = (id) => {
        setActiveId(id);
      };

    return (
        <>
            <div className = "select_template">
                <Form.Group className = {`form-group mb-3 ${activeId === 'radio-1' ? 'active' : ''}`}>
                    <div className = "form-check d-flex align-items-center justify-content-between ps-0 mb-0">
                        <label htmlFor = "radio-1">
                            <div className = "template-label">
                                <div className = "template-name">
                                    Mena Telehealth
                                </div>
                                <div className = "tempalte-leng">
                                    Language: en_GB
                                </div>
                            </div>
                        </label>
                        <input className = "form-check-input" type = "radio"  id = "radio-1" name = "select-tempalte" 
                        checked={activeId === 'radio-1'} onChange={()=>handleRadioChange('radio-1')}/>
                    </div>

                </Form.Group>

                <Form.Group className = {`form-group mb-3 ${activeId === 'radio-2' ? 'active' : ''}`}>
                    <div className = "form-check d-flex align-items-center justify-content-between ps-0 mb-0">
                        <label htmlFor = "radio-2">
                            <div className = "template-label">
                                <div className = "template-name">
                                    Meena telehealth Announcement
                                </div>
                                <div className = "template-name w-date">
                                    20 April 2024
                                </div>
                                <div className = "tempalte-leng">
                                    Language: en_GB
                                </div>
                            </div>
                        </label>
                        <input className = "form-check-input" type = "radio"  id = "radio-2" name = "select-tempalte" 
                        checked={activeId === 'radio-2'} onChange={()=>handleRadioChange('radio-2')}/>
                    </div>

                </Form.Group>

                <Form.Group className = {`form-group mb-3 ${activeId === 'radio-3' ? 'active' : ''}`}>
                    <div className = "form-check d-flex align-items-center justify-content-between ps-0 mb-0">
                        <label htmlFor = "radio-3">
                            <div className = "template-label">
                                <div className = "template-name">
                                    MWAN Mobile
                                </div>
                                <div className = "tempalte-leng">
                                    Language: en_GB
                                </div>
                            </div>
                        </label>
                        <input className = "form-check-input" type = "radio"  id = "radio-3" name = "select-tempalte" 
                        checked={activeId === 'radio-3'} onChange={()=>handleRadioChange('radio-3')}/>
                    </div>

                </Form.Group>

                <Form.Group className = {`form-group mb-3 ${activeId === 'radio-4' ? 'active' : ''}`}>
                    <div className = "form-check d-flex align-items-center justify-content-between ps-0 mb-0">
                        <label htmlFor = "radio-4">
                            <div className = "template-label">
                                <div className = "template-name">
                                    GCC eHealth
                                </div>
                                <div className = "tempalte-leng">
                                    Language: en_GB
                                </div>
                            </div>
                        </label>
                        <input className = "form-check-input" type = "radio"  id = "radio-4" name = "select-tempalte" 
                        checked={activeId === 'radio-4'} onChange={()=>handleRadioChange('radio-4')}/>
                    </div>

                </Form.Group>

                <Form.Group className = {`form-group mb-3 ${activeId === 'radio-5' ? 'active' : ''}`}>
                    <div className = "form-check d-flex align-items-center justify-content-between ps-0 mb-0">
                        <label htmlFor = "radio-5">
                            <div className = "template-label">
                                <div className = "template-name">
                                    Meena telehealth 
                                </div>
                                <div className = "tempalte-leng">
                                    Language: en_GB
                                </div>
                            </div>
                        </label>
                        <input className = "form-check-input" type = "radio"  id = "radio-5" name = "select-tempalte" 
                        checked={activeId === 'radio-5'} onChange={()=>handleRadioChange('radio-5')}/>
                    </div>

                </Form.Group>
            </div>
        </>
    )
}

export default InsertTemplate;