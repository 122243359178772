
import Accordion from "react-bootstrap/Accordion"

import "./accordion.css"

const Faqaccordion = ({title, description}) => {
    return (
        <>
            <Accordion defaultActiveKey="0">
                <Accordion.Item>
                        <Accordion.Header>
                            {title}
                        </Accordion.Header>
                        <Accordion.Body>
                            {description}
                        </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default Faqaccordion;