import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link } from 'react-router-dom';

import Select from 'react-select';

import emptyUser_ic from "../../images/icons/user-icon-header.svg";

import '../template-listing/css/templateListing-header.css';

const MangageUserHeader = () => {
    
    const tags = [
        { value: 'marketing', label: 'Marketing' },
        { value: 'sale', label: 'Sale' },
        { value: 'queries', label: 'Genral Queries' }
    ]

    const status = [
        { value: 'active', label: 'Active' },
        { value: 'approved', label: 'Approved' },
        { value: 'pending', label: 'Pending' },
        {valut: 'deactivated', label: 'Deactivated'},
        { value: 'rejected', label: 'Rejected' }
    ]

    return (
        <>
             <section className = "template-header py-3">
                <Container>
                    <Row className = "align-items-center">
                        <Col xs = {8} lg = {3}>
                            <div className = "template-heading">
                                <img src = {emptyUser_ic} alt = "icon"/> Mangage Users 
                            </div>
                        </Col>
                        <Col md = {6} className = "d-none d-lg-block">
                            <Row>
                                <Col md = {6} className = "px-md-1">
                                    <Form.Group className = "form-group">
                                        <Form.Control placeholder = "Search by name or email"/>
                                    </Form.Group>
                                </Col>
                                <Col md = {3} className = "px-md-1">
                                    <Form.Group>
                                        <Select className = "c-select" options={status} placeholder = {'Filter Status'} 
                                        classNamePrefix="select"/>
                                    </Form.Group>
                                </Col>
                                <Col md = {3} className = "px-md-1">
                                    <Form.Group>
                                        <Select className = "c-select" options={tags} placeholder = {'Filter Tag'} classNamePrefix="select"/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs = {4} lg = {3} className = "text-end">
                            <Link to = "/manageuser" className = "btn btn-main text-decoration-none btn-main-mob">
                                <i className ="bi bi-plus"></i> <span>Add User</span>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className = "template-header-mob d-lg-none py-3">
                <Container>
                    <Row>
                        <Col xs = {12} className = "mb-3">
                            <Form.Group className = "form-group">
                                <Form.Control placeholder = "Search"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className = "align-items-center">
                        <Col xs = {6} className = "pe-1">
                            <Form.Group>
                                <Select className = "c-select" options={status} placeholder = {'Filter Status'} 
                                classNamePrefix="select"/>
                            </Form.Group>
                        </Col>
                        <Col xs = {6} className = "ps-1">
                            <Form.Group>
                                <Select className = "c-select" options={tags} placeholder = {'Filter Tags'} closeMenuOnSelect={false} classNamePrefix="select"/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default MangageUserHeader;