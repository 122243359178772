

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Resetform from '../components/wahtsapp_form/form-reset';

import '../components/wahtsapp_form/form.css';

const Login = ()=>{

    return(
        <section className = "wahtsapp_form">
             <Container>
                <Row>
                    <Col sm = {12}>
                        <Resetform heading = {'Reset Password'}/>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Login;