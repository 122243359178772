
// import React, { useState } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ContactgroupTable from "../components/contact-table/c-table";
import Search from "../components/search/search";
import ContactHeader from '../components/contact-page/contact-header';
import TemplateListingFooter from "../components/template-listing/templateListing-footer";
import ContactMobile from "../components/contact-page/contact-mobile";

const Contact = () => {

    return (
        <section className = "main inner-main">
            {/* <section className = "contact-group-top">
                <Container>
                    <Row className = "align-items-center">
                        <Col sm = {6} className = "mb-3 mb-sm-0">
                            <Search/>
                        </Col>
                        <Col sm = {6}>
                            <div className = "btn-sec text-sm-end">
                                <Button className = "btn-main" onClick={() => setCtShow(true)}>
                                    <i className ="bi bi-plus"></i> Add Contact
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> */}

            <ContactHeader Contact_title={'Contacts'} Contact_search={<Search/>}/>

            <section className = "contact-group-table pt-md-2 pt-lg-4 d-none d-lg-block">
                <Container>
                    <Row>
                        <Col xs = {12}>
                            <ContactgroupTable/>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className = "main inner-main template-listing pb-3 d-lg-none">
                <ContactMobile/>
            </section>

            <TemplateListingFooter/>

            

        </section>
    )
}

export default Contact;