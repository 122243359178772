
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";

import TemplateHeader from "./accordion-header";
import TemplateBodymob from "./template-accordion-body";

import './css/table-listing-mob.css';

const TemplateListingMob = () => {
    return (
        <>
        <Container>
            <Row>
                <Col xs = {12}>
                    <div className = "template-listing-collapse">
                        <div className = "tlc-header">
                            Templates Name
                        </div>
                        <div className = "tlc-body">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey = "0">
                                    <Accordion.Header>
                                        <TemplateHeader TemplateName={'Mena telehealth'} TemplateDate={'Jan-14-2024'} Badgetext={'In review'}
                                        statusClass={'in-review'} TemplateCreated={'Asad Mahmood'}/>
                                    </Accordion.Header>
                                    <Accordion.Body className = "pt-0">
                                        <TemplateBodymob CategoryTitle={'Marketing'} LanguageTitle={'EN_US'} />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey = "1">
                                    <Accordion.Header>
                                    <TemplateHeader TemplateName={'Mena telehealth'} TemplateDate={'Jan-14-2024'} Badgetext={'Rejected'}
                                        statusClass={'rejected'} TemplateCreated={'Asad Mahmood'}/>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <TemplateBodymob CategoryTitle={'Marketing'} LanguageTitle={'EN_US'} />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey = "2">
                                    <Accordion.Header>
                                        <TemplateHeader TemplateName={'Mena telehealth'} TemplateDate={'Jan-14-2024'} Badgetext={'In review'}
                                        statusClass={'in-review'} TemplateCreated={'Asad Mahmood'}/>
                                    </Accordion.Header>
                                    <Accordion.Body className = "pt-0">
                                        <TemplateBodymob CategoryTitle={'Marketing'} LanguageTitle={'EN_US'} />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey = "3">
                                    <Accordion.Header>
                                        <TemplateHeader TemplateName={'Mena telehealth'} TemplateDate={'Jan-14-2024'} Badgetext={'Approved'}
                                        statusClass={'approved'} TemplateCreated={'Asad Mahmood'}/>
                                    </Accordion.Header>
                                    <Accordion.Body className = "pt-0">
                                        <TemplateBodymob CategoryTitle={'Marketing'} LanguageTitle={'EN_US'} />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey = "4">
                                    <Accordion.Header>
                                        <TemplateHeader TemplateName={'Mena telehealth'} TemplateDate={'Jan-14-2024'} Badgetext={'In review'}
                                        statusClass={'in-review'} TemplateCreated={'Asad Mahmood'}/>
                                    </Accordion.Header>
                                    <Accordion.Body className = "pt-0">
                                        <TemplateBodymob CategoryTitle={'Marketing'} LanguageTitle={'EN_US'} />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey = "5">
                                    <Accordion.Header>
                                        <TemplateHeader TemplateName={'Mena telehealth'} TemplateDate={'Jan-14-2024'} Badgetext={'Approved'}
                                        statusClass={'approved'} TemplateCreated={'Asad Mahmood'}/>
                                    </Accordion.Header>
                                    <Accordion.Body className = "pt-0">
                                        <TemplateBodymob CategoryTitle={'Marketing'} LanguageTitle={'EN_US'} />
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        </>
    );
};

export default TemplateListingMob;