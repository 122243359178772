
//import React, {useState } from 'react';

const Userlist = ({UserClass,Image,Name,Messagetitle,Time,ChatTag,TagClass,MessageNumber,handleButtonClick}) => {

    return (
        <div className = {`single-user ${UserClass}`} onClick = {handleButtonClick}>
            <div className = "user-details">
                <div className = "user-image">
                    <img src = {Image} alt = "user-img"/>
                    <span className = "online-status"></span>
                </div>
                <div className = "user-content w-100">
                    <div className = "user-name">
                        {Name}
                    </div>
                    <div className = "msg-title">
                        {Messagetitle}
                    </div>
                    <div className = "d-flex align-items-center justify-content-between pt-3">
                        <div className = {`chat-tag ${TagClass}`}>
                            {ChatTag}
                        </div>
                        <div className = "time-sec">
                            {Time}
                        </div>
                        <div className = "chat-msg-badge">
                            {MessageNumber}
                        </div>
                    </div>
                </div>
            </div>                                          
        </div>
    )
}

export default Userlist;