import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link } from 'react-router-dom';

import Select from 'react-select';

import Template_ic from "../../images/icons/template-ic.svg";

import './css/templateListing-header.css';

const TemplateListingHeader = () => {
    
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
        { value: 'apple', label: 'Apple' },
        { value: 'mango', label: 'Mango' },
        { value: 'grapes', label: 'Grapes' }
    ]

    const status = [
        { value: 'All', label: 'All' },
        { value: 'approved', label: 'Approved' },
        { value: 'pending', label: 'Pending' },
        { value: 'rejected', label: 'Rejected' }
    ]

    return (
        <>
             <section className = "template-header py-3">
                <Container>
                    <Row className = "align-items-center">
                        <Col xs = {6} lg = {3}>
                            <div className = "template-heading">
                                <img src = {Template_ic} alt = "icon"/> Message Templates 
                            </div>
                        </Col>
                        <Col md = {6} className = "d-none d-lg-block">
                            <Row>
                                <Col md = {6} className = "px-md-1">
                                    <Form.Group className = "form-group">
                                        <Form.Control placeholder = "Search"/>
                                    </Form.Group>
                                </Col>
                                <Col md = {3} className = "px-md-1">
                                    <Form.Group>
                                        <Select className = "c-select" options={options} placeholder = {'Category'} 
                                        classNamePrefix="select"/>
                                    </Form.Group>
                                </Col>
                                <Col md = {3} className = "px-md-1">
                                    <Form.Group>
                                        <Select className = "c-select" options={status} placeholder = {'Status'} classNamePrefix="select"/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs = {6} lg = {3} className = "text-end">
                            <Link to = "/createTemplate" className = "btn btn-main text-decoration-none btn-main-mob">
                                <i className ="bi bi-plus"></i> <span>Create Template</span>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className = "template-header-mob d-lg-none py-3">
                <Container>
                    <Row>
                        <Col xs = {12} className = "mb-3">
                            <Form.Group className = "form-group">
                                <Form.Control placeholder = "Search"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className = "align-items-center">
                        <Col xs = {6} className = "pe-1">
                            <Form.Group>
                                <Select className = "c-select" options={options} placeholder = {'Category'} 
                                classNamePrefix="select"/>
                            </Form.Group>
                        </Col>
                        <Col xs = {6} className = "ps-1">
                            <Form.Group>
                                <Select className = "c-select" options={status} placeholder = {'Status'} closeMenuOnSelect={false} classNamePrefix="select"/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default TemplateListingHeader;