
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";

import Broadcastname from "./broadcast-name";
import BroadcastTablestatus from "./broadcast-status";
import BroadcastType from "./broadcast-type";
import DeliveryStatus from "./delivery-status";
import BroadcastAction from "./broadcast-action";

import '../manageUser/css/usermob.css';

const BroadcastlistingMob = () => {

    return (
        <>
        <Container>
            <Row>
                <Col xs = {12}>
                    <div className = "template-listing-collapse">
                        <div className = "tlc-header">
                            Broadcast Name
                        </div>
                        <div className = "tlc-body">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey = "0">
                                    <Accordion.Header>
                                        <div className = "username-broadcastmob">
                                            <Broadcastname EventName={'GCC eHealth Event Announcement'} CreatedName={'Muzammil Ahmed Khan'}/>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Created At</div>
                                            <div className = "cb-date">Apr-18-2023</div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Status</div>
                                            <div className = "phone_number">
                                                <BroadcastTablestatus statusClass={'status-title'} statusTitle={'Completed'}/>
                                            </div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Audience</div>
                                            <div className = "phone_number">
                                                35
                                            </div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Broadcast Type</div>
                                            <div className = "phone_number">
                                                <BroadcastType className={'scheduled'} Typename={'Scheduled'} TypeDateTime={'Apr-18-2023 07:00 PM'}/>
                                            </div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <Row>
                                                <Col xs = {12}>
                                                    <div className = "phone_number">
                                                        <DeliveryStatus CompletedValue={'60'} PendingValue={'30'} FailedValue={'10'}/>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className = "contact-list d-flex justify-content-end mb-3">
                                            <Row className = "w-100">
                                                <Col xs = {12} className = "text-end">
                                                    <BroadcastAction Link_add={'/broadcastDetails'}/>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey = "1">
                                    <Accordion.Header>
                                        <div className = "username-broadcastmob">
                                            <Broadcastname EventName={'Discount offer for new customers'} CreatedName={'Muzammil Ahmed Khan'}/>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Created At</div>
                                            <div className = "cb-date">Apr-18-2023</div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Status</div>
                                            <div className = "phone_number">
                                                <BroadcastTablestatus statusClass={'status-title'} statusTitle={'Pending'}/>
                                            </div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Audience</div>
                                            <div className = "phone_number">
                                                35
                                            </div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Broadcast Type</div>
                                            <div className = "phone_number">
                                                <BroadcastType className={'scheduled'} Typename={'Scheduled'} TypeDateTime={'Apr-18-2023 07:00 PM'}/>
                                            </div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <Row className = "w-100">
                                                <Col xs = {12}>
                                                    <div className = "phone_number">
                                                        <DeliveryStatus CompletedValue={'100'} PendingValue={'0'} FailedValue={'0'}/>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className = "contact-list d-flex justify-content-end mb-3">
                                            <Row className = "w-100">
                                                <Col xs = {12} className = "text-end">
                                                    <BroadcastAction Link_add={'/broadcastDetails'}/>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey = "2">
                                    <Accordion.Header>
                                        <div className = "username-broadcastmob">
                                            <Broadcastname EventName={'Registration confirmation message to the registered user'} CreatedName={'Asad Mehmood'}/>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Created At</div>
                                            <div className = "cb-date">Apr-18-2023</div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Status</div>
                                            <div className = "phone_number">
                                                <BroadcastTablestatus statusClass={'status-title'} statusTitle={'Completed'}/>
                                            </div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Audience</div>
                                            <div className = "phone_number">
                                                35
                                            </div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Broadcast Type</div>
                                            <div className = "phone_number">
                                                <BroadcastType className={'scheduled'} Typename={'Scheduled'} TypeDateTime={'Apr-18-2023 07:00 PM'}/>
                                            </div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <Row>
                                                <Col xs = {12}>
                                                    <div className = "phone_number">
                                                        <DeliveryStatus CompletedValue={'60'} PendingValue={'30'} FailedValue={'10'}/>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className = "contact-list d-flex justify-content-end mb-3">
                                            <Row className = "w-100">
                                                <Col xs = {12} className = "text-end">
                                                    <BroadcastAction Link_add={'/broadcastDetails'}/>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey = "3">
                                    <Accordion.Header>
                                        <div className = "username-broadcastmob">
                                            <Broadcastname EventName={'Registration confirmation message to the registered user'} CreatedName={'Asad Mehmood'}/>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Created At</div>
                                            <div className = "cb-date">Apr-18-2023</div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Status</div>
                                            <div className = "phone_number">
                                                <BroadcastTablestatus statusClass={'status-title'} statusTitle={'Failed'}/>
                                            </div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Audience</div>
                                            <div className = "phone_number">
                                                35
                                            </div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Broadcast Type</div>
                                            <div className = "phone_number">
                                                <BroadcastType className={'scheduled'} Typename={'Scheduled'} TypeDateTime={'Apr-18-2023 07:00 PM'}/>
                                            </div>
                                        </div>
                                        <div className = "contact-list d-flex justify-content-end mb-3">
                                            <Row className = "w-100">
                                                <Col xs = {12}>
                                                    <div className = "phone_number">
                                                        <DeliveryStatus CompletedValue={'60'} PendingValue={'30'} FailedValue={'10'}/>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <Row>
                                                <Col xs = {12} className = "text-end">
                                                    <BroadcastAction Link_add={'/broadcastDetails'}/>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        </>
    );
};

export default BroadcastlistingMob;