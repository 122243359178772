import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
// import { Link } from 'react-router-dom';

// import Select from "react-select";

import React, { useState, useRef} from 'react';

import Group_ic from "../../images/groups/groups_icon.svg";
import Allcontacts from "../group-table/all-contacts";
// import Modaltable from "../modal-table/mTable";

import '../template-listing/css/templateListing-header.css';

const GroupHeader = ({Contact_title, Contact_search}) => {

    const [cgtshow, setCgtShow] = useState(false);
    // const [cgshow, setCgShow] = useState(false);
    const [newgroupshow, setNewgroupShow] = useState(false);
    // const [cvsshow, setCvsShow] = useState(false);

    // const inputRef = useRef(null);

    // const handleClick = () => {
    //     inputRef.current.click();
    // };
    
    return (
        
        <>
             <section className = "template-header py-3">
                <Container>
                    <Row className = "align-items-center">
                        <Col xs = {6} lg = {4}>
                            <Row className = "align-items-center">
                                <Col md = {12}>
                                    <div className = "template-heading">
                                        <img className = "me-2" src = {Group_ic} alt = "icon"/> 
                                        {Contact_title}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs = {6} lg = {8}>
                            <Row className = "align-items-center justify-content-end">
                                <Col md = {12} lg = {8} xl = {9} className = "d-none d-lg-block group-search">
                                    {Contact_search}
                                </Col>
                                <Col md = {12} lg = {4} xl = {3} className = "text-end">
                                    <Button className = "btn btn-main text-decoration-none btn-main-mob w-100" onClick={() => setCgtShow(true)}>
                                        <i className ="bi bi-plus"></i> <span>Create Group</span>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className = "template-header-mob d-lg-none pt-3">
                <Container>
                    <Row>
                        <Col xs = {12} className = "mb-3">
                            <Form.Group className = "form-group">
                                <Form.Control placeholder = "Search"/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Modal show={cgtshow} onHide={() => setCgtShow(false)} className = "whatsapp-modal modal-g-contact" centered>
                <Modal.Header className = "p-0">
                    <Modal.Title className = "mb-3 w-100">
                        Add Group Member
                         <span className = "finish-btn" onClick={()=>{setCgtShow(false) 
                        setNewgroupShow(true)}}>
                            Next <i className ="bi bi-chevron-right"></i>
                         </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className = "pt-0">
                    {/* <Row>
                        <Col sm = {12}>
                            <Button className = "import-cvs">
                                Import Contacts From CSV File
                            </Button>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Control className = "search" type = "text" placeholder = "Search Contact"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <div className = "col-12">
                            <Allcontacts/>
                        </div>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={newgroupshow} onHide={() => setNewgroupShow(false)} className = "whatsapp-modal modal-contact" centered>
                <Modal.Header className = "p-0" closeButton>
                    <Modal.Title className = "text-center w-100">
                        <div className="back-btn" onClick = {()=>{setNewgroupShow(false) 
                        setCgtShow(true)}}>
                            <i className ="bi bi-chevron-left"></i>
                        </div>
                        New Group
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Group Name</Form.Label>
                                <Form.Control type = "text" placeholder = "Mwan Mobile"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Participants: 3 of 5</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className = "px-0">
                    <Button  className = "btn-main" onClick={()=>{setNewgroupShow(false)}}>
                        Create Group
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default GroupHeader;