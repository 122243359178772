
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import UserListingtable from "../components/manageUser/usertable";
import MangageUserHeader from "../components/manageUser/userheader";
import ManageUsermob from "../components/manageUser/manageuser-mob";

import TemplateListingFooter from "../components/template-listing/templateListing-footer";

const ManageUser = () => {
    return (
        <>
            <MangageUserHeader/>

            <section className = "contact-group-table py-3 d-none d-lg-block">
                <Container>
                    <Row>
                        <Col xs = {12}>
                            <UserListingtable/>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className = "main inner-main template-listing pt-2 pb-3 d-lg-none">
                <ManageUsermob/>
            </section>

            <TemplateListingFooter/>
        </>
    );
};

export default ManageUser;