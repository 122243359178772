
import Container from 'react-bootstrap/Container';
import { useState, useEffect, useRef } from "react";
import { Link, useLocation } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';

import Logo from "../../images/logos/utter_logo.svg";
import Inbox_icon from '../../images/icons/inbox_ic.svg';
import Contact_icon from '../../images/icons/contact_ic.svg';
import BroadCast_icon from '../../images/icons/broadcast_ic.svg';
import Template_icon from '../../images/icons/template_ic.svg';
import Group_icon from '../../images/icons/group_ic.svg';
import Manage_icon from '../../images/icons/manage-icon.svg';
import Tags_icon from '../../images/icons/tag_icons.svg';
import Empty_notification from '../../images/logos/Bell-icon.svg';
import Menu_ic from '../../images/logos/menu-ic.svg';
import Dashboad_icon from '../../images/icons/dashboard_ic.svg';
import "./header.css";


    const Header = ()=> {
        //const lnks = ['/login','/signup']
        const [currentLink,setCurrentLink] = useState('')
        const [companyBtn,setCompanyBtn] = useState(true)
        const [profileBtn, setProfileBtn] = useState(false)

        useEffect(()=>{
            let links = ['/login','/signup','/resetpassword', '/facebookaccess']
            links?.includes(window.location.pathname) ==true ?setCompanyBtn(true):setCompanyBtn(false)   
        },[])

        useEffect(()=>{
            let links = ['/login','/signup','/resetpassword', '/facebookaccess']
            links?.includes(window.location.pathname) ==true ?setProfileBtn(false):setProfileBtn(true)   
        },[])

        const updateLink = (link)=>{
            setCurrentLink(link)
        }

        const location = useLocation();
        const [active, setActive] = useState("inbox");

        useEffect(() => {
            switch (location.pathname) {
                case "/inbox":
                    setActive("inbox");
                    break;
                case "/contact":
                    setActive("contact");
                    break;
                case "/emptyContact":
                    setActive("emptyContact");
                    break;
                case "/group":
                    setActive("group");
                    break;
                case "/emptyGroup":
                    setActive("group");
                    break;
                case "/emptyBroadcast":
                    setActive("emptyBroadcast");
                    break;
                case "/broadcast":
                    setActive("broadcast");
                    break;
                case "/broadcastListing":
                    setActive("broadcastListing");
                    break;
                case "/templatelisting":
                    setActive("templatelisting");
                    break;
                case "/manageuser":
                    setActive("manageuser");
                    break;
                case "/integrations":
                    setActive("integrations");
                    break;
                case "/tags":
                    setActive("tags");
                    break;
                case "/emptytags":
                    setActive("emptytags");
                    break;
                default:
                    setActive("");
                    break;
            }
        }, [location]);

        const handleClick = (event) => {
            setActive(event.target.id);
        };

        const handleMenu = () => {
            setMenuShow(false);
        }

        const[profileshow, setProfileShow] = useState(false);
        const[notificationshow, setNotificationShow] = useState(false);
        const [menushow, setMenuShow] = useState(false);

        const options = [
            { value: 'chocolate', label: 'Chocolate' },
            { value: 'strawberry', label: 'Strawberry' },
            { value: 'vanilla', label: 'Vanilla' }
        ]

        const [isOpen, setIsOpen] = useState(false);
        const dropdownRef = useRef(null);

        const toggleDropdown = () => setIsOpen(prev => !prev);

        const closeDropdown = () => setIsOpen(false);

        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        // Handle link click: close dropdown and call another function
        const handleLinkClick = (callback) => (event) => {
            //event.preventDefault(); // Prevent default link behavior
            closeDropdown();
            callback();
        };

        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, []);


        return (
            <>
                <Navbar expand="lg" className = "Navbar-top">
                    <Container fluid>
                        <div className="position-relative d-flex align-items-center justify-content-between w-100 d-lg-none">
                            {window.location.pathname?.trim().length == 1 ? null : profileBtn ? 
                                <ul className = "nav align-items-center gap-2">
                                    {/* <li className = "nav-item">
                                        <div onClick = {()=>setProfileShow(true)} className = "profile-btn">
                                            <span></span> <span>Profile</span> <span className = "creat-dn"></span>
                                        </div>
                                    </li> */}
                                    <li className = "nav-item">
                                        {/* <Navbar.Toggle aria-controls='whatsapp-bot-nav'>
                                            <img src = {Menu_ic} alt = "menu_icon"/>
                                        </Navbar.Toggle> */}
                                        <Button className = "navbar-mob-open btn" onClick={()=>setMenuShow(true)}>
                                            <img src = {Menu_ic} alt = "menu_icon"/>
                                        </Button>
                                    </li>
                                    <li className = "nav-item">
                                        <Navbar.Brand href="/" className = "py-0 me-0">
                                            <img src = {Logo} alt = "logo-main"/>
                                        </Navbar.Brand>
                                    </li>
                                </ul>:null}
                                <Navbar.Brand href="/" className = "py-0 me-0 d-none d-lg-block">
                                    <img src = {Logo} alt = "logo-main"/>
                                </Navbar.Brand>
                            {window.location.pathname?.trim().length == 1 ? null : profileBtn ? 
                                <ul className = "nav align-items-center">
                                    <li className = "nav-item">
                                        <div onClick = {()=>setNotificationShow(true)} className = "notification-btn me-2">
                                            <i className ="bi bi-bell"></i> 
                                            <div className = "notification-badge">99</div>
                                        </div>
                                    </li>
                                    <li className = "nav-item">
                                        <div onClick = {()=>setProfileShow(true)} className = "profile-btn">
                                            <span></span> <span>Profile</span> <span className = "creat-dn"></span>
                                        </div>
                                    </li>
                                    {/* <li className = "nav-item">
                                        <Navbar.Toggle aria-controls='whatsapp-bot-nav'>
                                            <i className ="bi bi-list"></i>
                                        </Navbar.Toggle>
                                    </li> */}
                                </ul>:null}
                        </div>
                        <Navbar.Brand href="/" className = "d-none d-lg-block">
                            <img src = {Logo} alt = "logo-main"/>
                        </Navbar.Brand>
                        <Navbar.Collapse id = "whatsapp-bot-nav" className = "mt-4 mt-lg-0">
                        
                            <Nav className = {`${window.location.pathname.includes('/login') || window.location.pathname?.trim().length == 1 ? 'd-none':'mx-auto'} ${window.location.pathname.includes('signup') ? 'd-none':'mx-auto'} 
                                ${window.location.pathname.includes('resetpassword') ? 'd-none':'mx-auto'} ${window.location.pathname.includes('facebookaccess') ? 'd-none':'mx-auto'}`}>
                                <li className = "Nav-item">
                                    <Link id = "inbox" to = "/inbox" className = {active === "inbox" ? "active" : ""} onClick={handleClick}>
                                        <img src = {Inbox_icon} alt = "nav-icon"/> Inbox
                                    </Link>
                                </li>
                                <li className = "Nav-item">
                                    <Link id = "contact" to = "/emptyContact" className = {["emptyContact","contact"].includes(active) ? "active" : ""} onClick={handleClick}>
                                        <img src = {Contact_icon} alt = "nav-icon"/> Contacts
                                    </Link>
                                </li>
                                <li className = "Nav-item">
                                    <Link id = "group" to = "/emptyGroup" className = {["emptyGroup","group"].includes(active) ? "active" : ""} onClick={handleClick}>
                                        <img src = {Group_icon} alt = "nav-icon"/> Groups
                                    </Link>
                                </li>
                                <li className = "Nav-item">
                                    <Link id = "broadcast" to = "/emptyBroadcast" className = {["emptyBroadcast","broadcast","broadcastListing"].includes(active) ? "active" : ""} onClick={handleClick}>
                                        <img src = {BroadCast_icon} alt = "nav-icon"/> Broadcast
                                    </Link>
                                </li>
                                <li className = "Nav-item wd-links">
                                    <Link id = "manage" to = "#" className = {["manageuser","templatelisting","integrations", "emtpytags", "tags"].includes(active) ? "active" : ""}>
                                        <img src = {Manage_icon} alt = "nav-icon"/> Manage
                                    </Link>
                                        <ul className = "dropdown-links">
                                            <div className = "dropdown-links-body">
                                                <li className = "nav-item">
                                                    <Link id = "templatelisting" to = "/templatelisting" className = {active === "templatelisting" ? "active" : ""} onClick={handleClick}>
                                                        <img src = {Template_icon} alt = "nav-icon"/> Message Templates
                                                    </Link>
                                                    <Link id = "manage" to = "/manageuser" className = {active === "manageuser" ? "active" : ""} onClick={handleClick}>
                                                        <img src = {Group_icon} alt = "nav-icon"/> Users
                                                    </Link>
                                                    <Link id = "tags" to = "/emptytags" className = {active === "tags" ? "active" : ""} onClick={handleClick}>
                                                        <img src = {Tags_icon} alt = "nav-icon"/> Tags
                                                    </Link>
                                                    <Link id = "integrations" to = "/integrations" className = {active === "integrations" ? "active" : ""} onClick={handleClick}>
                                                        <img src = {Manage_icon} alt = "nav-icon"/> Integration
                                                    </Link>
                                                </li>
                                            </div>
                                        </ul>
                                </li>
                            </Nav>
                        </Navbar.Collapse>
                        { window.location.pathname?.trim().length == 1 ? null : profileBtn ? <div onClick = {()=>setNotificationShow(true)} className = "notification-btn desktop-btn me-2">
                            <i className ="bi bi-bell"></i> 
                            <div className = "notification-badge">99</div>
                        </div>:null}
                        { window.location.pathname?.trim().length == 1 ? null : profileBtn ? <div onClick = {()=>setProfileShow(true)} className = "profile-btn desktop-btn">
                            <span className = "p-img"></span> <span>Profile</span> <span className = "creat-dn"></span>
                        </div>:null}
                    </Container>
                </Navbar>

                {/* <div className = "mobile-navbar">
                    <div className = "mobile-nav inner">
                        <ul className = "nav flex-column">
                            <li className = "nav-item">
                                <Link to = "/" className = "d-flex align-items-center gap-3">
                                    <img src = {Inbox_icon} alt = "nav-icon"/> Dashboard
                                </Link>
                            </li>
                            <li className = "nav-item">
                                <Link to = "/" className = "d-flex align-items-center gap-3">
                                    <img src = {Inbox_icon} alt = "nav-icon"/> inbox
                                </Link>
                            </li>
                            <li className = "nav-item">
                                <Link to = "/emptycontact" className = "d-flex align-items-center gap-3">
                                    <img src = {Contact_icon} alt = "nav-icon"/> Contact
                                </Link>
                            </li>
                            <li className = "nav-item">
                                <Link to = "/emptyGroup" className = "d-flex align-items-center gap-3">
                                    <img src = {Group_icon} alt = "nav-icon"/> Groups
                                </Link>
                            </li>
                            <li className = "nav-item">
                                <Link to = "/emptyBroadcast" className = "d-flex align-items-center gap-3">
                                    <img src = {BroadCast_icon} alt = "nav-icon"/> Broadcast
                                </Link>
                            </li>
                            <li className = "nav-item">
                                <Link to = "/" className = "d-flex align-items-center gap-3">
                                    <img src = {Manage_icon} alt = "nav-icon"/> Manage
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div> */}

                <Offcanvas show = {menushow} onHide = {()=>setMenuShow(false)} backdrop="static" placement="start" className = "mobile-navbar">
                    <Offcanvas.Header closeButton></Offcanvas.Header>
                    <Offcanvas.Body className = "mobile-nav inner">
                            <ul className = "nav flex-column">
                                <li className = "nav-item">
                                    <Link to = "/" className = "d-flex align-items-center gap-3" onClick={handleMenu}>
                                        <img src = {Dashboad_icon} alt = "nav-icon"/> Dashboard
                                    </Link>
                                </li>
                                <li className = "nav-item">
                                    <Link to = "/inbox" className = "d-flex align-items-center gap-3" onClick={handleMenu}>
                                        <img src = {Inbox_icon} alt = "nav-icon"/> inbox
                                    </Link>
                                </li>
                                <li className = "nav-item">
                                    <Link to = "/emptycontact" className = "d-flex align-items-center gap-3" onClick={handleMenu}>
                                        <img src = {Contact_icon} alt = "nav-icon"/> Contact
                                    </Link>
                                </li>
                                <li className = "nav-item">
                                    <Link to = "/emptyGroup" className = "d-flex align-items-center gap-3" onClick={handleMenu}>
                                        <img src = {Group_icon} alt = "nav-icon"/> Groups
                                    </Link>
                                </li>
                                <li className = "nav-item">
                                    <Link to = "/emptyBroadcast" className = "d-flex align-items-center gap-3" onClick={handleMenu}>
                                        <img src = {BroadCast_icon} alt = "nav-icon"/> Broadcast
                                    </Link>
                                </li>
                                <li className = "nav-item">
                                    <div className = "dropdown-cotainer py-3" ref={dropdownRef}>
                                        <Button variant='dropdown' className = "d-flex align-items-center gap-3" onClick={toggleDropdown}>
                                            <img src = {Manage_icon} alt = "nav-icon"/> Manage
                                        </Button>
                                        
                                            <div className={`dropdown-content ${isOpen ? 'show' : ''}`}>
                                                <ul className = "flex-column nav">
                                                    <li className = "nav-item">
                                                        <Link to = "/templatelisting" className = "d-flex align-items-center gap-3" onClick={handleLinkClick(handleMenu)}>
                                                            <img src = {Manage_icon} alt = "nav-icon"/> Message Template
                                                        </Link>
                                                    </li>
                                                    <li className = "nav-item">
                                                        <Link to = "/emptyuser" className = "d-flex align-items-center gap-3" onClick={handleLinkClick(handleMenu)}>
                                                            <img src = {Manage_icon} alt = "nav-icon"/> Users
                                                        </Link>
                                                    </li>
                                                    <li className = "nav-item">
                                                        <Link to = "/emptytags" className = "d-flex align-items-center gap-3" onClick={handleLinkClick(handleMenu)}>
                                                            <img src = {Manage_icon} alt = "nav-icon"/> Tags
                                                        </Link>
                                                    </li>
                                                    <li className = "nav-item">
                                                        <Link to = "/integrations" className = "d-flex align-items-center gap-3" onClick={handleLinkClick(handleMenu)}>
                                                            <img src = {Manage_icon} alt = "nav-icon"/> Integration
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        
                                    </div>
                                </li>
                            </ul>
                    </Offcanvas.Body>
                </Offcanvas>

                <Offcanvas show={profileshow} onHide={()=>setProfileShow(false)} backdrop="static" placement="end" className = "profile-offcanvas">
                    <Offcanvas.Header closeButton>
                        <div className = "profile-info">
                            <ul className = "nav">
                                <li className = "nav-item">
                                    
                                    <div className = "profile-member">
                                        {/* <img src = {} alt={}/> */}
                                    </div>
                                    
                                </li>
                                <li className = "nav-item">
                                    <div className = "profile-member-details">
                                        <h1>Hajira Ihsan</h1>
                                        <p>hajira.ihsan@mwanmobile.org</p>
                                        <Link to = "/editprofile" className = "btn btn-main-default" onClick = {()=>setProfileShow(false)}>Edit Profile</Link>
                                        {/* <Button className = "btn btn-main-default" onClick = {()=>{setProfileShow(false) 
                                            setEditprofileShow(true)}}>
                                            Edit Profile
                                        </Button> */}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className = "subcription-card">
                            <h2>Subscription Plan</h2>
                            <h3>Expires on <span>09 Feb 2023</span></h3>
                            <Row>
                                <Col sm = {12} className = "text-end">
                                    <Link to = "/subscription" className = "btn upgrade-btn" onClick = {()=>{setProfileShow(false) 
                                        updateLink('/subscription')}}>
                                        Upgrate Now
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                        <Row className = "py-3">
                            <Col sm = {12}>
                                {/* <Form.Group>
                                    <Form.Label>
                                        Language
                                    </Form.Label>
                                    <Form.Select aria-label="English">
                                        <option value="1">English</option>
                                        <option value="2">Urdu</option>
                                        <option value="3">Punjabi</option>
                                    </Form.Select>
                                </Form.Group> */}
                                <Form.Group>
                                    <Form.Label>Language</Form.Label>
                                    <Select className = "c-select" options={options} placeholder = {'Select Language'}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className = "py-3">
                            <Col sm = {6} className = "mb-3 mb-sm-0">
                                <Link to = "/faq" className = "btn btn-main-default w-100" onClick = {()=>{setProfileShow(false)
                                updateLink('/faq')}}>
                                    <i className ="bi bi-question-circle"></i> Help Center
                                </Link>
                            </Col>
                            <Col sm = {6}>
                                <Link to = "/" className = "btn btn-main-default w-100" onClick = {()=>setProfileShow(false)}>
                                    <i className ="bi bi-envelope"></i> Email Us
                                </Link>
                            </Col>
                        </Row>
                        <div className = "logout-btn">
                            <Link to = "/" className = "btn btn-main-default w-100">
                                Sign Out
                            </Link>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>

                <Offcanvas show={notificationshow} onHide={()=>setNotificationShow(false)} backdrop="static" placement="end" className = "notification-offcanvas">
                    <Offcanvas.Header closeButton>
                        <div className = "header-title">
                            All notification
                        </div>
                        <btn className = "allread-btn">
                            Mark all as read
                        </btn>
                    </Offcanvas.Header>
                    <Offcanvas.Body className = "p-0">
                        <div className = "notification-listing">
                            <div className = "notification-row new">
                                <div className = "notification-img">
                                    <div className = "notification-icon notification-user">
                                        RM
                                    </div>
                                </div>
                                <div className = "notification-content">
                                    <span>Rimsha</span> assigned you a conversation of <span>Sales Queries</span>
                                </div>
                                <div className = "time">2m</div>
                            </div>
                            <div className = "notification-row">
                                <div className = "notification-img">
                                    <div className = "notification-icon">
                                    </div>
                                </div>
                                <div className = "notification-content">
                                    <span>Andreas</span> added notes on <span>Sales Queries</span>
                                </div>
                                <div className = "time">2h</div>
                            </div>
                            <div className = "notification-row">
                                <div className = "notification-img">
                                    <div className = "notification-icon notification-user">
                                        MK
                                    </div>
                                </div>
                                <div className = "notification-content">
                                    <span>Muzammil Ahmed Khan</span> added notes on <span>Sales Queries</span>
                                </div>
                                <div className = "time">Yesterday</div>
                            </div>
                            <div className = "notification-row">
                                <div className = "notification-img">
                                    <div className = "notification-icon">
                                    </div>
                                </div>
                                <div className = "notification-content">
                                    <span>Andreas</span> added notes on <span>Sales Queries</span>
                                </div>
                                <div className = "time">3 days</div>
                            </div>
                            <div className = "notification-row">
                                <div className = "notification-img">
                                    <div className = "notification-icon">
                                    </div>
                                </div>
                                <div className = "notification-content">
                                    <span>Andreas</span> added notes on <span>Sales Queries</span>
                                </div>
                                <div className = "time">7 days</div>
                            </div>
                        </div>
                        {/* Add empty className with top offcanvas to show this div */}
                        <div className = "notification-empty">
                                <div className = "empty-icon-bell">
                                    <img src = {Empty_notification} alt = "notification-bell"/>
                                </div>
                                <div className = "empty-content">
                                    <div className = "empty-title">No notifications yet</div>
                                    <div className = "empty-description">
                                        We'll let you know when updates arrive!
                                    </div>
                                </div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );
    };

export default Header;