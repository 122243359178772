

const UsertableStatus = ({statusClass, statusTitle}) => {

    const statusStyle = {
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: statusTitle === 'Disactivated' ? '#E94B42' : '#2BD02E',
    }

    return (
        <>
            <ul className = "nav align-items-center">
                <li className = "nav-item pe-2">
                    <div style = {statusStyle} className = {`status-circle ${statusClass}`}></div>
                </li>
                <li className = "nav-item">
                    <div className = "status-title">
                        {statusTitle}
                    </div>
                </li>
            </ul>
        </>
    );
};

export default UsertableStatus;