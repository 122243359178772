

const UserBasicInfo = () => {
    return (
        <>
            <div className = "user-info-data">
                <div className = "user-personal-info">
                    <div className = "info-row">
                        <div className = "title">Basic Information</div>
                        <div className = "description">Phone number: 03025676625</div>
                    </div>
                    <div className = "info-row">
                        <div className = "title">Conversation info:</div>
                        <div className = "description">Active</div>
                    </div>
                    <div className = "info-row">
                        <div className = "title">Remaining Time:</div>
                        <div className = "description">
                        <i className ="bi bi-alarm me-2"></i> 
                            22:05:04
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserBasicInfo;