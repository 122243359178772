

import Table from "react-bootstrap/Table";

import TemplateAction from "./template-action";
import TemplateDetails from "./template-details";
import TemplateStatus from "./template-status";

import './css/template-listing-table.css';

const Listingtable = ({Templates, onChildClick}) => {
    // console.log("listing child", onChildClick)

    return (
        <>
            <div className = "table-responsive template-recordtable">
                <Table hover className = "listing-table mb-0">
                    <thead>
                        <tr>
                        <th>Template Name</th>
                        <th>Status</th>
                        <th>Language</th>
                        <th>Category</th>
                        <th>Updated Date</th>
                        <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                            {Templates.map((template, index) => (
                                <tr key={index}>
                                    <td>
                                        <TemplateDetails Eventname={template.eventName} EventCreatedBy={template.createdname}/>
                                    </td>
                                    <td><TemplateStatus statustext={template.statustitle} statusClass={template.statusclass}/></td>
                                    <td>{template.language}</td>
                                    <td>{template.category}</td>
                                    <td>{template.UpdatedDagte}</td>
                                    <td>
                                        <TemplateAction onChildC={onChildClick}/>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </div>
        </>
    );
};

export default Listingtable;