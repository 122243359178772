
import React from 'react';
import Wtable from '../table/table';
import "../table/table.css";

import BroadcastTablestatus from './broadcast-status';
import Broadcastname from './broadcast-name';
import BroadcastType from './broadcast-type';
import DeliveryStatus from './delivery-status';
import BroadcastAction from './broadcast-action';

const BroadcastDetailTable = () => {

    const theadData = ["Name", "Phone Number",  "Status", "Delivered At" ];

    const tbodyData = [
        {
            id: "1",
            items: ["Asad Mehmood", "+92314567765", <BroadcastTablestatus statusClass={'status-title'} statusTitle={'Delivered'}/>, "Apr-18-2023 07:00 PM"],
        },
        {
            id: "2",
            items: ["Muzamil Ahmed Khan", "+92314567765", <BroadcastTablestatus statusClass={'status-title'} statusTitle={'Failed'}/>, "Apr-18-2023 07:00 PM"],
        },
        {
            id: "3",
            items: ["Naveed Khan", "+92314567765", <BroadcastTablestatus statusClass={'status-title'} statusTitle={'Delivered'}/>, "Apr-18-2023 07:00 PM"],
        },
        {
            id: "4",
            items: ["Hamza Maqsood", "+92314567765", <BroadcastTablestatus statusClass={'status-title'} statusTitle={'Read'}/>, "Apr-18-2023 07:00 PM"],
        },
        {
            id: "5",
            items: ["Rimsha Malaik", "+92314567765", <BroadcastTablestatus statusClass={'status-title'} statusTitle={'Read'}/>, "Apr-18-2023 07:00 PM"],
        },
        {
            id: "6",
            items: ["Sammad Farooq", "+92314567765", <BroadcastTablestatus statusClass={'status-title'} statusTitle={'Read'}/>, "Apr-18-2023 07:00 PM"],
        },
        {
            id: "7",
            items: ["Najeeb", "+92314567765", <BroadcastTablestatus statusClass={'status-title'} statusTitle={'Delivered'}/>, "Apr-18-2023 07:00 PM"],
        },
    ];

    return (
    <>
        <div className = "cg-custom-table">
            <Wtable theadData={theadData} tbodyData={tbodyData}/>
        </div>
    </>
    )
}

export default BroadcastDetailTable;