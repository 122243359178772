
import Scheduled_ic from '../../images/broadcast/scheduled-ic.svg';
import Instant_ic from '../../images/broadcast/instant-ic.svg';

import './broadcast.css';

const BroadcastType = ({Typename, TypeDateTime, className}) => {
    return (
        <>
            <div className = {`broadcast-type ${className}`}>
                <div className = "type-name">
                    <img className = "scheduled-img" src = {Scheduled_ic} alt = "schedule-icon"/>
                    <img className = "instant-img" src = {Instant_ic} alt = "instant-icon"/>
                    {Typename}
                </div>
                <div className = "type-date_time">
                    {TypeDateTime}
                </div>
            </div>
        </>
    )
}

export default BroadcastType;