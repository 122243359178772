
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";

import Username from "../userName/username";
// import UsertableStatus from "./user-status";
// import Useraction from "./useraction";
import Actionbutton from "../tableAction/tableactionBtn";

import '../manageUser/css/usermob.css';

const ContactMobile = () => {

    return (
        <>
        <Container>
            <Row>
                <Col xs = {12}>
                    <div className = "template-listing-collapse">
                        <div className = "tlc-header">
                            Name
                        </div>
                        <div className = "tlc-body">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey = "0">
                                    <Accordion.Header>
                                        <Username name = {'Assad Mehmood'} Useremail={'asad.mehmood@mwanmobile.com'}/>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Phone Number</div>
                                            <div className = "phone_number">
                                                +97122495022
                                            </div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Created At</div>
                                            <div className = "cb-date">Apr-18-2023</div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-end">
                                            <Actionbutton/>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey = "1">
                                    <Accordion.Header>
                                        <Username name = {'Abigail Smith'} Useremail={'abigail.smith@example.com'}/>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Phone Number</div>
                                            <div className = "Role">
                                                +97122495022
                                            </div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Created At</div>
                                            <div className = "cb-date">Apr-18-2023</div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-end">
                                            <Actionbutton/>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey = "2">
                                    <Accordion.Header>
                                    <   Username name = {'Daniel Williams'} Useremail={'daniel.williams@example.com'}/>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Phone Number</div>
                                            <div className = "Role">
                                                +97122495022
                                            </div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Created At</div>
                                            <div className = "cb-date">Apr-18-2023</div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-end">
                                            <Actionbutton/>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey = "3">
                                    <Accordion.Header>
                                        <Username name = {'Edward Martinez'} Useremail={'edward.martinez@example.com'}/>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Phone Number</div>
                                            <div className = "Role">
                                                +97122495022
                                            </div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Created At</div>
                                            <div className = "cb-date">Apr-18-2023</div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-end">
                                            <Actionbutton/>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        </>
    );
};

export default ContactMobile;