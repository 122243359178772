
import Button from "react-bootstrap/Button";
import Dropdown from 'react-bootstrap/Dropdown';

const UserNotes = () => {
    return (
        <>
            <div className = "note-body">
                <div className = "note-header pb-2 mb-3 mb-md-0 text-center text-md-end">
                    <Button variant = "note">
                        <i className ="bi bi-plus-circle-fill me-2 d-md-none"></i> Write a Note
                    </Button>
                </div>
                <div className = "note-body-inner">
                    <div className = "note-panel">
                        <div className = "note-user">
                            Muzamil Ahmed Khan
                        </div>
                        <div className = "note-date">
                            01/11/2023  09:08 AM
                        </div>
                        <div className = "note-content">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                        </div>
                        <div className = "note-option">
                            <Dropdown>
                                <Dropdown.Toggle className = "chat-o-btn">
                                    <i className ="bi bi-three-dots-vertical"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className = "option-dropdown">
                                    <Dropdown.Item>
                                       Edit Notes
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        Delete Notes
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className = "note-panel">
                        <div className = "note-user">
                            Hajira Ihsan
                        </div>
                        <div className = "note-date">
                            01/11/2023  09:08 AM
                        </div>
                        <div className = "note-content">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                        </div>
                    </div>
                    <div className = "note-panel">
                        <div className = "note-user">
                            Ahmed Ali
                        </div>
                        <div className = "note-date">
                            01/11/2023  09:08 AM
                        </div>
                        <div className = "note-content">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                        </div>
                    </div>
                    <div className = "note-panel">
                        <div className = "note-user">
                            Asad Mehmood
                        </div>
                        <div className = "note-date">
                            01/11/2023  09:08 AM
                        </div>
                        <div className = "note-content">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserNotes;