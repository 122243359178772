import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import { Link } from "react-router-dom";

import Broadcast_ic from "../../images/broadcast/broadCast-icon.svg";

import '../template-listing/css/templateListing-header.css';

const BroadcastHeader = ({Contact_title, Contact_search}) => {
    
    return (
        
        <>
             <section className = "template-header py-3">
                <Container>
                    <Row className = "align-items-center">
                        <Col xs = {6} lg = {4}>
                            <Row className = "align-items-center">
                                <Col md = {12}>
                                    <div className = "template-heading">
                                        <img width = "33" className = "me-2" src = {Broadcast_ic} alt = "icon"/> 
                                        {Contact_title}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs = {6} lg = {8}>
                            <Row className = "align-items-center justify-content-end">
                                <Col md = {12} lg = {8} xl = {9} className = "d-none d-lg-block group-search">
                                    {Contact_search}
                                </Col>
                                <Col md = {12} lg = {4} xl = {3} className = "text-end">
                                    <Link to = "/newBroadcast" className = "btn btn-main text-decoration-none btn-main-mob w-100">
                                        <i className ="bi bi-plus"></i> <span>New Broadcast</span>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className = "template-header-mob d-lg-none pt-3">
                <Container>
                    <Row>
                        <Col xs = {12} className = "mb-3">
                            <Form.Group className = "form-group">
                                <Form.Control placeholder = "Search by name or number"/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    );
};

export default BroadcastHeader;