
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "../manageUser/css/usermob.css";
import TagAction from "./tag-action";

// import React, { useState } from "react";


const getRandomLightColor  = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    const a = 0.2; // Semi-transparent
    return `rgba(${r}, ${g}, ${b}, ${a})`;
}

// Function to generate an array of unique light RGBA colors
const generateUniqueColors = (count) => {
    const colors = new Set();
    while (colors.size < count) {
        colors.add(getRandomLightColor());
    }
    return Array.from(colors);
}

const TagsMob = () => {

    // Define the number of unique colors you need
    const numberOfColors = 10; // Update based on the number of unique spans you have
    // Generate unique colors
    const badgeColors = generateUniqueColors(numberOfColors);

    const TagmobStyle = {
        padding: '14px 16px 20px 16px',
        borderBottom: '1px solid rgba(217, 217, 217, 0.5)',
        color: 'rgba(75, 74, 74, 1)',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '18px',
    }

    return (
        <>
        <Container>
            <Row>
                <Col xs = {12}>
                    <div className = "template-listing-collapse">
                        <div className = "tlc-header">
                            Tag Name
                        </div>
                        <div className = "tlc-body">

                            <div className = "tag-mob" style = {TagmobStyle}>
                                <Row className="align-items-center">
                                    <Col sm = {6} xs = {7}>
                                        <div className = "tagname mb-3">
                                            <span style={{ backgroundColor: badgeColors[0], color: '#000', fontWeight: '600', lineHeight: '16px', fontSize: '12px', borderRadius: '5px', padding: '5px 6px', display: 'inline-block', margin: '3px 0px', whiteSpace: 'nowrap' }}>
                                                Sale
                                            </span>
                                        </div>
                                        <div className = "created-at">
                                            Created At Apr-18-2023
                                        </div>
                                    </Col>
                                    <Col sm = {6} xs = {5}>
                                        <TagAction/>
                                    </Col>
                                </Row>
                            </div>

                            <div className = "tag-mob" style = {TagmobStyle}>
                                <Row className="align-items-center">
                                    <Col sm = {6} xs = {7}>
                                        <div className = "tagname mb-3">
                                            <span style={{ backgroundColor: badgeColors[1], color: '#000', fontWeight: '600', lineHeight: '16px', fontSize: '12px', borderRadius: '5px', padding: '5px 6px', display: 'inline-block', margin: '3px 0px', whiteSpace: 'nowrap' }}>
                                                Technical Support
                                            </span>
                                        </div>
                                        <div className = "created-at">
                                            Created At Apr-18-2023
                                        </div>
                                    </Col>
                                    <Col sm = {6} xs = {5}>
                                        <TagAction/>
                                    </Col>
                                </Row>
                            </div>

                            <div className = "tag-mob" style = {TagmobStyle}>
                                <Row className="align-items-center">
                                    <Col sm = {6} xs = {7}>
                                        <div className = "tagname mb-3">
                                            <span style={{ backgroundColor: badgeColors[2], color: '#000', fontWeight: '600', lineHeight: '16px', fontSize: '12px', borderRadius: '5px', padding: '5px 6px', display: 'inline-block', margin: '3px 0px', whiteSpace: 'nowrap' }}>
                                                Event Marketing
                                            </span>
                                        </div>
                                        <div className = "created-at">
                                            Created At Apr-18-2023
                                        </div>
                                    </Col>
                                    <Col sm = {6} xs = {5}>
                                        <TagAction/>
                                    </Col>
                                </Row>
                            </div>

                            <div className = "tag-mob" style = {TagmobStyle}>
                                <Row className="align-items-center">
                                    <Col sm = {6} xs = {7}>
                                        <div className = "tagname mb-3">
                                            <span style={{ backgroundColor: badgeColors[3], color: '#000', fontWeight: '600', lineHeight: '16px', fontSize: '12px', borderRadius: '5px', padding: '5px 6px', display: 'inline-block', margin: '3px 0px', whiteSpace: 'nowrap' }}>
                                                General Queries
                                            </span>
                                        </div>
                                        <div className = "created-at">
                                            Created At Apr-18-2023
                                        </div>
                                    </Col>
                                    <Col sm = {6} xs = {5}>
                                        <TagAction/>
                                    </Col>
                                </Row>
                            </div>

                            <div className = "tag-mob" style = {TagmobStyle}>
                                <Row className="align-items-center">
                                    <Col sm = {6} xs = {7}>
                                        <div className = "tagname mb-3">
                                            <span style={{ backgroundColor: badgeColors[4], color: '#000', fontWeight: '600', lineHeight: '16px', fontSize: '12px', borderRadius: '5px', padding: '5px 6px', display: 'inline-block', margin: '3px 0px', whiteSpace: 'nowrap' }}>
                                                Logistic
                                            </span>
                                        </div>
                                        <div className = "created-at">
                                            Created At Apr-18-2023
                                        </div>
                                    </Col>
                                    <Col sm = {6} xs = {5}>
                                        <TagAction/>
                                    </Col>
                                </Row>
                            </div>

                            <div className = "tag-mob" style = {TagmobStyle}>
                                <Row className="align-items-center">
                                    <Col sm = {6} xs = {7}>
                                        <div className = "tagname mb-3">
                                            <span style={{ backgroundColor: badgeColors[5], color: '#000', fontWeight: '600', lineHeight: '16px', fontSize: '12px', borderRadius: '5px', padding: '5px 6px', display: 'inline-block', margin: '3px 0px', whiteSpace: 'nowrap' }}>
                                                Technical Support
                                            </span>
                                        </div>
                                        <div className = "created-at">
                                            Created At Apr-18-2023
                                        </div>
                                    </Col>
                                    <Col sm = {6} xs = {5}>
                                        <TagAction/>
                                    </Col>
                                </Row>
                            </div>

                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        </>
    );
};

export default TagsMob;