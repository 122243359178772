
import React from 'react';
import Wtable from '../table/table';
import Groupaction from '../tableAction/groupaction';

const Grouptable = () => {

    
    const theadData = ["#", "Group Name", "Created at", "Created By", "Members", "Action"];

    const tbodyData = [
        {
            id: "1",
            items: ["01", "Mwan Mobile", "Jan-18-2023", "Asad Mehmood", "35", <Groupaction/>],
        },
        {
            id: "2",
            items: ["02", "Mwan Events", "Jan-19-2023", "Asad Mehmood", "50", <Groupaction/>],
        },
        {
            id: "3",
            items: ["03", "New Group", "Jan-20-2023", "Asad Mehmood", "80", <Groupaction/>],
        },
        {
            id: "4",
            items: ["04", "Verify The Task", "Jan-22-2023", "Asad Mehmood", "100", <Groupaction/>],
        },
        {
            id: "5",
            items: ["05", "Event Details", "Jan-23-2023", "Asad Mehmood", "500", <Groupaction/>],
        },
        {
            id: "6",
            items: ["06", "Announcement", "Jan-25-2023", "Asad Mehmood", "800", <Groupaction/>],
        },
    ];

    return (
        <div className = "cg-custom-table">
            <Wtable theadData={theadData} tbodyData={tbodyData}/>
        </div>
    )
}

export default Grouptable;