import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Link } from "react-router-dom";

import TagsHeader from "../components/tags/tags-header";
import Search from "../components/search/search";

import Tag_icons from "../images/tags/tag_icons.svg";

const EmptyTags = () => {
    return (
        <>
            <TagsHeader Tag_title={'Tag'} Tag_search={<Search/>}/>

            <section className = "main inner-main templates-body-empty">
                    <Container>
                        <Row className = "align-items-center">
                            <Col xs = {12}>
                                <div className = "tb-empty-content text-center groups">
                                    <img class = "groups-icons" src = {Tag_icons} alt = "icon"/>
                                    <div className = "tb-page-title">
                                        No Tags to display.
                                    </div>
                                    <p>
                                        You can create a new Tag by clicking the button below
                                    </p>
                                    <Link to = "/tags" className = "btn btn-main">
                                        <i className ="bi bi-plus"></i> Create New
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
             </section>
        </>
    )
}

export default EmptyTags;