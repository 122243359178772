
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useState, useEffect } from "react";

import Forminput from "../components/forms/input";

const Editprofile = () => {

    const [selectedImage, setSelectedImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [isShown, setIsShown] = useState(false);
    const [isHide, setIsHide] = useState(true);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        if (selectedImage) {
          setImageUrl(URL.createObjectURL(selectedImage));
        }
      }, [selectedImage]);

      const handleClick = () => {
        // Show loader and hide text
        setIsLoading(true);
        setIsShown(false);
        setIsHide(true);
    
        // Simulate a delay of 3-4 seconds
        setTimeout(() => {
          // Hide loader and show text again
          setIsLoading(false);
    
          // Optional: Short delay before showing text again
          setTimeout(() => {
            setIsShown(true);
            setIsHide(true);
          }, 300); // Short delay before reverting to original text
        }, 3000); // Simulate 3 seconds delay
    };
    
       

    return (
        <section className = "main edit-profile">
            <Container>
                <Row>
                    <Col sm = "12">
                        <div className ="form-container">
                            <div className = "form-header">
                                <ul className = "nav align-items-center">
                                    <li className = "nav-item">
                                        <div className = "profile-img">
                                            {imageUrl && selectedImage && (
                                                <img src = {imageUrl} alt={selectedImage.name}/>
                                            )}
                                        </div>
                                    </li>
                                    
                                    {isHide && (
                                        <li className = "nav-item">
                                            <div className = "profile-img-upload">
                                                <label>Upload Photo</label>
                                                <input accept="image/*" type = "file" id="select-image" onChange={(e) => setSelectedImage(e.target.files[0])}/>
                                            </div>
                                        </li>
                                    )}
                                    
                                    
                                    {isShown && (
                                        <li className = "nav-item">
                                            <div className = "action-list">
                                                <div className = "action-btn" onClick = {()=>{setIsShown(false) 
                                                setIsHide(true)}}>
                                                    <i className="bi bi-pencil"></i>
                                                </div>
                                                <div className = "action-btn" onClick={() => {setSelectedImage(false) 
                                                setIsHide(true) 
                                                setIsShown(false)}}>
                                                    <i className ="bi bi-trash3"></i>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                    
                                </ul>
                            </div>
                            <div className = "form-body">
                                <div className = "form-title">
                                    <h1>
                                        Basic information
                                    </h1>
                                </div>
                                <Row className = "mb-3 mb-lg-4">
                                    <Col md = {6}>
                                        <Forminput label = {'Company Name'} type = {'text'} value = {'Mwan Mobile'} />
                                    </Col>
                                    <Col md = {6}>
                                        <Forminput label = {'Full Name'} type = {'text'} value = {'Full Name'} />
                                    </Col>
                                    <Col md = {6}>
                                        <Forminput label = {'Phone No'} type = {'text'} value = {'923025676625'} />
                                    </Col>
                                    <Col md = {6}>
                                        <Forminput label = {'Email'} type = {'email'} value = {'hajra.ihsan@gmail.com'}/>
                                    </Col>
                                </Row>
                                <div className = "form-title">
                                    <h1>
                                        Change Password
                                    </h1>
                                    <p>
                                        Leave password fields empty if you are not updating password
                                    </p>
                                </div>
                                <Row>
                                    <Col md = {6}>
                                        <Forminput label = {'Old Password *'} type = {'password'} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md = {6}>
                                        <Forminput label = {'New Password *'} type = {'password'} />
                                    </Col>
                                    <Col md = {6}>
                                        <Forminput label = {'Confirm Password *'} type = {'password'}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm = {12}>
                                        <Button variant = "main" className = "w-100" onClick={handleClick}
                                        disabled={isLoading}>
                                                {isLoading ? (
                                                    <Spinner animation="border" size="sm" />
                                                    ) : (
                                                    'Update'
                                                )}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Editprofile;