import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
// import React, { useState } from 'react';

import "../components/Group-page/groups.css";
import Broadcast_ic from "../images/broadcast/broadCast-icon.svg";

import BroadcastHeader from "../components/broadcast/broadcast-header";
import Search from "../components/search/search";

import { Link } from "react-router-dom";

import Select from 'react-select';


const EmptyBroadcast = () => {


    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
        { value: 'apple', label: 'Apple' },
        { value: 'mango', label: 'Mango' },
        { value: 'grapes', label: 'Grapes' }
    ]

    const status = [
        { value: 'All', label: 'All' },
        { value: 'approved', label: 'Approved' },
        { value: 'pending', label: 'Pending' },
        { value: 'rejected', label: 'Rejected' }
    ]


    return(
        <>

            <BroadcastHeader Contact_title={'Broadcast'} Contact_search={<Search/>}/>

            <section className = "broadcast-top pt-lg-3 pb-3 mt-lg-3">
                <div className = "container">
                    <Row>
                        <Col lg = {5} xl = {4} className = "order-last order-md-first">
                            <Row>
                                <Col xs = {6} className = "px-lg-1">
                                    <Button variant = "main w-100">
                                        Broadcast list
                                    </Button>
                                </Col>
                                <Col xs = {6} className = "px-lg-1">
                                    <Button variant = "main w-100">
                                        Scheduled
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg = {7} xl = {8} className = "order-first order-md-last mb-3 mb-md-0">
                            <ul className = "nav align-items-center gap-2 justify-content-lg-end mt-0 mt-md-3 mt-lg-0">
                                <li class = "nav-item d-none d-md-block">
                                    <Form.Group>
                                       <Form.Control type = "date" />
                                    </Form.Group>
                                </li>
                                <li class = "nav-item">
                                    <Form.Group>
                                        <Select className = "c-select" options={status} placeholder = {'Status'} closeMenuOnSelect={false} classNamePrefix="select"/>
                                    </Form.Group>
                                </li>

                                <li class = "nav-item d-none d-md-block">
                                    <Form.Group>
                                        <Select className = "c-select" options={options} placeholder = {'All Broadcast'} 
                                        classNamePrefix="select"/>
                                    </Form.Group>
                                </li>

                            </ul>
                        </Col>
                    </Row>
                </div>
            </section>

            <section className = "main inner-main templates-body-empty">
                    <Container className = "mt-0">
                        <Row className = "align-items-center">
                            <Col xs = {12}>
                                <div className = "tb-empty-content text-center groups">
                                    <img class = "groups-icons" src = {Broadcast_ic} alt = "icon"/>
                                    <div className = "tb-page-title">
                                        Currently you don’t have no broadcasts
                                    </div>
                                    <p>
                                        You can create a new broadcast by clicking the button below
                                    </p>
                                    <Link to = "/broadcastListing" className = "btn btn-main">
                                        <i className ="bi bi-plus"></i> New Broadcast
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
             </section>
        </>
    );
};

export default EmptyBroadcast;