
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";

import Username from "../userName/username";
import UsertableStatus from "./user-status";
import Useraction from "./useraction";

import './css/usermob.css';

const ManageUsermob = () => {

    const badgeStyelmob = {
        backgroundColor: '#FBE2FF',
        color: '#000',
        fontWeight: '600',
        lineHeight: '16px',
        fontSize: '12px',
        borderRadius: '5px',
        padding: '5px 6px',
        margin: '2px',
    }


    return (
        <>
        <Container>
            <Row>
                <Col xs = {12}>
                    <div className = "template-listing-collapse">
                        <div className = "tlc-header">
                            Name
                        </div>
                        <div className = "tlc-body">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey = "0">
                                    <Accordion.Header>
                                        <Username name = {'Assad Mehmood'} Useremail={'asad.mehmood@mwanmobile.com'}/>
                                    </Accordion.Header>
                                    <Accordion.Body className = "pt-0">
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Tags</div>
                                            <div style = {{width: "100%", maxWidth: "220px"}} className = "d-flex align-items-center justify-content-end flex-wrap">  
                                                <span style = {badgeStyelmob}>Marketing</span> 
                                                <span style = {{...badgeStyelmob, backgroundColor:'#FFDBD9'}}>Sale</span>
                                            </div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Role</div>
                                            <div className = "Role">
                                                User
                                            </div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Status</div>
                                            <div className = "status-info">
                                                <UsertableStatus statusTitle={'Active'} statusClass={'active'}/>
                                            </div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Created At</div>
                                            <div className = "cb-date">Apr-18-2023</div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-end">
                                            <Useraction/>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey = "1">
                                    <Accordion.Header>
                                        <Username name = {'Abigail Smith'} Useremail={'abigail.smith@example.com'}/>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Tags</div>
                                            <div>
                                                <span style = {badgeStyelmob}>Marketing</span>
                                            </div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Role</div>
                                            <div className = "Role">
                                                User
                                            </div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Status</div>
                                            <div className = "status-info">
                                                <UsertableStatus statusTitle={'Active'} statusClass={'active'}/>
                                            </div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Created At</div>
                                            <div className = "cb-date">Apr-18-2023</div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-end">
                                            <Useraction/>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey = "2">
                                    <Accordion.Header>
                                    <   Username name = {'Daniel Williams'} Useremail={'daniel.williams@example.com'}/>
                                    </Accordion.Header>
                                    <Accordion.Body className = "pt-0">
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Tags</div>
                                            <div>
                                                <span style = {{...badgeStyelmob, backgroundColor:'#E8F1FF'}}>General Queries</span>
                                            </div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Role</div>
                                            <div className = "Role">
                                                User
                                            </div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Status</div>
                                            <div className = "status-info">
                                                <UsertableStatus statusTitle={'Active'} statusClass={'active'}/>
                                            </div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Created At</div>
                                            <div className = "cb-date">Apr-18-2023</div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-end">
                                            <Useraction/>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey = "3">
                                    <Accordion.Header>
                                        <Username name = {'Edward Martinez'} Useremail={'edward.martinez@example.com'}/>
                                    </Accordion.Header>
                                    <Accordion.Body className = "pt-0">
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Tags</div>
                                            <div>
                                                <span style = {badgeStyelmob}>Marketing</span> 
                                                <span style = {{...badgeStyelmob, backgroundColor:'#FFDBD9'}}>Sale</span> 
                                                <span style = {{...badgeStyelmob, backgroundColor:'#E8F1FF'}}>General Queries</span>
                                            </div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Role</div>
                                            <div className = "Role">
                                                User
                                            </div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Status</div>
                                            <div className = "status-info">
                                                <UsertableStatus statusTitle={'Disactivated'} statusClass={'disactivated'}/>
                                            </div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Created At</div>
                                            <div className = "cb-date">Apr-18-2023</div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-end">
                                            <Useraction/>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        </>
    );
};

export default ManageUsermob;