
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import Integration_img from '../../images/icons/integration-icon.svg';

const IntegrationHeader = ({Pagename}) => {

    const pageHeaderstyle = {
        boxShadow: '0px 3px 5px 0px #00000014',
        backgroundColor: '#fff',
        padding: '25px 0px'
    }

    const pageHeadingstyle = {
        fontWeight: '700',
        fontSize: '22px',
        color: '#000',
        lineHeight: '30px',
    }

    const mobformstyle = {
        border: '1px solid #D9D9D9',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '35px',
    }


    return (
        <>
            <section style = {pageHeaderstyle} className = "page-header">
                <Container>
                    <Row className = "align-items-center">
                        <Col md = {6} lg = {8} xxl = {9} style = {pageHeadingstyle} className = "page-heading">
                            <div className = "flex align-items-center gap-2">
                                <img src = {Integration_img} alt = "integration-icon"/> {Pagename}
                            </div>
                        </Col>
                        <Col md = {6} lg = {4} xxl = {3} className = "col-md-6 text-md-end d-none d-md-block">
                            <Form.Group>
                                <Form.Control placeholder = "Search" className = "w-100"/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className = "mobile-integration-search d-md-none pt-3">
                <Container>
                    <Row>
                        <Col xs = {12}>
                            <Form.Group>
                                <Form.Control style = {mobformstyle} placeholder = "Search" className = "w-100"/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default IntegrationHeader;