import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useState } from 'react';

import Select from "react-select";

import EmptyuserIcon from "../images/icons/empty-user-icon.svg";

import MangageUserHeader from "../components/manageUser/userheader";


const EmptyUser = () => {

    const [adshow, setAdShow] = useState(false);

    const role = [
        { value: 'user', label: 'User' },
        { value: 'admin', label: 'Admin' },
        { value: 'superadmin', label: 'Super Admin' }
    ]

    const tags = [
        { value: 'marketing', label: 'Marketing' },
        { value: 'sale', label: 'Sale' },
        { value: 'queries', label: 'Genral Queries' }
    ]

    const status = [
        { value: 'active', label: 'Active' },
        { value: 'approved', label: 'Approved' },
        { value: 'pending', label: 'Pending' },
        {valut: 'deactivated', label: 'Deactivated'},
        { value: 'rejected', label: 'Rejected' }
    ]

    return(
        <>

            <MangageUserHeader/>

            <section className = "main inner-main templates-body-empty">
                    <Container>
                        <Row className = "align-items-center">
                            <Col xs = {12}>
                                <div className = "tb-empty-content text-center">
                                    <img src = {EmptyuserIcon} alt = "icon"/>
                                    <div className = "tb-page-title">
                                        Currently you don’t have users
                                    </div>
                                    <p>
                                        You can create a new user by clicking the button below
                                    </p>
                                    <Button className = "btn-main" onClick={() => setAdShow(true)}>
                                        <i className ="bi bi-plus"></i> Add User
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
             </section>

             <Modal show={adshow} onHide={() => setAdShow(false)} className = "whatsapp-modal modal-contact" centered>
                <Modal.Header className = "p-0">
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm = {6}>
                            <Form.Group>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type = "text" placeholder = "Adam"/>
                            </Form.Group>
                        </Col>
                        <Col sm = {6}>
                            <Form.Group>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type = "text" placeholder = "Smith"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type = "email" placeholder = "email@example.com"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Role</Form.Label>
                                <Select className = "c-select basic-multi-select" options={role} placeholder = {'Select role'} 
                                classNamePrefix="select"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Assign Tags</Form.Label>
                                <Select isMulti className = "c-select basic-multi-select" options={tags} placeholder = {'Select Tags'} 
                                classNamePrefix="select"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Status</Form.Label>
                                <Select className = "c-select basic-multi-select" options={status} placeholder = {'Select status'} 
                                classNamePrefix="select"/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className = "px-0">
                    <Button className = "btn-main-default" onClick={()=>setAdShow(false)}>
                        Cancel
                    </Button>
                    <Button  className = "btn-main" onClick={()=>setAdShow(false)}>
                        Add User
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EmptyUser;