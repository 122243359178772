import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import Select from 'react-select';
import { useState, useRef } from "react";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import Close_icon from '../../images/icons/close-ic.svg';

import './widget-form.css';

const WidgetForm = () => {

    const language = [
        { value: 'English', label: 'English' },
        { value: 'Urdu', label: 'Urdu' },
        { value: 'Punjabi', label: 'Punjabi' }
    ]

    const [selectedColor, setSelectedColor] = useState('#4DC247'); // Initial color

    const [isColorPickerOpen, setIsColorPickerOpen] = useState(false); // State to track if color picker is open

    const colorPickerRef = useRef(null);

    // Handler for color change
    const handleColorChange = (e) => {
        setSelectedColor(e.target.value);
    };

    // Handle click on color picker field to toggle color picker open/close
    const handleColorPickerToggle = () => {
        setIsColorPickerOpen(!isColorPickerOpen);
        if (!isColorPickerOpen) {
        colorPickerRef.current.click(); // Programmatically open color picker
        }
    };

    const [headerbackground, setheaderbackground] = useState('#2ABC65');

    const handlebackgruondChagne = (e) => {
        setheaderbackground(e.target.value);
    }

    const [snippetshow, setSnippetShow] = useState(false);

    return (
        <>
            <section className = "main inner-main">
                <section className = "contact-group-top">
                        <Container>
                            <Row>
                                <Col md = {12} lg = {8} className = "mb-3 mb-lg-0">
                                    <div className = "create-template-form widget-form mb-3">
                                        <Row>
                                            <div className = "widget-heading">
                                                Chat Button
                                            </div>
                                            <div className = "widget-pra">
                                                This button, when clicked by the user, will open up the chat widget on your website.
                                            </div>
                                        </Row>
                                        <Row>
                                            <Col sm = {12} md = {6}>
                                                <Form.Group className = "form-group">
                                                    <Form.Label>WhatsApp Phone Number</Form.Label>
                                                    <Form.Control placeholder = "923015675524"/>
                                                </Form.Group>
                                            </Col>

                                            <Col sm = {12} md = {6}>
                                                 <Form.Group className = "form-group">
                                                    <Form.Label>Button Background</Form.Label>
                                                    <div className = "color-picker-field" onClick={handleColorPickerToggle}>
                                                        <div className="color-picker-inner d-flex align-items-center gap-2">
                                                            {/* Left side: Color picker */}
                                                            <div className="color-sec">
                                                            <input
                                                                ref={colorPickerRef}
                                                                type="color"
                                                                className="form-control form-control-color mb-0 border-0 bg-transparent"
                                                                style={{
                                                                width: '20px',
                                                                height: '20px',
                                                                borderRadius: '50%',
                                                                backgroundColor: selectedColor,
                                                                display: 'block',
                                                                padding: '0',
                                                                overflow: 'hidden',
                                                                }}
                                                                value={selectedColor}
                                                                onChange={handleColorChange}
                                                            />
                                                            </div>
                                                            
                                                            {/* Right side: Color code display */}
                                                            <div className="color-code-sec">
                                                                <span>{selectedColor}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm = {12} md = {6}>
                                                 <Form.Group className = "form-group">
                                                    <Form.Label>Button Text</Form.Label>
                                                    <Form.Control placeholder = "Chat with us"/>
                                                </Form.Group>
                                            </Col>

                                            <Col sm = {12} md = {6}>
                                                 <Form.Group className = "form-group">
                                                    <Form.Label>Border Radius</Form.Label>
                                                    <Form.Control placeholder = "20"/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm = {12} md = {4}>
                                                 <Form.Group className = "form-group">
                                                    <Form.Label>Margin Left(px)</Form.Label>
                                                    <Form.Control placeholder = "10"/>
                                                </Form.Group>
                                            </Col>
                                            <Col sm = {12} md = {4}>
                                                 <Form.Group className = "form-group">
                                                    <Form.Label>Margin Right(px)</Form.Label>
                                                    <Form.Control placeholder = "10"/>
                                                </Form.Group>
                                            </Col>
                                            <Col sm = {12} md = {4}>
                                                 <Form.Group className = "form-group">
                                                    <Form.Label>Margin Bottom(px)</Form.Label>
                                                    <Form.Control placeholder = "10"/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className = "create-template-form widget-form mb-3">
                                        <Row>
                                            <div className = "widget-heading">
                                                Chat Widget
                                            </div>
                                            <div className = "widget-pra">
                                                Customize the chat widget with brand colors, custom heading, and subheading. Add tailored call-to-action buttons based on conversation tags.
                                            </div>
                                        </Row>
                                        <Row>
                                            <Col xs = {12} md = {6}>
                                                <Form.Group className = "form-group">
                                                    <Form.Label>Widget Header Background</Form.Label>
                                                    <div className = "color-picker-field">
                                                        <div className="color-picker-inner d-flex align-items-center gap-2">
                                                            {/* Left side: Color picker */}
                                                            <div className="color-sec">
                                                            <input
                                                                type="color"
                                                                className="form-control form-control-color mb-0 border-0 bg-transparent"
                                                                style={{
                                                                width: '20px',
                                                                height: '20px',
                                                                borderRadius: '50%',
                                                                backgroundColor: headerbackground,
                                                                display: 'block',
                                                                padding: '0',
                                                                overflow: 'hidden',
                                                                }}
                                                                value={headerbackground}
                                                                onChange={handlebackgruondChagne}
                                                            />
                                                            </div>
                                                            
                                                            {/* Right side: Color code display */}
                                                            <div className="color-code-sec">
                                                                <span>{headerbackground}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs = {12} md = {12}>
                                                <Form.Group className = "form-group">
                                                    <Form.Label>Widget Title</Form.Label>
                                                    <div className = "input-body position-relative">
                                                        <Form.Control placeholder = "Chat with us on WhatsApp"/>
                                                        <span className = "word-limit">10/60</span>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs = {12} md = {12}>
                                                <Form.Group className = "form-group">
                                                    <Form.Label>Short Description</Form.Label>
                                                    <div className = "textarea-body position-relative">
                                                        <textarea className = "form-control" placeholder="Click on the Button below to start your conversation."  rows = "4"></textarea>
                                                        <span className = "word-limit">60/1024</span>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className = "align-items-end newBtn-row">
                                            <Col xs = {12} md = {6}>
                                                <Form.Group className = "form-group">
                                                    <Form.Label>Button Label</Form.Label>
                                                    <Form.Control placeholder = "Text"/>
                                                </Form.Group>
                                            </Col>
                                            <Col xs = {12} md = {5}>
                                                <Form.Group className = "form-group">
                                                    <Form.Label>Assign Tag</Form.Label>
                                                    <Select className = "c-select" options={language} placeholder = {'Supports'} closeMenuOnSelect={false} classNamePrefix="select"/>
                                                </Form.Group>
                                            </Col>
                                            <Col xs = {12} md = {1} className = "d-none d-md-block">
                                                <Button className = "bg-transparent border-none p-2 button-close">
                                                    <img src = {Close_icon} alt = "close-ic"/>
                                                </Button>
                                            </Col>

                                            <Button className = "bg-transparent border-none p-2 button-close d-md-none">
                                                <img src = {Close_icon} alt = "close-ic"/>
                                            </Button>
                                            
                                        </Row>
                                        <Row className = "align-items-end newBtn-row">
                                            <Col xs = {12} md = {6}>
                                                <Form.Group className = "form-group">
                                                    <Form.Label>CTA Text</Form.Label>
                                                    <Form.Control placeholder = "Text"/>
                                                </Form.Group>
                                            </Col>
                                            <Col xs = {12} md = {5}>
                                                <Form.Group className = "form-group">
                                                    <Form.Label>Assign Tag</Form.Label>
                                                    <Select className = "c-select" options={language} placeholder = {'Supports'} closeMenuOnSelect={false} classNamePrefix="select"/>
                                                </Form.Group>
                                            </Col>
                                            <Col xs = {12} md = {1} className = "d-none d-md-block">
                                                <Button className = "bg-transparent border-none p-2 button-close">
                                                    <img src = {Close_icon} alt = "close-ic"/>
                                                </Button>
                                            </Col>

                                            <Button className = "bg-transparent border-none p-2 button-close d-md-none">
                                                <img src = {Close_icon} alt = "close-ic"/>
                                            </Button>
                                            
                                        </Row>
                                        <Row>
                                            <Col sm = {12}>
                                                <Button className = "btn btn-simple">
                                                    <i class="bi bi-plus"></i> New Button
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                    <Row>
                                        <Col sm = {12}>
                                            <button className = "btn btn-main d-block w-100" onClick={() => setSnippetShow(true)}>
                                                Generate Snippet
                                            </button>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col md = {12} lg = {4}>
                                    <div className = "create-template-form widget-form mb-3">
                                        <Row>
                                            <div className = "widget-heading">
                                                Create WhatsApp Button
                                            </div>
                                            <div className = "widget-pra">
                                                Install the button on your website via simple code. Click on Generate Snippet to get the installation code for your website. Check live preview of your WhatsApp Button at Botton-Right of this Page
                                            </div>
                                        </Row>
                                    </div>
                                </Col>
                                
                            </Row>
                        </Container>
                    </section>
            </section>

            <Modal show={snippetshow} onHide={() => setSnippetShow(false)} className = "whatsapp-modal modal-contact" centered>
                <Modal.Header className = "p-0" closeButton>
                    <Modal.Title>Code Snippet</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <Row>
                        <Col sm = {12}>
                            <Tabs className = "code-tabs">

                                <TabList>
                                    <Tab id = "#react-tab">React Code</Tab>
                                    <Tab id = "html-tab">HTML Code</Tab>
                                </TabList>

                                <TabPanel>
                                    <code>
                                        {`
                                        <script type="text/javascript"
                                            src="https://d3mkw6s8thqya7.cloudfront.net/integration-plugin.js"
                                            id="utter-wa-widget"
                                            widget-id="tasxeF">
                                            </script>`}
                                    </code>
                                </TabPanel>

                                <TabPanel>
                                    <code>
                                        {`
                                            <script type="text/javascript"
                                                src="https://d3mkw6s8thqya7.cloudfront.net/integration-plugin.js"
                                                id="utter-wa-widget"
                                                widget-id="tasxeF">
                                                </script>
                                        `}
                                    </code>
                                </TabPanel>
                            </Tabs>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
        </>
    );
};

export default WidgetForm;