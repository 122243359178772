
import Groupcontact from './groupcontact'; 
import Hajira from "../../images/hajira.png";

export const Contactlist = [
    {
      id: "01",
      name: <Groupcontact Image = {Hajira}  name = {'Hajira'}/>
    },
    {
      id: "02",
      name: <Groupcontact Image = {Hajira}  name = {'Rabbia Awan'}/>
    },
    {
      id: "03",
      name: <Groupcontact Image = {Hajira}  name = {'Muzamil Khan'}/>
    },
    {
      id: "04",
      name: <Groupcontact Image = {Hajira}  name = {'Mohsin Khan'}/>
    },
    {
      id: "05",
      name: <Groupcontact Image = {Hajira}  name = {'Anum Ihsan'}/>
    },
  ];
  