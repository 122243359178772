import React from "react";

import "./group-contact.css";

const Groupcontact = ({Image, name}) => {
    return (
       <>
                <ul className = "nav align-items-center">
                    <li className = "nav-item">
                        <div className = "user-img">
                            <img src = {Image} alt = "user-img"/>
                        </div>
                    </li>
                    <li className = "nav-item">
                        <div className = "u-name">
                            {name}
                        </div>
                    </li>
                </ul>
        </>
    )
}

export default Groupcontact;