
import Button from 'react-bootstrap/Button';

import Whatsapp_logo from '../../images/whatsapp-logo.svg';
import Utter_logo from '../../images/utter-logo.svg';
import Whatsapp_whieLogo from '../../images/ic_baseline-whatsapp.svg';
import Btn_chatClose from '../../images/btn-close-chat.svg';

import { useState } from 'react';

import './chatUs.css';

const ChatUs = () => {

    //const [chatOpen, setChatOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const openChat = () => {
        setIsOpen(true)
    }

    const closeChat = () => {
        setIsOpen(false)
    }

    // const toggleChatBox = ()=> {
    //     setChatOpen(!chatOpen);
    // }

    return (
        <>
            {/* {chatOpen && ( */}
                
                <section className = {`chatUs ${isOpen ? 'open' : ''}`}>
                    <div className = "chatus-header position-relative">
                        <div className = "title">
                            Chat with us on WhatsApp
                        </div>
                        <div>
                            Click on the Button below to start your conversation.
                        </div>
                        <Button className = "close-chat" onClick={closeChat}></Button>
                    </div>
                    <div className = "chatus-body">
                        <div className = "chat-question">
                            <div className = "chat-logo">
                                <img src = {Whatsapp_logo} alt = "whatsapp-logo"/>
                            </div>
                            <div className = "question">
                                Sales Queries
                            </div>
                        </div>

                        <div className = "chat-question">
                            <div className = "chat-logo">
                                <img src = {Whatsapp_logo} alt = "whatsapp-logo"/>
                            </div>
                            <div className = "question">
                                General Questions
                            </div>
                        </div>

                        <div className = "chat-question">
                            <div className = "chat-logo">
                                <img src = {Whatsapp_logo} alt = "whatsapp-logo"/>
                            </div>
                            <div className = "question">
                                Technical Support
                            </div>
                        </div>

                        <div className = "chat-question">
                            <div className = "chat-logo">
                                <img src = {Whatsapp_logo} alt = "whatsapp-logo"/>
                            </div>
                            <div className = "question">
                                Delivery Queries
                            </div>
                        </div>
                    </div>
                    <div className = "chatus-footer">
                        Powered by <img src = {Utter_logo} alt = "logo"/>
                    </div>
                </section>
                
            {/* )} */}

            {isOpen && (
                <Button className = "btn-chat-close" onClick = {closeChat}>
                    <img src= {Btn_chatClose} alt = "btn-close-icon"/>
                </Button>
            )}
            {!isOpen && 
                (<Button className = "btn-chatwithUs" onClick={openChat}>
                    <img src = {Whatsapp_whieLogo} alt = "whatsapp-ic"/> Chat with us
                </Button>
            )}
        </>
    );
};

export default ChatUs;