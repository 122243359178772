
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import React, { useState } from 'react';

import TemplateListingHeader from "../components/template-listing/templateListing-header";
import TemplateListingFooter from "../components/template-listing/templateListing-footer";
import Listingtable from "../components/template-listing/listing-table";

import TemplateListingMob from "../components/template-listing/template-listing-mob";

import Reciver from "../images/icons/reciver.svg";
import Linkforward from "../images/icons/link-forward.svg";
import Eventimg from "../images/event-img.jpg";
import Cloasebtnic from "../images/close-icon.svg";

const TemplateListing = () => {

    const Templates = [
        { id: 1, eventName: 'Meena telehealth', createdname: 'Asad Mahmood', statustitle: 'In-review', statusclass: 'in-review', language: 'EN_US', category: 'Marketing', 
            UpdatedDagte: 'Apr-18-2023' },
        { id: 2, eventName: 'Meena telehealth', createdname: 'Muzamil Ahmed Khan', statustitle: 'In-review', statusclass: 'in-review', language: 'EN_US', category: 'Marketing', 
            UpdatedDagte: 'Apr-18-2023' },
        { id: 3, eventName: 'Meena telehealth', createdname: 'Naveed Khan', statustitle: 'Rejected', statusclass: 'rejected', language: 'EN_US', category: 'Marketing', 
            UpdatedDagte: 'Apr-18-2023' },
        { id: 4, eventName: 'Meena telehealth', createdname: 'Rimsha Malik', statustitle: 'Rejected', statusclass: 'rejected', language: 'EN_US', category: 'Marketing', 
            UpdatedDagte: 'Apr-18-2023' },
        { id: 5, eventName: 'Meena telehealth', createdname: 'Naveed Khan', statustitle: 'In-review', statusclass: 'in-review', language: 'EN_US', category: 'Marketing', 
            UpdatedDagte: 'Apr-18-2023' },
        { id: 6, eventName: 'Meena telehealth', createdname: 'Asad Mahmood', statustitle: 'Approved', statusclass: 'approved', language: 'EN_US', category: 'Marketing', 
            UpdatedDagte: 'Apr-18-2023' },
        { id: 7, eventName: 'Meena telehealth', createdname: 'Asad Mahmood', statustitle: 'Approved', statusclass: 'approved', language: 'EN_US', category: 'Marketing', 
            UpdatedDagte: 'Apr-18-2023' },
        { id: 8, eventName: 'Meena telehealth', createdname: 'Asad Mahmood', statustitle: 'Approved', statusclass: 'approved', language: 'EN_US', category: 'Marketing', 
            UpdatedDagte: 'Apr-18-2023' },
        { id: 9, eventName: 'Meena telehealth', createdname: 'Asad Mahmood', statustitle: 'Approved', statusclass: 'approved', language: 'EN_US', category: 'Marketing', 
            UpdatedDagte: 'Apr-18-2023' },
    ]

    const [show, setShow]= useState(false);

    const handleButtonClick = (newState)=>{
        setShow(newState)
    }

    return (
        <>
            <TemplateListingHeader/>

            <section className = "main inner-main template-listing py-3 d-none d-lg-block">
                <Container>
                    <Row>
                        <Col xs = {12}  lg={show ? 8: 12}>
                            <Listingtable Templates={Templates} onChildClick = {handleButtonClick}/>
                        </Col>
                        {show && 
                        <Col xs = {12} lg = {4}>
                        <div className = "preview-sec preview-sec-new">
                            <div className = "prev-sec-header pb-2">
                                <button className="close-preview" onClick={()=> setShow(false)}>
                                    <img src = {Cloasebtnic} alt = "close-btn"/>
                                </button>
                            </div>
                            <div className = "preview-body">
                                <div className = "preview-screen">
                                    <div className = "event-img">
                                        <img src = {Eventimg} alt = "event-img" className = "w-100 mb-2"/>
                                    </div>
                                    <div className = "heading">
                                        The event starts at 12 o'clock promptly.
                                    </div>
                                    <div className = "content">
                                        Hello everyone, the event starts at 12 o'clock promptly on March 7th, 2023. the event starts at 12 o'clock promptly on March 7th, 2023.the event starts at 12 o'clock promptly.
                                    </div>
                                    <div className = "heading">
                                        Best Regards
                                    </div>
                                </div>
                                <Row className = "link-footer mx-0">
                                    <Col xs = {6} className = "px-0">
                                        <a href = "#"> <img src = {Reciver} alt = "icon"/> Call me</a>
                                    </Col>
                                    <Col xs = {6} className = "px-0">
                                        <a href = "#"><img src = {Linkforward} alt = "icon"/> Register Now</a>
                                    </Col>
                                </Row>
                            </div>
                            <Row className = "event-time">
                                <Col xs = {6} className = "pe-1">
                                    <div className = "e-time start-time">
                                        12:00 PM
                                    </div>
                                </Col>
                                <Col xs = {6} className = "ps-1">
                                    <div className = "e-time end-time">
                                        2:00 PM
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                        }
                        
                            
                       
                    </Row>
                </Container>
            </section>

            <section className = "main inner-main template-listing pt-2 pb-3 d-lg-none">
                <TemplateListingMob/>
            </section>

            <TemplateListingFooter/>
        </>
    );
};

export default TemplateListing;