
import Accordion from "react-bootstrap/Accordion";

import UserBasicInfo from "./basic-info";
import UserTags from "./usertags";
import UserNotes from "./usernotes";

// import Select from 'react-select';

const ChatAccordion = () => {

    // const Tags = [
    //     { value: 'Marketing', label: 'Event Marketing' },
    //     { value: 'sale', label: 'Sale' },
    //     { value: 'queries', label: 'Queries' },
    // ]

    return (
        <>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <div className = "header-title d-flex align-items-center gap-2">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.1665 14.166H10.8332V9.16602H9.1665V14.166ZM9.99984 7.49935C10.2359 7.49935 10.4339 7.41949 10.5936 7.25977C10.7533 7.10004 10.8332 6.90213 10.8332 6.66602C10.8332 6.4299 10.7533 6.23199 10.5936 6.07227C10.4339 5.91254 10.2359 5.83268 9.99984 5.83268C9.76373 5.83268 9.56581 5.91254 9.40609 6.07227C9.24636 6.23199 9.1665 6.4299 9.1665 6.66602C9.1665 6.90213 9.24636 7.10004 9.40609 7.25977C9.56581 7.41949 9.76373 7.49935 9.99984 7.49935ZM9.99984 18.3327C8.84706 18.3327 7.76373 18.1139 6.74984 17.6764C5.73595 17.2389 4.854 16.6452 4.104 15.8952C3.354 15.1452 2.76025 14.2632 2.32275 13.2493C1.88525 12.2355 1.6665 11.1521 1.6665 9.99935C1.6665 8.84657 1.88525 7.76324 2.32275 6.74935C2.76025 5.73546 3.354 4.85352 4.104 4.10352C4.854 3.35352 5.73595 2.75977 6.74984 2.32227C7.76373 1.88477 8.84706 1.66602 9.99984 1.66602C11.1526 1.66602 12.2359 1.88477 13.2498 2.32227C14.2637 2.75977 15.1457 3.35352 15.8957 4.10352C16.6457 4.85352 17.2394 5.73546 17.6769 6.74935C18.1144 7.76324 18.3332 8.84657 18.3332 9.99935C18.3332 11.1521 18.1144 12.2355 17.6769 13.2493C17.2394 14.2632 16.6457 15.1452 15.8957 15.8952C15.1457 16.6452 14.2637 17.2389 13.2498 17.6764C12.2359 18.1139 11.1526 18.3327 9.99984 18.3327Z" fill="#0994CA"/>
                        </svg>

                            <span>General Information</span>

                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <UserBasicInfo/>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        <div className = "header-title d-flex align-items-center gap-2">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_3093_22714)">
                            <path d="M10.2928 17.8325L19.5789 8.54388C19.6207 8.50213 19.6403 8.44566 19.6354 8.38919L19.0093 0.988741C18.9921 0.797224 18.8423 0.647447 18.6508 0.630259L11.2504 0.00659846C11.1939 0.00168774 11.135 0.0213305 11.0957 0.0630716L1.8095 9.34923C1.77295 9.38615 1.75244 9.43601 1.75244 9.48796C1.75244 9.53992 1.77295 9.58977 1.8095 9.62669L10.0153 17.8325C10.0914 17.9111 10.2166 17.9111 10.2928 17.8325ZM11.8298 1.83093L17.3446 2.29745L17.8111 7.81218L10.1528 15.468L4.17156 9.48919L11.8298 1.83093ZM13.307 6.40354C13.4164 6.51298 13.5463 6.59978 13.6894 6.65901C13.8324 6.71823 13.9856 6.7487 14.1404 6.74869C14.2952 6.74868 14.4484 6.71818 14.5914 6.65894C14.7344 6.5997 14.8643 6.51287 14.9738 6.40342C15.0832 6.29397 15.17 6.16403 15.2292 6.02103C15.2885 5.87803 15.3189 5.72476 15.3189 5.56998C15.3189 5.4152 15.2884 5.26194 15.2292 5.11895C15.1699 4.97596 15.0831 4.84604 14.9737 4.7366C14.8642 4.62716 14.7343 4.54035 14.5913 4.48113C14.4483 4.42191 14.295 4.39144 14.1402 4.39145C13.9854 4.39146 13.8322 4.42196 13.6892 4.4812C13.5462 4.54044 13.4163 4.62727 13.3068 4.73672C13.1974 4.84617 13.1106 4.97611 13.0514 5.11911C12.9922 5.26211 12.9617 5.41538 12.9617 5.57016C12.9617 5.72494 12.9922 5.8782 13.0514 6.02119C13.1107 6.16418 13.1975 6.2941 13.307 6.40354ZM20.2738 11.6818L19.3015 10.712C19.2646 10.6754 19.2147 10.6549 19.1627 10.6549C19.1108 10.6549 19.0609 10.6754 19.024 10.712L10.1356 19.5832L4.30169 13.764C4.26477 13.7274 4.21492 13.7069 4.16296 13.7069C4.11101 13.7069 4.06115 13.7274 4.02424 13.764L3.05191 14.7338C3.01536 14.7707 2.99485 14.8206 2.99485 14.8726C2.99485 14.9245 3.01536 14.9744 3.05191 15.0113L9.02334 20.9729L9.99566 21.9428C10.0718 22.0189 10.197 22.0189 10.2731 21.9428L20.2738 11.9593C20.3499 11.8832 20.3499 11.7579 20.2738 11.6818Z" fill="#0994CA"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_3093_22714">
                                <rect width="22" height="22" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>

                            <span>Tags</span>

                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <UserTags/>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        <div className = "header-title d-flex align-items-center gap-2">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5833 17.5H15.4167V15.4167H17.5V14.5833H15.4167V12.5H14.5833V14.5833H12.5V15.4167H14.5833V17.5ZM15 19.1667C13.8472 19.1667 12.8646 18.7604 12.0521 17.9479C11.2396 17.1354 10.8333 16.1528 10.8333 15C10.8333 13.8472 11.2396 12.8646 12.0521 12.0521C12.8646 11.2396 13.8472 10.8333 15 10.8333C16.1528 10.8333 17.1354 11.2396 17.9479 12.0521C18.7604 12.8646 19.1667 13.8472 19.1667 15C19.1667 16.1528 18.7604 17.1354 17.9479 17.9479C17.1354 18.7604 16.1528 19.1667 15 19.1667ZM5.83333 7.5H14.1667V5.83333H5.83333V7.5ZM9.72917 17.5H4.16667C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H15.8333C16.2917 2.5 16.684 2.66319 17.0104 2.98958C17.3368 3.31597 17.5 3.70833 17.5 4.16667V9.75C17.0972 9.55556 16.691 9.40972 16.2812 9.3125C15.8715 9.21528 15.4444 9.16667 15 9.16667C14.8472 9.16667 14.7049 9.17014 14.5729 9.17708C14.441 9.18403 14.3056 9.20139 14.1667 9.22917V9.16667H5.83333V10.8333H10.9375C10.6875 11.0694 10.4618 11.3264 10.2604 11.6042C10.059 11.8819 9.88194 12.1806 9.72917 12.5H5.83333V14.1667H9.22917C9.20139 14.3056 9.18403 14.441 9.17708 14.5729C9.17014 14.7049 9.16667 14.8472 9.16667 15C9.16667 15.4583 9.20833 15.8854 9.29167 16.2812C9.375 16.6771 9.52083 17.0833 9.72917 17.5Z" fill="#0994CA"/>
                        </svg>


                            <span>Notes</span>

                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <UserNotes/>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>   
        </>
    )
}

export default ChatAccordion;