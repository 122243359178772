
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
import Ratio from 'react-bootstrap/Ratio';

import Faqaccordion from "../components/accordion/accordion";

const Helpcetner = () => {
    return (
        <section className = "main faq">
            <Container>
                <h1>
                    Frequently Asked Questions
                </h1>
                <p>
                    Learn what to do if you're having trouble with setting up your account.
                </p>
                <Row className = "justify-content-center">
                    <Col sm = "12" xl = {6}>
                        <Faqaccordion title = {'How do i get started and create my account?'} 
                            description = {<><p>Go to the Google Account creation page. Follow the steps on the screen to set up your account.</p>
                            <p> Use the account you created to sign in to Gmail.</p>
                            <Row className = "py-3">
                                <Col sm = {12}>
                                    <Ratio aspectRatio="16x9">
                                        <iframe src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" title="YouTube video" allowFullScreen></iframe>
                                    </Ratio>
                                </Col>
                            </Row>
                            </>}/>
                        <Faqaccordion title = {'Can i renew my subscription plan?'} 
                            description = {<><p>Go to the Google Account creation page. Follow the steps on the screen to set up your account.</p>
                            <p> Use the account you created to sign in to Gmail.</p></>}/>
                        <Faqaccordion title = {'Every individual is allow to send broadcast message?'} 
                            description = {<><p>Go to the Google Account creation page. Follow the steps on the screen to set up your account.</p>
                            <p> Use the account you created to sign in to Gmail.</p></>}/>
                        <Faqaccordion title = {'Is there any template or message limit?'} 
                            description = {<><p>Go to the Google Account creation page. Follow the steps on the screen to set up your account.</p>
                            <p> Use the account you created to sign in to Gmail.</p></>}/>
                        <Faqaccordion title = {'Can i renew my subscription plan?'} 
                            description = {<><p>Go to the Google Account creation page. Follow the steps on the screen to set up your account.</p>
                            <p> Use the account you created to sign in to Gmail.</p></>}/>
                    </Col>
                </Row>
            </Container>
            <Container className = "py-5">
                <Row>
                    <Col sm = {12}>
                        <h1 className = "mb-3 mb-lg-5">
                            User Guide Videos
                        </h1>
                    </Col>
                </Row>
                <Row className = "justify-content-center">
                    <Col md = {4} className = "mb-3 mb-md-0">
                        <Ratio aspectRatio="16x9">
                            <iframe src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" title="YouTube video" allowFullScreen></iframe>
                        </Ratio>
                    </Col>
                    <Col md = {4} className = "mb-3 mb-md-0">
                        <Ratio aspectRatio="16x9">
                            <iframe src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" title="YouTube video" allowFullScreen></iframe>
                        </Ratio>
                    </Col>
                    <Col md = {4} className = "mb-3 mb-md-0">
                        <Ratio aspectRatio="16x9">
                            <iframe src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" title="YouTube video" allowFullScreen></iframe>
                        </Ratio>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Helpcetner;