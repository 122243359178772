
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import './form.css';

const Signup_form = ({heading})=>{

    return(
        <div className = "form-sec">
            <Form>
                <div className = "form-heading text-center">
                   {heading}
                </div>
                <Form.Group className = "form-group">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control type = "text" placeholder = ""/>
                </Form.Group>
                <Form.Group className = "form-group">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control type = "text" placeholder = ""/>
                </Form.Group>
                <Form.Group className = "form-group">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type = "email" placeholder = ""/>
                </Form.Group>
                <Form.Group className = "form-group">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type = "password" placeholder = ""/>
                </Form.Group>
                <Form.Group className = "form-group">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control type = "password" placeholder = ""/>
                </Form.Group>
                <Form.Group className = "form-group">
                    <Button className = "btn btn-main" type = "submit">
                        Sign up
                    </Button>
                </Form.Group>
                <Form.Group className = "text-center last-pra">
                Already have an account? <Link to = "/login">Sign in</Link>
                </Form.Group>
            </Form>
        </div>
    );
}

export default Signup_form;