
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";

import Groupaction from "../tableAction/groupaction";

import '../manageUser/css/usermob.css';

const GroupMobile = () => {

    const groupnameStyle = {
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '21px',
        color: '#000',
    }


    return (
        <>
        <Container>
            <Row>
                <Col xs = {12}>
                    <div className = "template-listing-collapse">
                        <div className = "tlc-header">
                            Group Name
                        </div>
                        <div className = "tlc-body">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey = "0">
                                    <Accordion.Header>
                                        <div className = "group-name" style = {groupnameStyle}>
                                            MWAN Mobile
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Created At</div>
                                            <div className = "cb-date">Asad Mehmood</div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Created By</div>
                                            <div className = "cb-date">Apr-18-2023</div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Members</div>
                                            <div className = "cb-date">102</div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-end">
                                            <Groupaction/>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey = "1">
                                    <Accordion.Header>
                                        <div className = "group-name" style = {groupnameStyle}>
                                            MWAN Events
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Created At</div>
                                            <div className = "cb-date">Ashfaq Ahmed</div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Created By</div>
                                            <div className = "cb-date">Apr-18-2023</div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Members</div>
                                            <div className = "cb-date">102</div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-end">
                                            <Groupaction/>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey = "2">
                                    <Accordion.Header>
                                        <div className = "group-name" style = {groupnameStyle}>
                                            Mobilink
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Created At</div>
                                            <div className = "cb-date">Muzamil Ahmed Khan</div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Created By</div>
                                            <div className = "cb-date">Apr-18-2023</div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Members</div>
                                            <div className = "cb-date">102</div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-end">
                                            <Groupaction/>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey = "3">
                                    <Accordion.Header>
                                        <div className = "group-name" style = {groupnameStyle}> 
                                            Etisalat
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Created At</div>
                                            <div className = "cb-date">Muzamil Hussain</div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Created By</div>
                                            <div className = "cb-date">Apr-18-2023</div>
                                        </div>
                                        <div className = "contact-list d-flex align-items-center justify-content-between mb-3">
                                            <div className = "ub-title">Members</div>
                                            <div className = "cb-date">102</div>
                                        </div>
                                        <div className = "d-flex align-items-center justify-content-end">
                                            <Groupaction/>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        </>
    );
};

export default GroupMobile;