
import './broadcast.css';

const DeliveryStatus = ({CompletedValue, PendingValue, FailedValue}) => {

    const getStatusColor = (status) => {
        switch (status) {
            case 'Completed':
                return '#5FD495'; // Green for completed
            case 'Pending':
                return '#FEC931'; // Yellow for pending
            case 'Failed':
                return '#FFB7B2'; // Red for failed
            default:
                return '#CCCCCC'; // Default color
        }
    };

    const getInnerStyle = (value, status) => ({
        width: `${value}%`,
        backgroundColor: getStatusColor(status),
    });

    return (
        <>
            <div className = "delivery-status">
                <div className = "status-span">
                    <div className = "status-title">Completed</div>
                    <div className = "status-progress completed">
                        <div className = "inner" style={getInnerStyle(CompletedValue, 'Completed')}></div>
                    </div>
                    <div className = "progress-value">
                        {CompletedValue}%
                    </div>
                </div>
                <div className = "status-span">
                    <div className = "status-title">Pending</div>
                    <div className = "status-progress pending">
                        <div className = "inner" style={getInnerStyle(PendingValue, 'Pending')}></div>
                    </div>
                    <div className = "progress-value">
                        {PendingValue}%
                    </div>
                </div>
                <div className = "status-span">
                    <div className = "status-title">Failed</div>
                    <div className = "status-progress failed">
                        <div className = "inner" style={getInnerStyle(FailedValue, 'Failed')}></div>
                    </div>
                    <div className = "progress-value">
                        {FailedValue}%
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeliveryStatus;