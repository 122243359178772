import Inboxchat from "../components/chat/inboxchat";

const Inbox = ()=>{

    return(
            <section className="main">
                <Inboxchat/>
            </section>
    );
}

export default Inbox;