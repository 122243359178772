
import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Wtable from '../table/table';
import Companyactionbtn from './compnay-actionbtn';


const Companytable = () => {

    const theadData = ["Company Name", "Phone Number", "Email", "Expiry Date", "Status", "Actions"];

    const tbodyData = [
        {
            id: "1",
            items: ["MWAN Mobile", "923025676625", "mwanmobile@hotmail.com", "Jan-18-2023","Active", <Companyactionbtn/>],
        },
        {
            id: "2",
            items: ["Navigate Commodities", "923025676625", "navigatemarine@hotmail.com", "Feb-19-2023","Active", <Companyactionbtn/>],
        },
        {
            id: "3",
            items: ["Etisalat By e&", "923025676625", "etisalat@gmail.com", "Feb-20-2023","Active", <Companyactionbtn/>],
        },
        {
            id: "4",
            items: ["MWAN Events", "923025676625", "mwanevents@mwanmobile.com", "Mar-22-2023","Active", <Companyactionbtn/>],
        },
        {
            id: "5",
            items: ["Iplex PVT", "923025676625", "iplexpvet@gmail.com", "Jan-20-2023","Active", <Companyactionbtn/>],
        },
        {
            id: "6",
            items: ["Jazz Mobile", "923025676625", "jazzmobile@yahoo.com", "Jan-20-2023","Active", <Companyactionbtn/>],
        },
    ];

    return (
        <>
             <div className = "cg-custom-table">
                <Wtable theadData={theadData} tbodyData={tbodyData}/>
            </div>

            <Row className = "py-3 py-md-4">
                <Col sm = {12}>
                    <ul className = "nav justify-content-center align-items-center">
                        <li className = "nav-item">
                            <Button className = "table-btn">
                                <i className ="bi bi-chevron-left"></i>
                            </Button>
                        </li>
                        <li className = "nav-item">
                            <Button className = "table-btn active">
                                1
                            </Button>
                        </li>
                        <li className = "nav-item">
                            <Button className = "table-btn">
                                2
                            </Button>
                        </li>
                        <li className = "nav-item">
                            <Button className = "table-btn">
                                3
                            </Button>
                        </li>
                        <li className = "nav-item">
                            <Button className = "table-btn">
                                4
                            </Button>
                        </li>

                        <li className = "nav-item">
                            <div className = "pagegap">
                                ...
                            </div>
                        </li>

                        <li className = "nav-item">
                            <Button className = "table-btn">
                                10
                            </Button>
                        </li>
                        <li className = "nav-item">
                            <Button className = "table-btn">
                                <i className ="bi bi-chevron-right"></i>
                            </Button>
                        </li>
                    </ul>
                </Col>
            </Row>
        </>
    )
}

export default Companytable;