import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import Select from 'react-select';

import PageHeader from "../components/page-header/pageHeader";
import Broadcastcard from "../components/broadcast/broadcast-card";
import BroadcastDetailTable from "../components/broadcast/broadcast-detailsTable";

import Recipent_icon from "../images/broadcast/recipent-icon.svg"
import Send_icon from "../images/broadcast/send-icon.svg"
import Delivered_icon from "../images/broadcast/delivered-icon.svg"
import Read_icon from "../images/broadcast/read-icon.svg"
import Failed_icon from "../images/broadcast/failed-icon.svg"

import BroadcastDetailsMob from "../components/broadcast/broadcast-details-mob";

const BroadcastDetails = () => {

    const options = [
        { value: 'all Broadcasts', label: 'All Broadcasts' },
        { value: 'Instant', label: 'Instant' },
        { value: 'Scheduled', label: 'Scheduled' }
    ]

    return (
        <>
            <PageHeader Pagename={'GCC eHealth Event Announcement'} Backpage={'Broadcas'} 
            Currentpage={' GCC eHealth Event Announcement'} Linkpage={'/broadcastListing'}/>

            <section className = "main inner-main broadcast-sec pt-3 pt-xl-4">
                <Container>
                    <Row className = "card-row">
                        <Col>
                            <Broadcastcard title={'Recipients '} number={'2430'} imageicon={Recipent_icon} iconClass={'recipent'}/>
                        </Col>
                        <Col>
                            <Broadcastcard title={'Sent '} number={'2400'} imageicon={Send_icon} iconClass={'sent'}/>
                        </Col>
                        <Col>
                            <Broadcastcard title={'Delivered '} number={'1400'} imageicon={Delivered_icon} iconClass={'delivered'}/>
                        </Col>
                        <Col>
                            <Broadcastcard title={'Read '} number={'646'} imageicon={Read_icon} iconClass={'read'}/>
                        </Col>
                        <Col>
                            <Broadcastcard title={'Failed '} number={'30'} imageicon={Failed_icon} iconClass={'failed'}/>
                        </Col>
                    </Row>
                </Container>

                <Container className = "details-search py-3 py-lg-4">
                    <Row className = "align-items-center ds-row">
                        <Col md = {6} lg = {7} xxl = {7}>
                            <div className = "broadcast-heading mb-3 mb-md-0">
                                Broadcast Sent To:  <span>MWAN Mobile</span>
                            </div>
                        </Col>
                        <Col md = {6} lg = {5} xxl = {5}>
                            <Row className = "mx-md-0">
                                <Col md = {6} lg = {7} xl = {8} className = "ps-md-0 pe-md-1 mb-2 mb-md-0">
                                    <Form.Group>
                                        <Form.Control type="text" placeholder="Search" style = {{height: '40px'}} />
                                    </Form.Group>
                                </Col>
                                <Col md = {6} lg = {5} xl = {4} className = "pe-md-0 ps-md-1">
                                    <Form.Group>
                                        <Select className = "c-select" options={options} placeholder = {'All Broadcast'} 
                                        classNamePrefix="select"/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>

                <Container className = "d-none d-md-block">
                    <Row>
                        <Col xs = {12}>
                            <BroadcastDetailTable/>
                        </Col>
                    </Row>
                </Container>

                <Container className = "d-md-none">
                    <Row>
                        <Col xs = {12}>
                            <BroadcastDetailsMob/>
                        </Col>
                    </Row>
                </Container>

            </section>
        </>
    )
}

export default BroadcastDetails;