
import React from 'react';
import Wtable from '../table/table';
import Actionbutton from '../tableAction/tableactionBtn';
import Username from '../userName/username';

import "./table.css";

const ContactgroupTable = () => {

    const theadData = ["Name", "Phone Number", "Email", "Created Date", "Action"];

    const tbodyData = [
        {
            id: "1",
            items: [<Username name = {'Hajira Ihsan'}/>, "03025676625", "hajraihsan@gmail.com", "Jan-18-2023", <Actionbutton/>],
        },
        {
            id: "2",
            items: [<Username name = {'Rabbia Awan'}/>, "03025676625", "rabiawan@gmail.com", "Jan-18-2023", <Actionbutton/>],
        },
        {
            id: "3",
            items: [<Username name = {'Muzamil Khan'}/>, "03025676625", "rmuzamilkhan@gmail.com", "Jan-18-2023", <Actionbutton/>],
        },
        {
            id: "4",
            items: [<Username name = {'Mohsin Khan'}/>, "03025676625", "mohsinkhan@gmail.com", "Jan-18-2023", <Actionbutton/>],
        },
        {
            id: "5",
            items: [<Username name = {'Anum Ihsan'}/>, "03025676625", "ranumihsan@gmail.com", "Jan-18-2023", <Actionbutton/>],
        },
    ];

    return (
    <>
        <div className = "cg-custom-table">
            <Wtable theadData={theadData} tbodyData={tbodyData}/>
        </div>
    </>
    )
}

export default ContactgroupTable;