
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import Select from 'react-select';

import BroadcastHeader from "../components/broadcast/broadcast-header";
import Broadcasttable from "../components/broadcast/broadcast-table";
import TemplateListingFooter from "../components/template-listing/templateListing-footer";
import Search from "../components/search/search";
import BroadcastlistingMob from "../components/broadcast/Broadcast-listingMob";

const BroadcastListing = () => {

    const options = [
        { value: 'all Broadcasts', label: 'All Broadcasts' },
        { value: 'Instant', label: 'Instant' },
        { value: 'Scheduled', label: 'Scheduled' }
    ]

    const status = [
        { value: 'All', label: 'All' },
        { value: 'approved', label: 'Approved' },
        { value: 'pending', label: 'Pending' },
        { value: 'rejected', label: 'Rejected' }
    ]

    return (
            <>
                <BroadcastHeader Contact_title={'Broadcast'} Contact_search={<Search/>}/>

                <section className = "broadcast-top pt-lg-3 mt-lg-3">
                    <div className = "container">
                        <Row>
                            <Col lg = {5} xl = {4} className = "order-last order-md-first">
                                <Row className = "mx-lg-0">
                                    <Col xs = {6} className = "px-lg-1">
                                        <Button variant = "main w-100 active">
                                            Broadcast list
                                        </Button>
                                    </Col>
                                    <Col xs = {6} className = "px-lg-1">
                                        <Button variant = "main w-100">
                                            Scheduled
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg = {7} xl = {8} className = "order-first order-md-last mb-3 mb-md-0">
                                <ul className = "nav align-items-center gap-2 justify-content-lg-end mt-0 mt-md-3 mt-lg-0">
                                    <li class = "nav-item d-none d-md-block">
                                        <Form.Group>
                                        <Form.Control type = "date" placeholder = "start-date"/>
                                        </Form.Group>
                                    </li>
                                    <li class = "nav-item">
                                        <Form.Group>
                                            <Select className = "c-select" options={status} placeholder = {'Status'} closeMenuOnSelect={false} classNamePrefix="select"/>
                                        </Form.Group>
                                    </li>

                                    <li class = "nav-item d-none d-md-block">
                                        <Form.Group>
                                            <Select className = "c-select" options={options} placeholder = {'All Broadcast'} 
                                            classNamePrefix="select"/>
                                        </Form.Group>
                                    </li>

                                </ul>
                            </Col>
                        </Row>
                    </div>
                </section>

                <section className = "main inner-main broadcast-sec">
                    
                    <section className = "contact-group-table d-none d-lg-block">
                        <Container>
                            <Row>
                                <Col xs = {12}>
                                    <Broadcasttable/>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    <section className = "contact-group-table d-lg-none">
                        <BroadcastlistingMob/>
                    </section>

                    <TemplateListingFooter/>

                </section>
            </>
    )
};

export default BroadcastListing