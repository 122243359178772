import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import React, { useState } from 'react';

import Tag_icons from "../../images/tags/tag_icons.svg"

const TagsHeader = ({Tag_title, Tag_search}) => {

    const [editshow, setEditShow] = useState(false);

    return (
        <>
            <section className = "template-header py-3">
                <Container>
                    <Row className = "align-items-center">
                        <Col xs = {6} lg = {4}>
                            <Row className = "align-items-center">
                                <Col md = {12} lg = {4} xxl = {3}>
                                    <div className = "template-heading">
                                        <img className = "me-2" src = {Tag_icons} alt = "icon"/> 
                                        {Tag_title}
                                    </div>
                                </Col>
                                <Col md = {12} lg = {8} xxl = {9} className = "d-none d-lg-block group-search">
                                    {Tag_search}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs = {6} lg = {8}>
                            <Row className = "align-items-center justify-content-end">
                                <Col md = {12} className = "text-end">
                                    <Button className = "btn btn-main text-decoration-none btn-main-mob" onClick={()=>setEditShow(true)}>
                                        <i className ="bi bi-plus"></i> <span>Create New</span>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className = "template-header-mob d-lg-none pt-3">
                <Container>
                    <Row>
                        <Col xs = {12} className = "mb-3">
                            <Form.Group className = "form-group">
                                <Form.Control placeholder = "Search"/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Modal show={editshow} onHide={() => setEditShow(false)} className = "whatsapp-modal modal-contact" centered>
                <Modal.Header className = "p-0">
                    <Modal.Title>Create Tag</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Tag Name</Form.Label>
                                <Form.Control type = "text" placeholder = "General Queries"/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className = "px-0">
                    <Row className = "w-100 mx-0">
                        <Col xs = {12} className = "px-0">
                            <Button  className = "btn-main w-100" onClick={()=>setEditShow(false)}>
                                Create Tag
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default TagsHeader;