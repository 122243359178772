import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import PageHeader from "../components/page-header/pageHeader";

import Select from 'react-select';

import { Link } from "react-router-dom";

import '../components/broadcast/broadcast.css'


const NewBroadcast = () => {

    const options = [
        { value: 'templateA', label: 'Template A' },
        { value: 'templateB', label: 'Template B' },
        { value: 'templateC', label: 'Templated C' },
        { value: 'templateD', label: 'Template D' },
        { value: 'templateE', label: 'Template E' },
        { value: 'templateF', label: 'Template F' }
    ]

    const groups = [
        { value: 'All', label: 'All' },
        { value: 'Gcc', label: 'GCC eHealth Event Announcement' },
        { value: 'EidUlAdha', label: 'Eid ul Adha announcement' },
        { value: 'MwanMobile', label: 'MWAN Mobile Official Group' },
    ]

    const linkStyle = {
        color: '#165E5B',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '20px',
        textDecoration: 'none',
    }

    return (
        <>
            <PageHeader Pagename={'Send New Braodcast'} Backpage={'Broadcas'} 
            Currentpage={' Send New Broadcast'} Linkpage={'/broadcastListing'}/>

            <section className = "main inner-main py-3 py-lg-5">
                <Container>
                    <Row>
                        <Col md = {12} lg = {8} xxxl = {9} className = "mb-3 mb-lg-0">
                            <div className = "create-template-form mb-3">
                                <Row>
                                    <Col sm = {12}>
                                        <Form.Group className = "form-group">
                                            <Form.Label>Broadcast Name</Form.Label>
                                            <div className = "label-bottom">
                                                Give a name to your broadcast.
                                            </div>
                                            <div className = "input-body">
                                                <Form.Control placeholder = "Broadcast Name"/>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm = {12}>
                                        <Form.Group>
                                            <Form.Label>Select Message Template </Form.Label>
                                            <Select className = "c-select mw-100" options={options} placeholder = {'Select Template'} 
                                            classNamePrefix="select"/>
                                        </Form.Group>
                                    </Col>
                                    <Col sm = {12} className = "text-end">
                                        <Link style = {linkStyle} to = "/createTemplate">
                                            Create New Template
                                        </Link>
                                    </Col>
                                </Row>

                            </div>
                            <div className = "create-template-form mb-3">
                                <Row>
                                    <Col sm = {12}>
                                        <Form.Group>
                                            <Form.Label>Select Message Template </Form.Label>
                                            <div className = "label-bottom" style = {{fontWeight: '500', fontSize: '16px'}}>
                                                Select Group
                                            </div>
                                            <Select className = "c-select mw-100" options={groups} placeholder = {'Select Groups'} 
                                            classNamePrefix="select" closeMenuOnSelect={false}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>

                            <div className = "create-template-form mb-3">
                                <Row>
                                    <Col sm = {12}>
                                        <Form.Group>
                                            <Form.Label>Schedule Broadcast</Form.Label>
                                            <div className = "label-bottom">
                                                Choose a broadcast type below.
                                            </div>
                                            <div className = "button-select">
                                                <div className = "form-check form-check-inline">
                                                    <input className = "form-check-input" type = "radio" id = "inline-radio-1" name = "buttons"/>
                                                    <label className = "form-label" htmlFor = "inline-radio-1">Now</label>
                                                </div>
                                                <div className = "form-check form-check-inline">
                                                    <input className = "form-check-input" type = "radio" id = "inline-radio-2" name = "buttons"/>
                                                    <label className = "form-label"  htmlFor = "inline-radio-2">Letter</label>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm = {12} md = {6} lg = {4} xxxl = {3} className = "mb-3 mb-md-0">
                                        <input className = "form-control" type = "date"/>
                                    </Col>
                                    <Col sm = {12} md = {6} lg = {4} xxxl = {3}>
                                        <input className = "form-control" type = "time"/>
                                    </Col>
                                </Row>
                            </div>

                        </Col>
                        <Col md = {12} lg = {4} xxxl = {3}>
                            <div className = "message-preview-sec">
                                <div className = "message-preview-title">
                                    Message preview on WhatsApp
                                </div>
                                <div className = "message-prreview-inner">
                                    <div className = "start-heading">
                                        Hey, <span>[Eda]</span>
                                    </div>

                                    <div className = "message-details">
                                        We are excited to inform you about our upcoming event, [GCC eHEALTH WORKFORCE DEVELOPMENT CONFERENCE]! This is a great opportunity for you to connect with industry experts, learn about the latest trends, and expand your network.
                                        <br/><br/>
                                        We are offering an exclusive discount code for our valued participants. Use the code [GCC20] to get a special discount on your registration.
                                    </div>

                                    <div className = "regard-text">
                                        Best Regards
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default NewBroadcast;