import React from "react";
import TableRow from "./tableRow";
import TableHeadItem from "./tableHead";
import Table from "react-bootstrap/Table"

const Wtable = ({ theadData, tbodyData, customClass }) => {
    return (
        <Table className={customClass} responsive>
            <thead>
                <tr>
                    {theadData.map((item,index) => {
                        return <TableHeadItem key={index} item={item} />;
                    })}
                </tr>
            </thead>
            <tbody>
                {tbodyData.map((item,index) => {
                    return <TableRow key={index} data={item.items} />;
                })}
            </tbody>
        </Table>
    );
};

export default Wtable;