
import React from "react";
import Wtable from "../table/table";
import Form from "react-bootstrap/Form";

import "./modalTable.css";
//import Checkbox from "../group-table/checkbox";

const Modaltable = () => {
    const theadData = [<><Form.Check type = 'Checkbox'/></> , "Name", "Number", "Email"];

    const tbodyData = [
        {
            id: "1",
            items: [<><Form.Check type = 'Checkbox'/></>,"Hajira Ihsan", "03025676625", "hajraihsan@gmail.com"],
        },
        {
            id: "2",
            items: [<><Form.Check type = 'Checkbox'/></>,"Rabia Awan", "03025676625", "rabiawan@gmail.com"],
        },
        {
            id: "3",
            items: [<><Form.Check type = 'Checkbox'/></>,"Muzammil Khan", "03025676625", "rmuzamilkhan@gmail.com"],
        },
        {
            id: "4",
            items: [<><Form.Check type = 'Checkbox'/></>,"Mohsin Khan", "03025676625", "mohsinkhan@gmail.com"],
        },
        {
            id: "5",
            items: [<><Form.Check type = 'Checkbox'/></>,"Anum Ihsan", "03025676625", "ranumihsan@gmail.com"],
        },
    ];
    return (
        <>
            <div className = "modal-table">
                <Wtable theadData={theadData} tbodyData={tbodyData}/>
            </div>
        </>
    )
}

export default Modaltable